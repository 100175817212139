import { groupAccount } from "./../entity/groupAccount";
import { groupAccountRequest } from "./../entity/groupAccountRequest";
import { BaseService } from "./BaseService";
export class groupAccountSrevice extends BaseService<groupAccount> {
  protected getUrl(): string {
    return "groupAccount";
  }
  //校验用户是否为企业管理员
  getGroupAccount(groupAccountReq: groupAccountRequest) {
    return this.get("groupAccount", {
      pageNo: groupAccountReq.pageNo,
      pageSize: groupAccountReq.pageSize,
      searchType: groupAccountReq.searchType,
      searchContext: groupAccountReq.searchContext,
      type: groupAccountReq.type,
      phoneNumber: groupAccountReq.phoneNumber,
    })
      .then(function (response) {
        groupAccountReq.onSuccess(response.data);
      })
      .catch(function () {
        groupAccountReq.onError();
      });
  }
  AddGroupAccount(groupAccountReq: groupAccountRequest) {
    return this.post("groupAccount", {
      ...groupAccountReq,
    })
      .then(function (response) {
        groupAccountReq.onSuccess(response.data);
      })
      .catch(function () {
        groupAccountReq.onError();
      });
  }

  repeatCheck(groupAccountReq: groupAccountRequest) {
    return this.get(`groupAccount`, {
      type: groupAccountReq.type,
      repeatCheck: groupAccountReq.repeatCheck,
    })
      .then(function (response) {
        groupAccountReq.onSuccess(response.data);
      })
      .catch(function () {
        groupAccountReq.onError();
      });
  }
}
