import { Account } from "./../entity/Account";
import { BaseService } from "./BaseService";
import { AccountRequest } from "../entity/AccountRequest";
const url = "user-resource/account/1";
export class AccountService extends BaseService<Account> {
  protected getUrl(): string {
    return url;
  }
  getAccount(AccountReq: AccountRequest) {
    return this.get(`user-resource/account`, {})
      .then(function (response) {
        AccountReq.onSuccess(response.data);
      })
      .catch(function (error) {
        AccountReq.onError(error);
      });
  }
  signIn(AccountReq: AccountRequest) {
    return this.post(`user-resource/signIn`, {})
      .then(function (response) {
        AccountReq.onSuccess(response.data);
      })
      .catch(function (error) {
        AccountReq.onError(error);
      });
  }
  gettingAward(AccountReq: AccountRequest) {
    return this.post(`user-resource/gettingAward`, { ...AccountReq })
      .then(function (response) {
        AccountReq.onSuccess(response.data);
      })
      .catch(function (error) {
        AccountReq.onError(error);
      });
  }
}
