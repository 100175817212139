export class TicketService {
  key = "ticket";

  set(ticket: string) {
    localStorage.setItem(this.key, ticket);
  }

  get(): string | null {
    return localStorage.getItem(this.key);
  }

  /**
   * remove ticket in local storage.(logout)
   */
  remove() {
    localStorage.removeItem(this.key);
  }
}
