import { groupUserApprove } from "../entity/groupUserApprove";
import { groupUserApproveRequest } from "../entity/groupUserApproveRequest";
import { BaseService } from "./BaseService";
export class groupUserApproveSrevice extends BaseService<groupUserApprove> {
  protected getUrl(): string {
    return "groupUserApprove";
  }
  //审批列表
  getGroupUserApprove(groupUserApproveReq: groupUserApproveRequest) {
    return this.get(`groupUserApprove/${groupUserApproveReq.id}`, {
      pageNo: groupUserApproveReq.pageNo,
      pageSize: groupUserApproveReq.pageSize,
      searchType: groupUserApproveReq.searchType,
      searchContext: groupUserApproveReq.searchContext,
    })
      .then(function (response) {
        groupUserApproveReq.onSuccess(response.data);
      })
      .catch(function (err) {
        groupUserApproveReq.onError(err);
      });
  }
  //审批状态
  getApproveStatus(groupUserApproveReq: groupUserApproveRequest) {
    return this.getPut(`groupUserApprove/${groupUserApproveReq.id}`, {
      status: groupUserApproveReq.status,
    })
      .then(function (response) {
        groupUserApproveReq.onSuccess(response.data);
      })
      .catch(function (err) {
        groupUserApproveReq.onError(err);
      });
  }
}
