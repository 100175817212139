import { defineComponent } from "vue";
import headerComponent from "../header/HeaderComponent.vue";
import contentComponent from "../content/ContentComponent.vue";
import footerComponent from "../footer/FooterComponent.vue";

export default defineComponent({
  name: "WelcomeComponent",
  components: {
    footerComponent,
    headerComponent,
    contentComponent,
  },
});
