
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn")
import zhCN from 'ant-design-vue/es/locale/zh_CN';

export default {
  name: "App",
  data() {
    return {
      locale: zhCN
    };
  }
};
