/* eslint-disable @typescript-eslint/no-this-alias */
import { defineComponent } from "vue";
import { ProfileModel } from "./../../entity/pagemodel/ProfileModel";
import { Profile } from "./../../entity/Profile";
import { ProfileService } from "./../../service/ProfileService";
import  log  from "./../../service/log";
export default defineComponent({
  data() {
    return {
      ...new ProfileModel(),
      startTime: Date.now(),
    };
  },
  methods: {
    cancel: function () {
      log.info({
        p: "p",
        a: "c",
      });
      this.$router.push("/");
    },
    save: function () {
      if (!this.company) {
        this.message = "单位名称必填";
        return;
      }
      if (!this.department) {
        this.message = "部门名称必填";
        return;
      }
      const profile = new Profile();
      profile.company = this.company;
      profile.department = this.department;
      profile.name = this.name;
      profile.departmentPhone = this.departmentPhone;
      const profileService = new ProfileService(this.$router);
      const self = this;
      profileService.put(profile, () => {
        self.message = "保存成功，3秒后返回...";
        setTimeout(function () {
          self.$router.push("/");
        }, 3000);
      });
    },
  },
  mounted() {
    log.info({
      p: "p",
      a: "m",
    });
    const profileService = new ProfileService(this.$router);
    const self = this;
    profileService.getOne((profile) => {
      self.name = profile.name;
      self.company = profile.company;
      self.department = profile.department;
      self.departmentPhone = profile.departmentPhone;
    });
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "p",
      a: "u",
      d: stayTime,
    });
  },
});
