import { defineComponent } from "vue";
import log from "../../service/log";
import { AccountService } from "./../../service/AccountService";
export default defineComponent({
  data() {
    return {
      startTime: Date.now(),
      bg: require("@/assets/sign/积分背景.png"),
      dataList: <any>[],
      point: 0,
      path: require("@/assets/sign/icon-上传文档.png"),
      signList: <any>[
        {
          id: 1,
          code: "sign",
          title: "签到领取积分",
          description: "每天领取一次，积分可兑换好礼",
          path: require("@/assets/sign/icon-签到领积分.png"),
          todaySigned: true,
          point: 1,
          status: 0,
        },
      ],
      list: <any>{
        point: "10",
        csd: "0",
        tsd: "0",
      },
    };
  },
  mounted() {
    log.info({
      p: "u",
      a: "m",
    });
    this.getAccount();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "u",
      a: "u",
      d: stayTime,
    });
  },
  name: "MissionView",
  methods: {
    getAccount: function () {
      const service: AccountService = new AccountService(this.$router);
      service.getAccount({
        onSuccess: (resp: any) => {
          for (let i = 0; i < this.signList.length; i++) {
            if (this.signList[i].code === "sign") {
              this.signList[i].point = resp.tcd;
              this.signList[i].todaySigned = resp.todaySigned;
              this.signList[i].status = resp.todaySigned ? 2 : 0;
              break;
            }
          }
          this.point = resp.point;
          this.dataList = resp.tasks;
        },
        onError(err) {
          console.log(err);
        },
      });
    },
    gettingAward: function (code: string) {
      const service: AccountService = new AccountService(this.$router);
      service.gettingAward({
        code: code,
        onSuccess: () => {
          this.getAccount();
        },
        onError(err) {
          console.log(err);
        },
      });
    },
    getButtonColor(status: number) {
      if (status === 0) {
        return "white";
      } else if (status === 1) {
        return "#ff976a";
      } else if (status === 2) {
        return "#D3D3D3";
      }
    },
    getButtonText(status: number, code: string) {
      if (code == "sign" && status == 0) {
        return "去签到";
      } else if (code == "uploadAFile" && status == 0) {
        return "去上传";
      }
      if (status === 0) {
        return "未完成";
      } else if (status === 1) {
        return "领积分";
      } else if (status === 2) {
        return "已领取";
      }
    },
    isButtonDisabled(status: number) {
      return status === 2;
    },
    handleButtonClick(status: number, code: string) {
      if (code == "sign" && status == 0) {
        this.$router.push("/account/sign");
      } else if (code == "uploadAFile" && status == 1) {
        this.gettingAward(code);
      } else if (code == "uploadAFile" && status == 0) {
        this.$router.push("/");
      }
    },
  },
});
