import { defineComponent } from "vue";
import { FileImageOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import log from "../../service/log";
import { ConvertImageService } from "@/service/ConvertImageService";
import { TicketService } from "../../service/TicketService";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
import draggable from "vuedraggable";
import { OcrFileService } from "./../../service/OcrFileService";
import axios from "axios";

import {
  ArrowUpOutlined,
  InboxOutlined,
  PlusOutlined,
  MinusOutlined,
  DownloadOutlined,
  CompressOutlined,
  UploadOutlined,
  EditOutlined,
  CloudUploadOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  data() {
    return {
      scale: 1,
      ticket: <any>"",
      superiorId: <any>0,
      pollCount: <any>0, //轮询次数
      ids: <any>[],
      timer: null as any,
      startTime: Date.now(),
      list: <any>[], //文档列表
      selectedKeys: <any>[],
      selectedItem: <any>[],
      noTraceModeStatus: false, //是否开启无痕模式
      childList: <any>[], //文件(图片）列表
      drag: false,
      uploadStatus: false,
      moveList: <any>[],
      appendStatus: false,
      moveStatus: false,
      delStatus: false,
      currentFileItem: <any>{}, //当前选中的文档条目信息
      tracelessChecked: true, //无痕模式开关
      fileList: <any>[],
      visible: false, // 模板提示框
      info: <any>{}, //文件信息
      pageCount: 0,
      mergeStatus: false,
      badgeStatus: false,
      mergeid: 0,
      swiperlist: [],
      activeKey: 0,

      //当前焦点节点的key
      currentNodeKey: null as any,
      currentselectItem: <any>[], //当前选中的文档条目信息
      childrenIndex: -1 as number, //当前子节点索引
      currentIndex: 0 as number, //当前结果的list索引
      showInputGroup: false, // 标志位，用于控制是否显示输入框组件
      memo: "",
      loading: false as boolean,
      fileid: 0,

      /**canvas start............ */
      canvasWidth: 1800, // 画布大小
      canvasHeight: 1210,
      iconStatus: false,
      iconHover: false,
      canvasOffset: { left: 0, top: 0 },
      zoomRatio: 1,
      rotation: 0,
      canvas: <any>null,
      image: <any>null,
      context: <any>null,
      currentImageBase64: "",
      imageX: 0,
      imageY: 0,
      x: 0,
      y: 0,
      startX: 0,
      startY: 0,
      width: 0,
      height: 0,
      selectionStartX: 0,
      selectionStartY: 0,
      selectionEndX: 0,
      selectionEndY: 0,
      isSelecting: false,
      isDragging: false,
      status: 0,
      /**canvas end............ */
      myCanvas: <any>null,
      ctx: <any>null,
      imgObject: <any>[],
      local: <any>null,
      imgX: 0, // 图片在画布中渲染的起点x坐标
      imgY: 0,
      imgScale: 1, // 图片的缩放大小
      oldImgScale: 0, //记录原始图片缩放大小
      oldImgX: 0, // 图片在画布中渲染的起点x坐标
      oldImgY: 0,
      extraImgList: <any>[], //图片列表
      ocrTextResult: "",
      resizeObserver: <any>null, //监听
      mouseX: 0,
      mouseY: 0,
      dragStartX: 0,
      dragStartY: 0,

      capturing: false,
      endX: 0,
      endY: 0,
      imageIndex: 0,
      progress: 0, //进度条数值
      timing: <any>null, //加载定时器
      ocrCount: 0, //轮询ocr状态次数
      ocrTimer: <any>null,
      ocrModelShow: false,
      ocrResultShow: false,
      devicePixelRatio: window.devicePixelRatio,
    };
  },
  components: {
    draggable,
    FileImageOutlined,
    CloseOutlined,
    LeftCircleOutlined,
    RightCircleOutlined,

    ArrowUpOutlined,
    InboxOutlined,
    PlusOutlined,
    MinusOutlined,
    DownloadOutlined,
    CompressOutlined,
    UploadOutlined,
    EditOutlined,
    CloudUploadOutlined,
    LeftOutlined,
    RightOutlined,
  },
  name: "HomeView",
  mounted() {
    log.info({
      p: "t",
      a: "m",
    });
    this.getImageFileList();
    this.getTicket();
    //this.getCanvas();
    //this.drawCanvas();
    //this.setupEventListeners();

    this.startTime = Date.now();
    this.dragListener();

    //禁用默认拖拽事件
    this.disableDefaultDragEvents();
    window.addEventListener("resize", this.updateScreenWidth);
    // //注册全局拖拽事件
    // this.dragListener();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    window.removeEventListener("resize", this.updateScreenWidth);
    log.info({
      p: "t",
      a: "u",
      d: stayTime,
    });
  },
  computed: {
    scaledSize(): any {
      if (this.devicePixelRatio > 1) {
        return 1 / this.devicePixelRatio;
      } else {
        return 1;
      }
    },
  },
  watch: {
    scaledSize(newId: any) {
      return newId;
    },
  },
  methods: {
    //获取动态div宽高
    initResizeObserver() {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === this.$refs.middle) {
            // 检查目标元素是否为 div1
            this.handleDivWidthChange();
          }
        }
      });
      resizeObserver.observe(this.$refs.middle as any); // 开始监听 div1 的尺寸变化
      this.resizeObserver = resizeObserver; // 将 ResizeObserver 对象保存到实例属性中
    },
    destroyResizeObserver() {
      this.resizeObserver.disconnect(); // 停止监听
    },
    handleDivWidthChange() {
      this.updateScreenWidth(); // 宽度发生变化时执行 getCanvas 方法
    },
    updateScreenWidth() {
      this.devicePixelRatio = window.devicePixelRatio;
    },
    //注册全局拖拽事件
    dragListener: function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const canvas = document.getElementById("canvas") as any;
      // 阻止浏览器默认行为，使其支持拖放操作
      canvas.addEventListener(
        "dragover",
        function (e: any) {
          e.preventDefault();
        }.bind(this)
      );
      canvas.addEventListener(
        "drop",
        function (e: any) {
          e.preventDefault();
          const file = e.dataTransfer.files;
          if (file) {
            that.beforeRead(file);
          }
        }.bind(this)
      );
    },

    async initCanvas() {
      this.zoomRatio = 0;
      this.rotation = 0;
      this.imageX = 0;
      this.imageY = 0;
      this.x = 0;
      this.y = 0;
      this.startX = 0;
      this.startY = 0;
      this.width = 0;
      this.height = 0;
      this.selectionStartX = 0;
      this.selectionStartY = 0;
      this.selectionEndX = 0;
      this.selectionEndY = 0;
    },

    startTimer() {
      // 使用setInterval函数每秒钟执行一次更新进度的函数
      this.progress = 0;
      this.timing = setTimeout(() => {
        this.updateProgress();
      }, 2000);
    },
    stopTimer() {
      // 停止计时器
      clearInterval(this.timing);
      this.timing = setTimeout(() => {
        this.ocrModelShow = false;
        if (this.currentFileItem.ocrStatus == 1) {
          this.ocrResultShow = true;
        }
      }, 1000);
    },
    updateProgress() {
      // 获取当前时间
      const currentTime = new Date().getSeconds();
      // 根据时间判断要增加的进度
      if (currentTime % 2 === 0) {
        this.progress += 5;
      }
      if (currentTime % 3 === 0) {
        this.progress += 7;
      }

      if (this.progress > 88 && this.currentFileItem.ocrStatus == 0) {
        this.progress = 99;
      } else {
        this.progress = 100;
      }

      // 进度达到100后重置为0
      if (this.progress >= 100) {
        this.stopTimer();
      }
    },
    getTicket() {
      this.ticket = new TicketService().get();
    },
    // 获取文档列表
    getImageFileList() {
      const service: ConvertImageService = new ConvertImageService(
        this.$router
      );
      const obj = {
        pageNo: 1,
        pageSize: 500,
        noTraceModeStatus: this.noTraceModeStatus,
      };
      service.getImageFileList(obj, (data) => {
        this.list = data;
        this.checkStatus();
      });
    },
    //删除
    delImage(ids: any) {
      const service: ConvertImageService = new ConvertImageService(
        this.$router
      );
      service.delImage({
        ids: ids,
        onSuccess: (resp: any) => {
          if (resp.state == "true") {
            message.success("删除成功");
            this.getFileById(this.superiorId);
          }
        },
        onError() {
          message.error("删除失败 ");
        },
      });
    },
    //点击左侧图片树，切换图片信息
    treeHandleClick(e: number[]) {
      log.info({
        p: "t",
        a: "c",
      });
      this.selectedKeys = e[0];
      this.getFileById(e[0]);
    },
    treeClick(event: any, node: any) {
      log.info({
        p: "d",
        a: "c",
        o: "treeClick",
      });

      if (node.id == this.mergeid) {
        this.badgeStatus = false;
      }
      if (event.ctrlKey) {
        // 检查是否按下了 Ctrl 键
        const nodeIndex = this.selectedKeys.indexOf(node.key);
        if (nodeIndex === -1) {
          this.selectedKeys.push(node.key); // 添加到已选择的键中
          this.selectedItem.push(node);
        } else {
          this.selectedKeys.splice(nodeIndex, 1); // 从已选择的键中移除
          this.selectedItem.splice(nodeIndex, 1);
        }
      } else {
        this.selectedKeys = [node.key]; // 单选时直接替换已选择的键
        this.selectedItem = [node];
        this.getFileById(this.selectedKeys);
        //this.getImageById();
      }
    },
    batchDownloadImage(ids: any, type: string) {
      const baseUrl = process.env.VUE_APP_API_URL;
      const types = type;
      const service: ConvertImageService = new ConvertImageService(
        this.$router
      );
      service.batchDownloadImage({
        type: type,
        ids: ids,
        onSuccess: (resp: any) => {
          if (type == "batchDownload") {
            const path = resp.filepath;
            const a = document.createElement("a");
            a.href = `${baseUrl}convert/common/download?filepath=${path}`;
            a.click();
            message.success("下载成功");
          }
        },
        onError() {
          if (types == "batchDownload") {
            message.error("下载失败 ");
          }
        },
      });
    },
    downloadFile() {
      log.info({
        p: "d",
        a: "c",
        o: "downloadFileBtnClick",
      });
      if (this.selectedItem.length == 0) {
        message.warning("请选择下载的Image");
        return;
      } else {
        const file = this.getPath(this.selectedItem);
        this.repack(file);
        //this.batchDownloadImage(ids, "batchDownload");
      }
    },
    repack: function (file: any) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const service: ConvertImageService = new ConvertImageService(
        this.$router
      );
      service.getCheckImage({
        type: "repack",
        ids: file[0].id,

        onSuccess: (resp) => {
          if (resp[0].state == "true") {
            let content = "";
            if (resp[0].payType == "addFiles") {
              content = `由于您追加上传图片,需额外消耗${resp[0].TotalNeedPoint}积分,确认下载这个文件吗`;
            } else {
              content = `需要消耗${resp[0].TotalNeedPoint}积分确认下载这个文件吗`;
            }
            Modal.confirm({
              title: "提示",
              content: content,
              okText: "确认",
              cancelText: "取消",
              onOk: () => {
                this.batchDownloadImage(file[0].id, "batchDownload");
              },
            });
          } else {
            this.batchDownloadImage(file[0].id, "batchDownload");
          }
        },
        onError() {
          message.error("验证失败 ");
        },
      });
    },
    getPath(list: any) {
      const file = list.map((item: any) => ({
        id: item.id,
        fileName: item.fileName,
        memo: item.memo,
        filePath: item.filePath,
        fileType: item.fileType,
      }));
      return file;
    },
    Move: function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const service: ConvertImageService = new ConvertImageService(
        this.$router
      );
      service.move({
        id: this.superiorId,
        list: this.moveList,
        onSuccess: (resp) => {
          if (resp.state == "true") {
            message.success("顺序调整成功");
            this.uploadStatus = true;
            this.moveStatus = true;
            this.getImageFileList();
          }
        },
        onError() {
          self.uploadStatus = false;
          self.moveStatus = false;
          message.error("顺序调整失败 ");
        },
      });
    },
    endMove() {
      const newArray = this.childList.map(
        (
          item: { id: number; listOrder: number; fieldCName: string },
          index: number
        ) => ({
          id: item.id,
          listOrder: index + 1,
        })
      );
      const jsonString = JSON.stringify(newArray);
      this.moveList = jsonString;
      this.Move();
    },
    checkStatus() {
      let hasLoadingData = false;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].convertStatus == 0) {
          hasLoadingData = true;
        }
      }
      if (hasLoadingData) {
        if (this.pollCount < 500) {
          // 检查轮询次数
          this.pollCount++; // 轮询次数加一
          this.timer = setTimeout(() => {
            this.getImageFileList();
          }, 2000);
        } else {
          clearTimeout(this.timer); // 停止轮询
          if (this.uploadStatus) {
            message.error("转换图片失败,已超时...");
          }
        }
      } else {
        clearTimeout(this.timer);
        if (this.uploadStatus) {
          message.success("转换图片成功,请您查看...");
        }
      }
    },
    //创建任务
    beforeRead(file: any) {
      // 创建 FormData 对象
      const loading = message.loading("文件上传中，请稍等", 0);
      const formData = new FormData();
      if (file.length == undefined) {
        formData.append("files", file);
      } else {
        for (let i = 0; i < file.length; i++) {
          formData.append("files", file[i]);
        }
      }
      const xhr = new XMLHttpRequest();
      const baseUrl = process.env.VUE_APP_API_URL;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      xhr.open("POST", `${baseUrl}convert/image`);
      xhr.setRequestHeader("apiVersion", "0.1.0");
      xhr.setRequestHeader("ticket", this.ticket);
      xhr.onload = function () {
        if (xhr.status === 200) {
          // 上传成功处理代码
          loading();
          message.success("上传成功");
          self.uploadStatus = true;
          self.pollCount = 0;
          self.getImageFileList();
        } else {
          self.uploadStatus = false;
          // 上传失败处理代码
          message.error("上传失败");
        }
      };
      xhr.send(formData);
    },
    append() {
      if (this.superiorId == 0) {
        message.warning("请选择要追加的图片");
        return;
      }
    },

    Appendupload(file: any) {
      log.info({
        p: "d",
        a: "c",
        o: "appendFileBtnClick",
      });

      const loading = message.loading("追加文件上传中，请稍等", 0);
      // 创建 FormData 对象
      const formData = new FormData();
      if (file.length == undefined) {
        if (isImage(file)) {
          // 检查单个文件是否为图片
          formData.append("files", file);
        } else {
          loading();
          message.error("只允许上传图片文件(jpg 和 png)");
          return;
        }
      } else {
        for (let i = 0; i < file.length; i++) {
          if (isImage(file[i])) {
            // 检查每个文件是否为图片
            formData.append("files", file[i]);
          } else {
            loading();
            message.error("只允许上传图片文件(jpg 和 png)");
            return;
          }
        }
      }

      formData.append("type", "addFiles");
      formData.append("superiorId", this.superiorId);
      const xhr = new XMLHttpRequest();
      const baseUrl = process.env.VUE_APP_API_URL;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      xhr.open("POST", `${baseUrl}convert/image`);
      xhr.setRequestHeader("apiVersion", "0.1.0");
      xhr.setRequestHeader("ticket", this.ticket);
      xhr.onload = function () {
        if (xhr.status === 200) {
          // 上传成功处理代码
          loading();
          message.success("上传成功");
          self.uploadStatus = true; //上传成功
          self.appendStatus = true; //追加成功
          self.getImageFileList();
          self.pollCount = 0;
          self.getFileById(self.superiorId);
        } else {
          // 上传失败处理代码
          self.uploadStatus = false;
          self.appendStatus = false;
          message.error("上传失败");
        }
      };
      xhr.send(formData);

      // 辅助函数，检查文件是否为图片
      function isImage(file: any) {
        const allowedExtensions = [".jpg", ".jpeg", ".png"];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        return allowedExtensions.includes("." + fileExtension);
      }
    },

    onSelect(selectedKeys: string[]) {
      this.selectedKeys = selectedKeys;
    },
    countpages() {
      log.info({
        p: "d",
        a: "c",
        o: "countpagesBtnClick",
      });
      let list = [];
      if (this.selectedKeys.length == 0) {
        list = this.list.map((item: any) => ({
          fileName: item.memo,
          pageCount: item.pageCount,
        }));
      } else {
        list = this.list
          .filter((item: any) => this.selectedKeys.includes(item.id))
          .map((item: any) => ({
            fileName: item.memo,
            pageCount: item.pageCount,
          }));
      }

      const text = list
        .map(
          (item: { fileName: any; pageCount: any }) =>
            `名称: '${item.fileName}', 页数: ${item.pageCount}`
        )
        .join("\n");
      const element = document.createElement("a");
      const file = new Blob([text], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = "计算图片页数.txt";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    //移除图片
    deleteImage(element: any) {
      this.delImage(element.id);
    },
    downImage(e: any) {
      // 发送 GET 请求获取图片数据
      console.log(e.imageUrl);
      fetch(e.imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // 创建临时下载链接
          const url = window.URL.createObjectURL(blob);

          // 设置下载链接的属性，并模拟点击下载链接
          const link = document.createElement("a");
          link.href = url;
          link.download = e.memo + "." + e.fileType;
          link.click();

          // 清除临时下载链接
          window.URL.revokeObjectURL(url);
        });
    },
    sourcetext() {
      if (this.selectedItem.length === 1) {
        const file = this.selectedItem[0];
        // 构建文件的下载链接
        const baseUrl = process.env.VUE_APP_API_URL;
        const filepath = `${baseUrl}convert/common/download?filepath=${file.filePath}${file.fileName}.${file.fileType}`;
        // 设置下载链接的属性，并模拟点击下载链接
        const link = document.createElement("a");
        link.href = filepath;
        link.download = file.memo; // 设置文件名
        link.click();
        // 清除临时下载链接
        window.URL.revokeObjectURL(filepath);
      }
    },
    //根据id查询单条目文档信息
    getFileById(id: number | undefined) {
      if (id) {
        this.superiorId = id;
        const service: ConvertImageService = new ConvertImageService(
          this.$router
        );
        //1.获取Image对应的子文件列表

        service.getChildList(id, (data) => {
          this.childList = data;
          this.imageIndex = 0;
          this.initData();
          this.getImageById();
          // this.initCanvas();
          // this.createCanvas();
        });
      } else {
        this.currentselectItem = {};
      }
    },
    //加载图片
    // loadingImage() {
    //   // 构建文件的下载链接
    //   const baseUrl = process.env.VUE_APP_API_URL;
    //   const path = `${baseUrl}convert/common/preview/${
    //     this.childList[this.imageIndex].id
    //   }?type=image&ticket=${this.ticket}`;
    //   this.currentImageBase64 = path;
    //   this.initCanvas();
    //   this.drawCanvas();
    // },
    nextNode() {
      // 切换至下一页的逻辑
      // ...
      if (this.currentImageBase64) {
        if (this.imageIndex < this.childList.length - 1) {
          this.imageIndex++;
          this.initData();
          this.getImageById();
        } else if (this.imageIndex == this.childList.length - 1) {
          this.imageIndex = 0;
          this.initData();
          this.getImageById();
        }
      }
    },
    prevNode() {
      if (this.currentImageBase64) {
        if (this.imageIndex > 0) {
          this.imageIndex--;
          this.getImageById();
        } else if (this.imageIndex == 0) {
          this.imageIndex = this.childList.length - 1;
          this.getImageById();
        }
      }
    },

    //放大图片
    // async amplificationImage() {
    //   this.zoomRatio += 0.05;
    //   this.drawCanvas();
    // },

    // //缩小图片
    // async narrowImage() {
    //   this.zoomRatio -= 0.05;
    //   this.drawCanvas();
    // },

    // 还原图片
    //还原缩放按钮
    returnScale() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      if (self.currentImageBase64 == "") {
        return;
      }
      self.imgScale = self.oldImgScale;
      //清空画布
      self.ctx.clearRect(0, 0, self.canvasWidth, self.canvasHeight);
      const imgList = self.imgObject;
      const imageList = self.extraImgList;
      const iw = imageList[0].width;
      const ih = imageList[0].height;
      const local = self.calculate(iw, ih);

      for (let i = 0; i < imgList.length; i++) {
        self.ctx.drawImage(
          imgList[i].img, //规定要使用的图片
          local.px * self.imgScale,
          local.py * self.imgScale,
          local.pw * self.imgScale,
          local.ph * self.imgScale
        );
      }
    },

    // 下载图片
    downLoadPngImage() {
      fetch(this.currentImageBase64)
        .then((response) => response.blob())
        .then((blob) => {
          // 创建临时下载链接
          const url = window.URL.createObjectURL(blob);

          // 设置下载链接的属性，并模拟点击下载链接
          const link = document.createElement("a");
          link.href = url;
          link.download = "image.jpg";
          link.click();

          // 清除临时下载链接
          window.URL.revokeObjectURL(url);
        });
    },

    //无痕模式切换
    updateNoTraceMode(e: any) {
      log.info({
        p: "f",
        a: "c",
        o: "updateNoTraceModeBtn",
      });
      this.noTraceModeStatus = e;
      if (e) {
        sessionStorage.setItem("isTracelessMode", "true");
        message.success("已切换至无痕模式，可安心查阅!");
      } else {
        sessionStorage.setItem("isTracelessMode", "false");
        message.success("已切换至普通模式!");
      }
    },

    init() {
      const service: ConvertImageService = new ConvertImageService(
        this.$router
      );
      for (let i = 0; i < this.ids.length; i++) {
        service.getFileById(this.ids[i], (data: any) => {
          for (let j = 0; i < this.list.length; j++) {
            if (this.list[j].id == this.ids[i]) {
              // 找到了匹配的对象
              this.list[j] = data;
            }
          }
        });
      }
      this.checkStatus();
    },

    // 禁用默认拖拽事件
    disableDefaultDragEvents: function () {
      const doc = document.documentElement;
      doc.addEventListener("dragleave", (e) => e.preventDefault()); //拖离
      doc.addEventListener("drop", (e) => e.preventDefault()); //拖后放
      doc.addEventListener("dragenter", (e) => e.preventDefault()); //拖进
      doc.addEventListener("dragover", (e) => e.preventDefault()); //拖来拖去
    },

    //检查文件大小
    checkSize: function (file: any) {
      const maxSize = 10 * 1024 * 1024;
      const sizeInMB = file.size / (1024 * 1024);
      if (sizeInMB > maxSize) {
        message.error(`文件大小不得超过10MB`);
        return;
      }
    },

    //
    getFileFromUrl(url: string | URL, fileName: string, type: string) {
      return new Promise((resolve, reject) => {
        let blob = null;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Accept", "image/" + type);
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
          // 获取返回结果
          blob = xhr.response;
          const file = new File([blob], fileName + "." + type, {
            type: "image/" + type,
          });
          // 返回结果
          resolve(file);
        };
        xhr.onerror = (e) => {
          reject(e);
        };
        // 发送
        xhr.send();
      });
    },
    //根据id查询单条目文档信息
    getOcrResultById(id: number | undefined) {
      if (id) {
        const service: OcrFileService = new OcrFileService(this.$router);
        service.getResult({
          id: id,
          onSuccess: (resp) => {
            this.currentFileItem = resp;
            if (this.currentFileItem.result) {
              const result = eval(`(${this.currentFileItem.result})`);
              if (result) {
                this.ocrTextResult = result.result.summary
                  .replace(/\n/g, "<br>")
                  .replace(/ /g, "&nbsp;");
              }
            }
            this.checkOcrStatus(id);
          },
          onError() {
            message.error("识别失败");
          },
        });
      } else {
        this.currentFileItem = {};
      }
    },
    checkOcrStatus(id: number) {
      let hasLoadingOcr = false;
      if (this.currentFileItem.ocrStatus == 0) {
        hasLoadingOcr = true;
      }

      if (hasLoadingOcr) {
        if (this.ocrCount < 30) {
          // 检查轮询次数
          this.ocrCount++; // 轮询次数加一
          this.ocrTimer = setTimeout(() => {
            this.getOcrResultById(id);
          }, 2000);
        } else {
          clearTimeout(this.ocrTimer); // 停止轮询
          this.ocrCount = 0;
          message.error("提取文字失败,已超时...");
        }
      } else {
        clearTimeout(this.ocrTimer);
        this.ocrCount = 0;
        if (this.currentFileItem.ocrStatus == 1) {
          message.success("提取文字成功，请查看");
        } else if (this.currentFileItem.ocrStatus == -1) {
          message.error("提取文字失败");
        }
      }
    },

    //提取文字
    extractext(e: any) {
      this.ocrModelShow = true;
      this.startTimer();
      this.getFileFromUrl(e.imageUrl, e.fileName, e.fileType)
        .then((response) => {
          const file = response;
          this.OcrCreate(file);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    //ocr识别
    OcrCreate(files: any) {
      const formData = new FormData();
      if (files.length == undefined) {
        formData.append("file", files);
      } else {
        for (let i = 0; i < files.length; i++) {
          formData.append("file", files[i]);
        }
      }

      const baseUrl = process.env.VUE_APP_API_URL;
      axios
        .post(`${baseUrl}ocr/file/upload`, formData, {
          headers: {
            apiVersion: "0.1.0",
            ticket: this.ticket,
          },
        })
        .then((response: any) => {
          const id = response.data.join("");

          this.getOcrResultById(id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleCancel() {
      this.ocrResultShow = false;
    },
    handleOk() {
      this.ocrResultShow = false;
    },
    getImageById() {
      const baseUrl = process.env.VUE_APP_API_URL;
      const path = `${baseUrl}convert/common/preview/${
        this.childList[this.imageIndex].id
      }?type=image&ticket=${this.ticket}`;
      this.currentImageBase64 = path;
      this.createCanvas();
    },

    //创建画布
    createCanvas() {
      //1.创建image
      const imgObj = new Image();
      // imgObj.setAttribute("crossOrigin", "anonymous");
      //imgObj.src = "data:image/png;base64," + this.currentImageBase64;
      imgObj.src = this.currentImageBase64;
      //2.预加载图片
      imgObj.onload = () => {
        //3.创建图片对象
        const obj = {
          //url: "data:image/png;base64," + this.currentImageBase64,
          url: this.currentImageBase64,
          x: 0,
          y: 0,
          width: imgObj.width,
          height: imgObj.height,
        };
        // 重置画布的缩放属性

        //4.记录原始缩放大小，x轴、y轴坐标
        this.oldImgScale = this.imgScale;
        this.oldImgX = this.imgX;
        this.oldImgY = this.imgY;
        //5.设置图片列表
        this.extraImgList[0] = obj;
        //6.创建画布
        this.myCanvas = this.$refs.bargraphCanvas;
        this.ctx = this.myCanvas.getContext("2d");
        //7.加载图片信息
        this.loadingImage();
        //8.在画布上注册鼠标的拖动和缩放事件
        this.canvasEventsInit();
      };
    },
    //加载图片信息
    loadingImage() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const extraImgList = self.extraImgList;
      const length = extraImgList.length;
      const imageList = <any>[];
      let count = 0;
      //1.加载图片
      const img = new Image();
      const bgImg = extraImgList[0];
      img.src = bgImg.url;
      img.onload = () => {
        imageList.push({
          img: img,
          x: bgImg.x,
          y: bgImg.y,
          width: bgImg.width,
          height: bgImg.height,
        });
        ++count;
        if (length > 1) {
          //加载剩余图片
          for (let key = 1; key < length; key++) {
            const item = extraImgList[key];
            const extarImg = new Image();
            extarImg.src = item.url;
            extarImg.onload = () => {
              imageList.push({
                img: extarImg,
                x: item.x,
                y: item.y,
                width: item.width,
                height: item.height,
              });
              if (++count >= length) {
                self.imgObject = imageList;
                self.drawImage(imageList, 0, 0, 0, 0);
              }
            };
          }
        } else {
          const iw = imageList[0].width;
          const ih = imageList[0].height;
          self.ctx.fillStyle = "white";
          self.ctx.fill();
          self.imgObject = imageList;
          //2.计算最佳尺寸四个角坐标
          const local = self.calculate(iw, ih);
          self.local = local;
          //3.将图片加载到画布上
          self.drawImage(imageList, local.px, local.py, local.pw, local.ph);
        }
      };
    },
    //计算图片最佳缩放大小，返回四个角坐标
    calculate(pw: number, ph: number) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const canvas = self.myCanvas;
      const w = canvas.width;
      const h = canvas.height;

      let px = 0;
      let py = 0;
      if (pw < w && ph < h) {
        px = 0.5 * w - 0.5 * pw;
        py = 0.5 * h - 0.5 * ph;
      } else if (ph / pw > h / w) {
        const uu = ph;
        ph = h;
        pw = (pw * h) / uu;
        px = 0.5 * w - 0.5 * pw;
      } else {
        const uu = pw;
        pw = w;
        ph = (ph * pw) / uu;
        py = 0.5 * h - 0.5 * ph;
      }
      self.imgObject[0].x = px;
      self.imgObject[0].y = py;
      self.imgObject[0].width = pw;
      self.imgObject[0].height = ph;
      self.imgX = px;
      self.imgY = py;
      return {
        px,
        py,
        pw,
        ph,
      };
    },
    //将图片加载到画布上
    drawImage(imgList: any, x: number, y: number, w: number, h: number) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      for (let i = 0; i < imgList.length; i++) {
        self.ctx.drawImage(
          imgList[i].img, //规定要使用的图片
          x * self.imgScale,
          y * self.imgScale,
          w * self.imgScale,
          h * self.imgScale
        );
      }
    },

    //在画布上注册鼠标的拖动和缩放事件
    canvasEventsInit() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const canvas = self.myCanvas;
      //拖动鼠标
      canvas.onmousedown = function (event: any) {
        const imgx = self.imgX;
        const imgy = self.imgY;
        const pos = {
          x: event.clientX,
          y: event.clientY,
        }; //坐标转换，将窗口坐标转换成canvas的坐标
        canvas.onmousemove = function (evt: any) {
          //移动
          canvas.style.cursor = "move";
          const x = (evt.clientX - pos.x) * 2 + imgx;
          const y = (evt.clientY - pos.y) * 2 + imgy;
          self.imgX = x;
          self.imgY = y;
          self.drawImage(
            self.imgObject,
            x,
            y,
            self.imgObject[0].width,
            self.imgObject[0].height
          ); //重新绘制图片

          //如果当前左上tab为显示标注，则拖动时应刷新标注位置
        };
        canvas.onmouseup = function () {
          canvas.onmousemove = null;
          canvas.onmouseup = null;
          canvas.style.cursor = "default";
        };
      };
      //滚轮放大缩小
      canvas.onmousewheel = canvas.onwheel = function (event: any) {
        //滚轮放大缩小
        const pos = self.windowToCanvas(event.clientX, event.clientY);
        const wheelDelta = event.wheelDelta
          ? event.wheelDelta
          : event.deltalY * -40; //获取当前鼠标的滚动情况
        const newPos = <any>{
          x: ((pos.x - self.imgX) / self.imgScale).toFixed(2),
          y: ((pos.y - self.imgY) / self.imgScale).toFixed(2),
        };
        if (wheelDelta > 0) {
          // 放大
          self.imgScale += 0.1;
          self.imgX = (1 - self.imgScale) * newPos.x + (pos.x - newPos.x);
          self.imgY = (1 - self.imgScale) * newPos.y + (pos.y - newPos.y);
        } else {
          //  缩小
          self.imgScale -= 0.1;
          if (self.imgScale < 0.3) {
            //最小缩放1
            self.imgScale = 0.3;
          } else {
            self.imgX = (1 - self.imgScale) * newPos.x + (pos.x - newPos.x);
            self.imgY = (1 - self.imgScale) * newPos.y + (pos.y - newPos.y);
          }
        }
        self.drawImage(
          self.imgObject,
          self.imgX,
          self.imgY,
          self.imgObject[0].width,
          self.imgObject[0].height
        ); //重新绘制图片
        event.preventDefault && event.preventDefault();
      };
    },

    //放大图片
    amplificationImage() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      if (self.currentImageBase64 == "") {
        return;
      }
      this.imgScale = this.imgScale + 0.1;
      //清空画布
      self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      const imgList = self.imgObject;
      const imageList = self.extraImgList;
      const iw = imageList[0].width;
      const ih = imageList[0].height;
      const local = self.calculate(iw, ih);
      for (let i = 0; i < imgList.length; i++) {
        self.ctx.drawImage(
          imgList[i].img, //规定要使用的图片
          local.px * self.imgScale,
          local.py * self.imgScale,
          local.pw * self.imgScale,
          local.ph * self.imgScale
        );
      }
    },
    //缩小图片
    narrowImage() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      if (self.currentImageBase64 == "") {
        return;
      }
      if (this.imgScale > 0.2) {
        this.imgScale = this.imgScale - 0.1;
        //清空画布
        self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        const imgList = self.imgObject;
        const imageList = self.extraImgList;
        const iw = imageList[0].width;
        const ih = imageList[0].height;
        const local = self.calculate(iw, ih);
        for (let i = 0; i < imgList.length; i++) {
          self.ctx.drawImage(
            imgList[i].img, //规定要使用的图片
            local.px * self.imgScale,
            local.py * self.imgScale,
            local.pw * self.imgScale,
            local.ph * self.imgScale
          );
        }
      }
    },
    /*坐标转换*/
    windowToCanvas(x: any, y: any) {
      const box = this.myCanvas.getBoundingClientRect(); //这个方法返回一个矩形对象，包含四个属性：left、top、right和bottom。分别表示元素各边与页面上边和左边的距离
      return {
        x: x - box.left - (box.width - this.myCanvas.width) / 2,
        y: y - box.top - (box.height - this.myCanvas.height) / 2,
      };
    },
    //初始化画布上的图片
    initImage() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      //清空画布
      self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      const imgList = self.imgObject;
      const imageList = self.extraImgList;
      const iw = imageList[0].width;
      const ih = imageList[0].height;
      const local = self.calculate(iw, ih);
      for (let i = 0; i < imgList.length; i++) {
        self.ctx.drawImage(
          imgList[i].img, //规定要使用的图片
          local.px * self.imgScale,
          local.py * self.imgScale,
          local.pw * self.imgScale,
          local.ph * self.imgScale
        );
      }
    },
    //初始化基础数据
    initData() {
      this.imgObject = <any>[];
      this.local = <any>null;
      this.imgX = 0; // 图片在画布中渲染的起点x坐标
      this.imgY = 0;
      this.imgScale = 1; // 图片的缩放大小
      this.oldImgScale = 0; //记录原始图片缩放大小
      this.oldImgX = 0; // 图片在画布中渲染的起点x坐标
      this.oldImgY = 0;
      this.extraImgList = <any>[]; //图片列表
      this.ocrTextResult = "";
      this.currentImageBase64 = "";
    },
  },
});
