export class DateformatUtil {
  //时间戳转换刚刚、几分钟前、几周前
  formatDate = (timestamp: number) => {
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const now = new Date().getTime();
    const diffValue = now - timestamp;
    // 如果本地时间反而小于变量时间
    if (diffValue < 0) {
      return "不久前";
    }
    // 计算差异时间的量级
    const monthC = diffValue / month;
    const weekC = diffValue / (7 * day);
    const dayC = diffValue / day;
    const hourC = diffValue / hour;
    const minC = diffValue / minute;
    // 使用
    if (monthC > 4) {
      // 超过1年，直接显示年月日
      return (function () {
        const date = new Date(timestamp);
        const month = date.getMonth() + 1;
        return `${date.getFullYear()}年${month}月${date.getDate()}日`;
      })();
    } else if (monthC >= 1) {
      return Math.ceil(monthC) + "月前";
    } else if (weekC >= 1) {
      return Math.ceil(weekC) + "周前";
    } else if (dayC >= 1) {
      return Math.ceil(dayC) + "天前";
    } else if (hourC >= 1) {
      return Math.ceil(hourC) + "小时前";
    } else if (minC >= 1) {
      return Math.ceil(minC) + "分钟前";
    }
    return "刚刚";
  };
}
