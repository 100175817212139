export class Resource {
    id?: number;
    createdAt?: bigint;
    updatedAt?: bigint;
    type?: string;
    after?: DoubleRange;
    amount?: DoubleRange;
    before?: DoubleRange;
    reason?: string;
    resourceTypeId?: number;

  }
  