import axios from "axios";
import { User } from "../entity/User";
import { BaseService } from "./BaseService";
import { FilePolicyRequest } from "../entity/FilePolicyRequest";
import { TicketService } from "./TicketService";
import { TokenResponse } from "../entity/TokenResponse";
import { DateformatUtil } from "../util/DateformatUtil";
import log from "./log";
export class TablePumpService extends BaseService<User> {
  protected getUrl(): string {
    return "open-api/table-pump";
  }
  //查询所有文件列表
  getFileList(data: any, callback: (data: any) => void) {
    this.get("open-api/table-pump", { ...data }).then((resp) => {
      try{
      const dateformatUtil = new DateformatUtil();
      const list = resp.data.content;
      for (let i = 0; i < list.length; i++) {
        // if (list[i].ocrStatus != -1) {
        //   if (list[i].comment) {
        //     list[i]["title"] = `${list[i].comment.substring(0, 1) + "..."}
        //     ${list[i].ocrStatus === 1 ? "识别成功" : "正在识别"}`;
        //   } else {
        //     list[i]["title"] = `未命名文件
        //   ${list[i].ocrStatus === 1 ? "识别成功" : "正在识别"}`;
        //   }
        // } else {
        //   if (list[i].comment) {
        //     list[i]["title"] = `${list[i].comment.substring(0, 5)} 识别失败`;
        //   } else {
        //     list[i]["title"] = `未命名文件 识别失败`;
        //   }
        // }
        if (list[i].ocrStatus != -1) {
          if (list[i].memo) {
            list[i]["title"] = `${
              list[i].memo.length > 3
                ? list[i].memo.substring(0, 3) + "..."
                : list[i].memo
            }`;
          } else {
            list[i]["title"] = `未命名文件`;
          }
        } else {
          if (list[i].memo) {
            list[i]["title"] = `${
              list[i].memo.length > 3
                ? list[i].memo.substring(0, 3) + "..."
                : list[i].memo
            }`;
          } else {
            list[i]["title"] = `未命名文件`;
          }
        }
        if (list[i].children != null) {
          for (let j = 0; j < list[i].children.length; j++) {
            list[i].children[j].title = list[i].children[j].memo;
            list[i].children[j].key = list[i].children[j].id;
            delete list[i].children[j].memo;
            delete list[i].children[j].id;
          }
        }
        list[i]["key"] = list[i].id;
        list[i].createdAt = dateformatUtil.formatDate(
          parseInt(list[i].createdAt || "0")
        );
      }
      callback(list);
    }catch(error){
      log.error({
        p: "t",
        a: "e",
        o: "getFileList"
      });
    }
    });
  }
  getAliyunOSSParameter(filePolicyRequest: FilePolicyRequest) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `${baseUrl}aliyun/oss/uploadPolicy`,
      method: "POST",
      // responseType: "blob",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        fileType: filePolicyRequest.fileType,
        noTraceMode: filePolicyRequest.noTraceMode,
        md5: filePolicyRequest.md5,
        size: filePolicyRequest.size,
      },
    })
      .then((response) => {
        log.info({
          p: "t",
          a: "r",
          o: "getAliyunOSSParameter"
        });
        filePolicyRequest.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "t",
            a: "e",
            o: "getAliyunOSSParameter"
          });
          filePolicyRequest.onGetFilePolicyError(err.response.data);
        }
      });
  }
  uploadFile(
    filePolicyRequest: FilePolicyRequest,
    fileInfo: any,
    token: TokenResponse,
    templateId: any[]
  ) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();

    axios({
      url: `${baseUrl}open-api/table-pump?token=${token.token}`,
      method: "POST",
      // responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        memo: filePolicyRequest.memo,
        fileType: filePolicyRequest.fileType,
        noTraceMode: filePolicyRequest.noTraceMode,
        md5: filePolicyRequest.md5,
        size: filePolicyRequest.size,
        file: fileInfo.file,
        templateId: templateId.length > 0 ? templateId[0] : null,
      },
    })
      .then((response) => {
        log.info({
          p: "t",
          a: "r",
          o: "uploadFile"
        });
        filePolicyRequest.onSuccess(response.data);
        let result = response.data.result;
        result = JSON.parse(result);
        window.open(
          `${baseUrl}open-api/table-pump/download?token=${token.token}&filepath=${result.outputFile.filename}`
        );
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "t",
            a: "e",
            o: "uploadFile"
          });
          filePolicyRequest.onGetFilePolicyError(err.response.data);
        }
      });
  }
}
