import { defineComponent } from "vue";
import  log  from "../../service/log";
import { FileProtectOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    FileProtectOutlined,
  },
  data() {
    return {
      startTime: Date.now(),
      activeKey: "1", // 初始化默认的激活标签页
      collactiveKey: "1",
      data: [
        {
          id: 1,
          message: "【产品动态】关于小程序商品、红包、搜索直达更新的通知",
          time: "2023-07-20 10:16",
        },
        {
          id: 2,
          message: "【产品动态】关于小程序商品、红包、搜索直达更新的通知",
          time: "2023-07-20 10:16",
        },
        {
          id: 3,
          message: "【产品动态】关于小程序商品、红包、搜索直达更新的通知",
          time: "2023-07-20 10:16",
        },
        {
          id: 4,
          message: "【产品动态】关于小程序商品、红包、搜索直达更新的通知",
          time: "2023-07-20 10:16",
        },
      ],
    };
  },
  mounted() {
    log.info({
      p: "u",
      a: "m",
    });
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "u",
      a: "u",
      d: stayTime,
    });
  },
  name: "UsbKeyView",
});
