import Antd from "ant-design-vue";
import 'ant-design-vue/dist/reset.css';
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import Vant from "vant";
import "vant/lib/index.css";
// 引入阿里巴巴矢量图标的CSS文件
import "./assets/icon/iconfont.css";

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import { createI18n } from 'vue-i18n';
import { zhLocale, enLocale } from './locales';

const i18n = createI18n({
  legacy: false, // 使用Composition API
  locale: 'zh', // 默认语言
  fallbackLocale: 'en', // 未找到对应语言时使用的默认语言
  messages: {
    zh: zhLocale,
    en: enLocale
  }
}) as any;



console.log("starting filequan web app, env: " + process.env.NODE_ENV);
const app = createApp(App)
  .use(Antd)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(Vant)
  .use(VXETable)
  .use(i18n)
;
app.directive("highlight", function (el) {
  const blocks = el.querySelectorAll("pre code");
  blocks.forEach((block: any) => {
    hljs.highlightBlock(block);
  });
});
app.mount("#app");
