import { defineComponent } from "vue";
import log from "./../../service/log";
import { LeftOutlined } from "@ant-design/icons-vue";
import type { FormInstance } from "ant-design-vue";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import { ApplyFormService } from "./../../service/ApplyFormService";
import { ApplyForm } from "./../../entity/ApplyForm";
import { SmsCodeService } from "@/service/SmsCodeService";
import { UserService } from "@/service/UserService";
import { TicketService } from "@/service/TicketService";
export default defineComponent({
  components: {
    LeftOutlined,
  },
  data() {
    return {
      visible: false,
      startTime: Date.now(),
      modalVisible: true,
      SendmodalVisible: false,
      formData: {
        textareaField: "",
      },
      formRules: {
        textareaField: [
          { required: true, message: "请输入验证消息", trigger: "blur" },
          // 其他验证规则...
        ],
      },
      loginRules: {
        phone: [
          { required: true, message: "", trigger: "blur" },
          { validator: this.checkPhone, trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          // 其他验证规则...
        ],
      },
      form: {
        phone: "",
        code: "",
      },
      countDown: 0,
      isPhoneValid: false,
      isFirstIntercept: true, // 是否第一次拦截
      inviteUserName: "", //邀请人姓名
      groupAccountName: "", //集团名称
      isSendCode: false, //是否发送成功验证码
      data: new ApplyForm(),
    };
  },
  mounted() {
    log.info({
      p: "a",
      a: "m",
    });
    this.getinvite();
    this.showModal(); // 在组件挂载后显示模态框
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "a",
      a: "u",
      d: stayTime,
    });
  },
  name: "ApplyFormView",
  computed: {
    inviteCode(): string {
      const inviteCode = String(this.$route.params.inviteCode);
      return inviteCode;
    },
  },
  methods: {
    getinvite: function () {
      const service: ApplyFormService = new ApplyFormService(this.$router);
      service.getinvite({
        inviteCode: this.inviteCode,
        onSuccess: (resp) => {
          this.inviteUserName = resp.inviteUserName;
          this.groupAccountName = resp.groupAccountName;
          this.data = resp;
        },
        onError: () => {
          Modal.error({
            title: "提示",
            content: "二维码或者链接已过期",
            okText: "确认",
            onOk: () => {
              this.$router.push("/");
            },
          });
        },
      });
    },
    sendinvite: function () {
      const service: ApplyFormService = new ApplyFormService(this.$router);
      service.sendinvite({
        inviteCode: this.inviteCode,
        verifyMessage: this.formData.textareaField,
        inviteUserId: this.data.inviteUserId,
        groupAccountId: this.data.groupAccountId,
        onSuccess: (resp) => {
          this.inviteUserName = resp.inviteUserName;
          this.groupAccountName = resp.groupAccountName;
          Modal.success({
            title: "提示",
            content: "发送成功",
            okText: "确定",
            onOk: () => {
              this.$router.push("/");
            },
          });
        },
        onError: () => {
          message.error("发送失败");
        },
      });
    },
    sendcode: function () {
      const service: SmsCodeService = new SmsCodeService(this.$router);
      service
        .send(this.form.phone)
        .then(() => {
          message.success("验证码已发送，请注意查收");

          this.countDown = 60; // 设置倒计时初始值为60秒

          // 启动计时器，每秒减少1秒
          const timer = setInterval(() => {
            if (this.countDown > 0) {
              this.countDown--;
            } else {
              clearInterval(timer); // 计时结束时清除计时器
            }
          }, 1000);
        })
        .catch(() => {
          this.isSendCode = false;
        });
    },
    login: function () {
      const service: UserService = new UserService(this.$router);
      service.login({
        phoneNumber: this.form.phone,
        verifyCode: this.form.code,
        onSuccess: (resp) => {
          new TicketService().set(resp.ticket);
          new UserService(this.$router).setUserStatus("1");
          this.sendinvite();
        },
        onVerifyCodeError() {
          message.error("验证码错误");
        },
      });
    },
    //捕获邀请链接参数
    showModal() {
      log.info({
        p: "a",
        a: "c",
        o: "showModalbtnClick",
      });
      this.modalVisible = true; // 打开模态框
    },
    handleCancel() {
      log.info({
        p: "a",
        a: "c",
        o: "handleCancelbtnClick",
      });
      // 返回上一页面的逻辑
      this.$router.push("/"); // 使用Vue Router返回上一页面
    },
    handleOk() {
      log.info({
        p: "a",
        a: "c",
        o: "handleOkbtnClick",
      });
      (this.$refs.form as FormInstance)
        .validate()
        .then(() => {
          // 表单验证通过
          // 执行确认按钮的逻辑
          // ...
          this.modalVisible = false;
          this.SendmodalVisible = true;
        })
        .catch(() => {
          // 表单验证不通过
          // 处理错误消息或其他逻辑
          // ...
        });
    },
    handleGo() {
      log.info({
        p: "a",
        a: "c",
        o: "handleGobtnClick",
      });
      this.SendmodalVisible = false;
      this.modalVisible = true;
    },
    checkPhone(value: string) {
      if (value && this.form.phone.length > 0) {
        // 修改校验规则，要求手机号必须是11位数字，且以1开头
        const pattern = /^1[3456789]\d{9}$/;
        if (value && pattern.test(this.form.phone)) {
          this.isPhoneValid = true;
          return Promise.resolve("成功");
        } else {
          this.isPhoneValid = false;
          return Promise.reject("手机号格式不正确");
        }
      } else {
        return Promise.reject("请输入手机号");
      }
    },
    handleInput(event: { target: { value: any } }) {
      const phone = event.target.value;
      this.checkPhone(phone);
    },
    sendCode() {
      log.info({
        p: "a",
        a: "c",
        o: "sendCodebtnClick",
      });
      // 发送验证码逻辑
      const phone = this.form.phone;
      if (phone && this.isPhoneValid == true) {
        this.sendcode();
      }
    },
    handleSubmit() {
      log.info({
        p: "a",
        a: "c",
        o: "handleSubmitbtnClick",
      });
      (this.$refs.loginForm as FormInstance)
        .validate()
        .then(() => {
          this.login();
        })
        .catch(() => {
          // 表单验证不通过
          // 处理错误消息或其他逻辑
          // ...
        });
    },
    handleExit() {
      log.info({
        p: "a",
        a: "c",
        o: "handleExitbtnClick",
      });
      if (this.isFirstIntercept) {
        this.isFirstIntercept = false;
        this.modalVisible = true;
        this.visible = true;
        // Modal.confirm({
        //   title: "提示",
        //   content: "文档圈强大功能，现在去体验?",
        //   okText: "现在就去",
        //   cancelText: "残忍拒绝",
        //   onOk: () => {
        //     this.$router.push("/");
        //   },
        // });
      } else {
        this.modalVisible = false;
      }
    },
    crjj() {
      log.info({
        p: "a",
        a: "c",
        o: "crjjbtnClick",
      });
      this.visible = false;
    },
    xzjq() {
      this.$router.push("/");
    },
  },
});
