import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-601b6b9d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { style: {"width":"80%","margin-left":"200px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout, { style: {"padding":"24px 0","background":"#fff"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_layout_sider, {
                width: "200",
                style: {"background":"#fff"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu, {
                    mode: "inline",
                    selectedKeys: [_ctx.$route.path],
                    style: {"height":"100%"},
                    class: "account-menu"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_menu_item, {
                        class: "Accountmenu-item",
                        key: "/account/user",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleMenuSelect('/account/user')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('setting')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, {
                        class: "Accountmenu-item",
                        key: "/account/purchase",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleMenuSelect('/account/purchase')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('purchase')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, {
                        class: "Accountmenu-item",
                        key: "/account/bill",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleMenuSelect('/account/bill')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('bill')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, {
                        class: "Accountmenu-item",
                        key: "/account/orders",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleMenuSelect('/account/orders')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('orders')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, {
                        class: "Accountmenu-item",
                        key: "/account/mission",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleMenuSelect('/account/mission')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('mission')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, {
                        class: "Accountmenu-item",
                        key: "/account/sign",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleMenuSelect('/account/sign')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('sign')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, {
                        class: "Accountmenu-item",
                        key: "/account/invite",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleMenuSelect('/account/invite')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('invite')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["selectedKeys"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_layout_content, {
                class: "main-content",
                style: { height: '700px' }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_router_view)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}