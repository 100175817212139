import { defineComponent } from "vue";
import HeaderComponent from "@/components/header/HeaderComponent.vue"; // @ is an alias to /src
import { groupUserSrevice } from "./../../service/groupUserSrevice";
import { groupAccountSrevice } from "./../../service/groupAccountSrevice";
import { groupUser } from "./../../entity/groupUser";
import { message } from "ant-design-vue";
import { Modal } from "ant-design-vue";
import type { FormInstance } from "ant-design-vue";
import { MinusCircleFilled, RightOutlined } from "@ant-design/icons-vue";
import { copy } from "clipboard";
import log from "../../service/log";
export default defineComponent({
  name: "AboutView",
  components: {
    HeaderComponent,
    MinusCircleFilled,
    RightOutlined,
  },
  data() {
    return {
      startTime: Date.now(),
      userPhoneNumber: "",
      dialogTitle: "添加用户",
      selectedOption: "全部",
      options: [
        { value: "全部", label: "全部" },
        { value: "userName", label: "姓名" },
        { value: "userPhoneNumber", label: "手机号码" },
        { value: "userRole", label: "用户权限" },
        { value: "joiningMethod", label: "进入集团方式" },
        { value: "inviteUserName", label: "邀请人姓名" },
        { value: "approveUserName", label: "审批人姓名" },
        { value: "joiningAt", label: "进入集团时间" },
      ],
      inputValue: "",
      selectedRowKeys: [],
      dataList: <any>[],
      selectedRow: null,
      dataSource: [],
      defaultActiveFirstOption: true,
      disabled: false,
      userNameOptions: [],
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 14 },
      },
      visible: false, // 控制表单的显示与隐藏
      formState: <groupUser>new groupUser(),
      delId: <any>[],
      rules: {
        userPhoneNumber: [
          {
            validator: this.validatePhoneNumber,
            trigger: "blur",
          },
        ],
        usersole: [
          { required: true, message: "请输入用户权限", trigger: "blur" },
        ],
      },
      userRoleOptions: [
        { value: "普通员工", label: "普通员工" },
        { value: "管理员", label: "管理员" },
      ],
      groupInviteLink: false,
      url: "",
      qrCode: "",
      paginationOptions: {
        current: 1, // 当前页码
        pageSize: 10, // 每页显示数量
        total: 0, // 总记录数
        showSizeChanger: true, // 是否显示每页显示数量切换器
        pageSizeOptions: ["10", "30", "50"], // 每页显示数量选项
        onChange: this.handlePageChange, // 页码改变时的回调函数
        onShowSizeChange: this.handlePageSizeChange, // 每页显示数量改变时的回调函数
      },
    };
  },
  computed: {
    groupId(): number {
      const id = Number(this.$route.params.groupAccountId);
      return id;
    },
  },
  mounted() {
    log.info({
      p: "g",
      a: "m",
    });
    this.getGroupUser();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "g",
      a: "u",
      d: stayTime,
    });
  },
  methods: {
    handlePageChange(page: number) {
      // 处理页码改变事件
      this.paginationOptions.current = page;
      this.getGroupUser();
    },
    handlePageSizeChange(_: any, pageSize: number) {
      // 处理每页显示数量改变事件
      this.paginationOptions.pageSize = pageSize;
      this.paginationOptions.current = 1;
      this.getGroupUser();
    },
    //查询是否有管理员
    getPmp: function () {
      const service: groupUserSrevice = new groupUserSrevice(this.$router);
      service.getGroupUser({
        groupAccountId: this.groupId,
        type: "pmp",
        onSuccess: (resp) => {
          if (resp.state == true) {
            this.disabled = true;
            this.formState.userRole = "普通员工";
          } else {
            this.disabled = false;
          }
        },
        onError() {
          console.log("管理员验证失败");
        },
      });
    },
    getGroupUser: function () {
      const service: groupUserSrevice = new groupUserSrevice(this.$router);
      service.getGroupUser({
        groupAccountId: this.groupId,
        pageNo: this.paginationOptions.current,
        pageSize: this.paginationOptions.pageSize,
        searchType: this.selectedOption,
        searchContext: this.inputValue,
        onSuccess: (resp) => {
          resp.content.forEach(function (obj: { joiningAt: string }) {
            const timestamp = obj.joiningAt; // 获取时间戳
            const date = new Date(timestamp); // 创建新的Date对象

            // 获取年月日的值
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            // 将年月日重新组合成所需的日期格式，例如：2023-07-03
            const formattedDate =
              year + "-" + month + "-" + day + " " + hours + ":" + minutes;

            // 更新对象的时间属性值
            obj.joiningAt = formattedDate;
          });
          this.dataList = resp.content;
        },
        onError() {
          console.log("列表获取失败");
        },
      });
    },
    AddGroupUser: function () {
      const service: groupUserSrevice = new groupUserSrevice(this.$router);
      service.AddGroupUser({
        groupAccountId: this.groupId,
        userPhoneNumber: this.userPhoneNumber,
        userRole: this.formState.userRole,
        onSuccess: () => {
          message.success("集团成员添加成功");
          this.getGroupUser();
          this.resetForm();
          this.visible = false;
        },
        onError() {
          message.error("集团成员添加失败");
        },
      });
    },
    DelGroupUser: function (id: number) {
      const service: groupUserSrevice = new groupUserSrevice(this.$router);
      service.DelGroupUser({
        id: id,
        onSuccess: (resp: any) => {
          if (resp.state == "true") {
            message.success("集团成员删除成功");
            this.getGroupUser();
          }
        },
        onError() {
          message.error("集团成员除失败 ");
        },
      });
    },
    GroupInviteLink: function () {
      const service: groupUserSrevice = new groupUserSrevice(this.$router);
      service.groupInviteLink({
        id: this.groupId,
        onSuccess: (resp) => {
          this.url = resp.url;
          this.qrCode = resp.qrCode;
        },
        onError() {
          message.error("获取失败 ");
        },
      });
    },
    validatePhoneNumber(rule: any, value: string) {
      if (value) {
        const purePhoneNumber = value.replace(/\(.*?\)/g, "");
        console.log(purePhoneNumber);
        this.userPhoneNumber = purePhoneNumber;
        // 修改校验规则，要求手机号必须是11位数字，且以1开头
        const pattern = /^1[3456789]\d{9}$/;
        if (value && pattern.test(purePhoneNumber)) {
          return this.checkPhoneNumber(rule, value);
        } else {
          return Promise.reject("手机号不正确");
        }
      } else {
        return Promise.reject("请输入手机号");
      }
    },
    //查询员工列表
    getphoneNumber: function () {
      const service: groupAccountSrevice = new groupAccountSrevice(
        this.$router
      );
      service.getGroupAccount({
        type: "user",
        phoneNumber: this.formState.userPhoneNumber,
        onSuccess: (resp) => {
          this.userNameOptions = resp.content.map(
            (item: { userName: string; phoneNumber: string }) => ({
              value: item.userName
                ? "(" + item.userName + ")" + item.phoneNumber
                : item.phoneNumber,
            })
          );
        },
        onError() {
          console.log("列表获取失败");
        },
      });
    },
    checkPhoneNumber(rule: any, value: string) {
      const purePhoneNumber = value.replace(/\(.*?\)/g, "");
      // 修改校验规则，要求手机号必须是11位数字，且以1开头
      const service = new groupAccountSrevice(this.$router);
      return new Promise<void>((resolve, reject) => {
        service.repeatCheck({
          type: "phoneNumber",
          repeatCheck: purePhoneNumber,
          onSuccess: (resp) => {
            if (resp.state === true) {
              reject("该用户存在本集团或者其他集团");
              return;
            }
            if (resp.exist === true) {
              resolve();
            } else {
              message.success(
                "该手机号码未注册文档圈，注册后自动成为本企业员工"
              );
              resolve();
            }
          },
          onError() {
            resolve();
          },
        });
      });
    },
    handleItemClick(item: any) {
      log.info({
        p: "g",
        a: "c",
        o: "handleItemBtnClick"
      });
      this.selectedRow = item;
      this.formState = item;
    },
    del(item: any) {
      log.info({
        p: "g",
        a: "c",
        o: "delBtnClick"
      });
      Modal.confirm({
        title: "提示",
        content: `确认删除${
          item.userName
            ? "(" + item.userName + ")" + item.userPhoneNumber
            : item.userPhoneNumber
        }吗`,
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          // 在确认按钮点击后执行的逻辑
          this.DelGroupUser(item.id);
          // 执行其他操作...
        },
      });
    },
    handleSearch() {
      log.info({
        p: "g",
        a: "c",
        o: "SearchBtnClick"
      });
      this.getGroupUser();
    },
    showForm() {
      log.info({
        p: "g",
        a: "c",
        o: "showFormBtnClick"
      });
      this.formState = new groupUser();
      this.dialogTitle = "添加用户";
      this.getPmp();
      this.visible = true; // 显示表单
    },
    handChange() {
      this.getphoneNumber();
    },
    refreshList() {
      this.getGroupUser();
    },
    resetForm() {
      (this.$refs.formRef as FormInstance).resetFields();
      this.visible = false;
    },
    handleFinish() {
      if (this.formState.id == undefined) {
        this.AddGroupUser();
      } else {
        //this.PutGroupUser();
      }
    },
    showInviteLink() {
      log.info({
        p: "g",
        a: "c",
        o: "showInviteLinkBtnClick"
      });
      this.GroupInviteLink();
      this.groupInviteLink = true;
    },
    handleOk() {
      const link = document.createElement("a");
      link.href = this.qrCode;

      // 设置下载文件名
      link.download = "邀请二维码.png";

      // 触发点击事件以下载图片
      link.click();
    },
    copyText() {
      const textElement = this.$refs.text as any;
      // 获取文字内容
      const text = textElement.innerText;
      // 复制文字到剪贴板
      copy(text);
      // 可选：给出复制成功的提示
      message.success("复制成功");
    },
    selectList(item: any) {
      this.formState = item;
    },
  },
});
