/* eslint-disable @typescript-eslint/no-this-alias */
import { defineComponent } from "vue";
import {
  ArrowUpOutlined,
  InboxOutlined,
  PlusOutlined,
  MinusOutlined,
  DownloadOutlined,
  CompressOutlined,
  UploadOutlined,
  CloudUploadOutlined,
  LeftOutlined,
  RightOutlined,
  CheckOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import { OcrFileService } from "../../service/OcrFileService";
import { OcrFile } from "../../entity/OcrFile";
import { TablePumpTemplate } from "../../entity/TablePumpTemplate";
import { AliyunOSS } from "@/entity/AliyunOSS";
import { message } from "ant-design-vue";
import log from "../../service/log";
import { TablePumpService } from "@/service/TablePumpService";
import { TablePumpTemplateService } from "@/service/TablePumpTemplateService";

import { TokenService } from "@/service/TokenService";
import { TokenResponse } from "../../entity/TokenResponse";

export default defineComponent({
  data() {
    return {
      isShow: false,
      fileid: 0,
      ids: <any>[],
      timer: null as any,
      startTime: Date.now(),
      list: Array<OcrFile>(), //文档列表
      currentFileItem: <any>{}, //当前选中的文档条目信息
      currentImageBase64: "", //当前选中图片的base64
      tracelessChecked: true, //无痕模式开关
      selectedKeys: ["0-0-0"],
      activeKey: "xsjg",
      fileList: [],
      noTraceModeStatus: false, //是否开启无痕模式
      token: <TokenResponse>{},
      visible: false, // 模板提示框
      info: <any>{}, //文件信息
      template: "", //模板地址
      templateColumns: [
        {
          title: "序号",
          dataIndex: "num",
          customRender: (obj: any) => {
            return `${obj.index + 1}`;
          },
        },
        {
          title: "模板名称",
          dataIndex: "memo",
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "140px",
        },
      ] as any,
      templateData: <any>[], //模板列表
      templateTotalPages: 0,
      templatePageSize: 10,
      templatePageNo: 1,
      templateTotalCount: 0,
      editableData: <any>[], //模板修改行数据
      templateRowSelection: [], //模板列表复选框
      templateFileList: [], //模板上传列表
      templateCuttentItem: <any>{}, //当前选中模板行对象
    };
  },
  components: {
    ArrowUpOutlined,
    InboxOutlined,
    PlusOutlined,
    MinusOutlined,
    DownloadOutlined,
    CompressOutlined,
    UploadOutlined,
    CloudUploadOutlined,
    LeftOutlined,
    RightOutlined,
    CheckOutlined,
    EditOutlined,
    DeleteOutlined,
  },
  
  name: "HomeView",
  mounted() {
    log.info({
      p: "m",
      a: "m",
    });

    //禁用默认拖拽事件
    this.disableDefaultDragEvents();
    //注册全局拖拽事件
    this.dragListener();
    const service = new TokenService(this.$router);
    const self = this;
    service.list((data) => {
      if (data.length > 0) {
        self.token = data[0];
        //获取文档列表
        this.getFileList();
        //获取模板列表
        self.getTemplateList();
      } else {
        // alert("请先获取Access token！");
        self.isShow = true;
        // self.$router.push({
        //   path: "/open-api",
        // });
      }
    });
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "m",
      a: "u",
      d: stayTime,
    });
  },
  computed: {
    getLocalizedColumns(): any {
      return this.templateColumns.map((column: { dataIndex: any; }) => {
        return {
          ...column,
          title: this.$t(`tableColumns.${column.dataIndex}`) // 使用 $t 方法获取国际化的列名
        };
      });
    }
  },
  methods: {
    handleTrue() {
      this.isShow = false;
      this.$router.push({
        path: "/open-api",
      });
    },
    handleFalse() {
      this.isShow = false;
      this.$router.push({
        path: "/",
      });
    },
    //获取当前用户的所有文件列表
    getFileList() {
      const service: TablePumpService = new TablePumpService(this.$router);
      const obj = {
        pageNo: 1,
        pageSize: 50,
        noTraceModeStatus: this.noTraceModeStatus,
        token: this.token.token,
      };
      service.getFileList(obj, (data) => {
        this.list = data;
      });
    },
    //根据id查询单条目文档信息
    getFileById(id: number | undefined) {
      if (id) {
        const service: OcrFileService = new OcrFileService(this.$router);
        service.getFileById(id, (data) => {
          this.currentFileItem = data;
          console.log("点击左侧树形结构");
          //根据id查询单条目图片信息
          this.getImageById(id);
        });
      } else {
        this.currentFileItem = {};
      }
    },
    //根据id查询单条目图片信息
    getImageById(id: number) {
      if (id) {
        const service: OcrFileService = new OcrFileService(this.$router);
        service.getImageById(this.currentFileItem, (data) => {
          this.currentImageBase64 = data;
        });
      } else {
        this.currentImageBase64 = "";
      }
    },
    //点击左侧图片树，切换图片信息
    treeHandleClick(e: number[]) {
      log.info({
        p: "m",
        a: "c",
        o: "treeHandleBtnClick"
      });
      this.getFileById(e[0]);
      //this.initData();
    },
    //切换标签触发事件
    tabHandleClick() {
      log.info({
        p: "m",
        a: "c",
        o: "tabHandleBtnClick"
      });
    },
    //下载文件
    downloadFile(type: string) {
      log.info({
        p: "m",
        a: "c",
        o: "downloadFileBtnClick"
      });
      alert("开始下载excel文件" + this.currentFileItem.id);
      if (type && this.currentFileItem.id) {
        let aliyunOSS = new AliyunOSS();
        const service: OcrFileService = new OcrFileService(this.$router);
        //获取阿里云OSS链接参数。
        service.getAliyunOSSPolicy((data) => {
          aliyunOSS = data;
        });
        const obj = {
          data: this.currentFileItem,
          type,
          aliyunOSS,
        };
        service.downloadFileById(obj, (data) => {
          const newData = Date.parse(new Date().toDateString()); // 获取当前时间，也是 毫秒级别的
          const element = document.createElement("a");
          element.setAttribute("href", data);
          element.setAttribute("download", "filequan-ocr_" + newData);
          element.style.display = "none";
          element.click();
        });
      } else {
        this.currentImageBase64 = "";
      }
    },
    //无痕模式切换
    updateNoTraceMode(e: any) {
      log.info({
        p: "m",
        a: "c",
        o: "updateNoTraceModeBtnClick"
      });
      this.noTraceModeStatus = e;
      if (e) {
        sessionStorage.setItem("isTracelessMode", "true");
        message.success("已切换至无痕模式，可安心查阅!");
      } else {
        sessionStorage.setItem("isTracelessMode", "false");
        message.success("已切换至普通模式!");
      }
    },
    handleDrop: (e: DragEvent) => {
      console.log("拖拽" + e);
    },
    //自定义上传文件
    customUpload: function (info: any) {
      log.info({
        p: "m",
        a: "c",
        o: "customUploadBtnClick"
      });
      const fileSuffix = info.file.name.split(".");
      let newFileSuffix = "";
      if (fileSuffix.length > 0) {
        newFileSuffix = info.file.name.split(".")[fileSuffix.length - 1];
        if (newFileSuffix) {
          newFileSuffix = newFileSuffix.toLowerCase();
        }
      }
      this.uploadFile(info);
    },
    //确认识别模板
    handleOk: function () {
      // if (this.template) {
      this.visible = false;
      this.uploadFile(this.info);
      // } else {
      //   message.error("请键入您的识别模板地址");
      // }
    },
    //取消识别模板
    handleCancel: function () {
      this.visible = false;
      this.fileList = [];
    },
    //启动轮询
    checkStatus() {
      let hasLoadingData = false;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].ocrStatus === 0) {
          hasLoadingData = true;
        }
      }
      if (hasLoadingData) {
        this.timer = setTimeout(() => {
          this.init();
        }, 5000);
      } else {
        clearTimeout(this.timer);
      }
    },
    init() {
      const service: OcrFileService = new OcrFileService(this.$router);
      for (let i = 0; i < this.ids.length; i++) {
        service.getFileById(this.ids[i], (data) => {
          for (let j = 0; i < this.list.length; j++) {
            if (this.list[j].id == this.ids[i]) {
              // 找到了匹配的对象
              this.list[j] = data;
            }
          }
        });
      }
      this.checkStatus();
    },
    //上传文件
    uploadFile: function (info: any) {
      // this.fileList = [];
      const fileSuffix = info.file.name.split(".");
      let newFileSuffix = "";
      if (fileSuffix.length > 0) {
        newFileSuffix = info.file.name.split(".")[fileSuffix.length - 1];
        if (newFileSuffix) {
          newFileSuffix = newFileSuffix.toLowerCase();
        }
      }
      const service: TablePumpService = new TablePumpService(this.$router);
      service.uploadFile(
        {
          memo: info.file.name.substring(0, 20),
          fileType: newFileSuffix,
          noTraceMode: this.noTraceModeStatus + "",
          size: info.file.size,
          onSuccess: (resp) => {
            resp.fileName = resp.fileName + "." + newFileSuffix;
            message.success(`文件'${info.file.name}' 上传成功`);
            this.fileList = [];
            this.getFileList();
          },
          onGetFilePolicyError(err: any) {
            if (err.errorCode === -3) {
              message.error(`暂不不支持上传${newFileSuffix} 格式文件！`);
            } else {
              message.error(`${info.file.name} 文件上传失败！`);
            }
          },
        },
        info,
        this.token,
        this.templateRowSelection
      );
    },
    // 禁用默认拖拽事件
    disableDefaultDragEvents: function () {
      const doc = document.documentElement;
      doc.addEventListener("dragleave", (e) => e.preventDefault()); //拖离
      doc.addEventListener("drop", (e) => e.preventDefault()); //拖后放
      doc.addEventListener("dragenter", (e) => e.preventDefault()); //拖进
      doc.addEventListener("dragover", (e) => e.preventDefault()); //拖来拖去
    },
      //注册全局拖拽事件
    dragListener: function () {
      const that = this;
      const canvas = document.getElementById('tablePump') as any;
      // 阻止浏览器默认行为，使其支持拖放操作
      canvas.addEventListener('dragover', function(e: any) {
        e.preventDefault();
      }.bind(this)); 
      canvas.addEventListener('drop', function(e: any) {
        e.preventDefault();
        console.log(e.dataTransfer.files)
        const file = e.dataTransfer.files;
        if(file){
          that.customUpload(file);
        }
      }.bind(this));
    },
    // 检查文件类型
    checkType: function (file: any) {
      const { type, name } = file;
      const accept = ".doc, .docx, .html,.pdf,xlsx,xls";
      if (accept.length === 0) return true;
      const extension =
        name.indexOf(".") > -1 ? `.${name.split(".").pop()}` : "";
      const baseType = type.replace(/\/.*$/, "");
      return accept
        .split(",")
        .map((type) => type.trim())
        .filter((type) => type)
        .some((acceptedType) => {
          if (/\..+$/.test(acceptedType)) {
            return extension === acceptedType;
          }
          if (/\/\*$/.test(acceptedType)) {
            return baseType === acceptedType.replace(/\/\*$/, "");
          }
          if (/^[^/]+\/[^/]+$/.test(acceptedType)) {
            return type === acceptedType;
          }
        });
    },
    //获取表格模板列表
    getTemplateList() {
      const service: TablePumpTemplateService = new TablePumpTemplateService(
        this.$router
      );
      const obj = {
        pageNo: this.templatePageNo,
        pageSize: this.templatePageSize,
        token: this.token.token,
      };
      service.getTemplateList(obj, (data) => {
        this.templateData = data.templateData;
        this.templateTotalPages = data.templateTotalPages;
        this.templatePageSize = data.templatePageSize;
        this.templatePageNo = parseInt(data.templatePageNo);
        this.templateTotalCount = data.templateTotalCount;
      });
    },
    //删除模板
    onDelete(record: any) {
      const self = this;
      const service: TablePumpTemplateService = new TablePumpTemplateService(
        this.$router
      );
      const obj = {
        ids: [record],
        token: this.token.token,
      };
      service.deleteItem(obj, function () {
        self.getTemplateList();
      });
    },
    //点击修改按钮
    edit(record: any) {
      log.info({
        p: "m",
        a: "c",
        o: "editBtnClick"
      });
      let obj = <TablePumpTemplate>{};
      for (let i = 0; i < this.templateData.length; i++) {
        if (record == this.templateData[i].key) {
          obj = this.templateData[i];
        }
      }
      this.editableData[obj.key] = obj;
    },
    //点击修改保存按钮
    save(record: any) {
      log.info({
        p: "m",
        a: "c",
        o: "saveBtnClick"
      });
      const service: TablePumpTemplateService = new TablePumpTemplateService(
        this.$router
      );
      for (let i = 0; i < this.editableData.length; i++) {
        const obj = this.editableData[i];
        if (obj) {
          if (obj.id == record) {
            obj.token = this.token.token;
            const obj1 = JSON.parse(JSON.stringify(obj));
            //进行数据修改
            service.updateMemo(obj1, function () {
              console.log("saved");
            });
            delete this.editableData[i];
          }
        }
      }
    },
    //模板自定义上传
    templateCustomUpload: function (info: any) {
      const record = this.templateCuttentItem;
      log.info({
        p: "m",
        a: "c",
        o: "templateCustomUploadBtnClick"
      });
      const fileSuffix = info.file.name.split(".");
      let newFileSuffix = "";
      if (fileSuffix.length > 0) {
        newFileSuffix = info.file.name.split(".")[fileSuffix.length - 1];
        if (newFileSuffix) {
          newFileSuffix = newFileSuffix.toLowerCase();
        }
      }
      if (newFileSuffix === "docx" || newFileSuffix === "docx") {
        if (record.id) {
          this.templateReUploadFile(info, record);
        } else {
          this.templateUploadFile(info);
        }
      } else {
        message.error(`暂不不支持上传${newFileSuffix} 格式文件！`);
        this.templateFileList = [];
      }
    },
    //上传模板文件
    templateUploadFile: function (info: any) {
      const self = this;
      const fileSuffix = info.file.name.split(".");
      let newFileSuffix = "";
      if (fileSuffix.length > 0) {
        newFileSuffix = info.file.name.split(".")[fileSuffix.length - 1];
        if (newFileSuffix) {
          newFileSuffix = newFileSuffix.toLowerCase();
        }
      }
      const service: TablePumpTemplateService = new TablePumpTemplateService(
        this.$router
      );
      service.uploadFile(
        {
          memo: info.file.name.substring(0, 20),
          fileType: newFileSuffix,
          noTraceMode: this.noTraceModeStatus + "",
          size: info.file.size,
          onSuccess: (resp) => {
            resp.fileName = resp.fileName + "." + newFileSuffix;
            message.success(`文件'${info.file.name}' 上传成功`);
            this.templateFileList = [];
            self.getTemplateList();
          },
          onGetFilePolicyError(err: any) {
            if (err.errorCode === -3) {
              message.error(`暂不不支持上传${newFileSuffix} 格式文件！`);
            } else {
              message.error(`${info.file.name} 文件上传失败！`);
            }
          },
        },
        info,
        this.token,
        this.template
      );
    },
    //获取当前行对象
    storeTemplateRecord: function (record: any) {
      this.templateCuttentItem = record;
    },
    //替换模板文件
    templateReUploadFile: function (info: any, record: any) {
      const obj1 = JSON.parse(JSON.stringify(record));
      const self = this;
      const fileSuffix = info.file.name.split(".");
      let newFileSuffix = "";
      if (fileSuffix.length > 0) {
        newFileSuffix = info.file.name.split(".")[fileSuffix.length - 1];
        if (newFileSuffix) {
          newFileSuffix = newFileSuffix.toLowerCase();
        }
      }
      const service: TablePumpTemplateService = new TablePumpTemplateService(
        this.$router
      );
      service.templateReUploadFile(
        {
          memo: info.file.name.substring(0, 20),
          fileType: newFileSuffix,
          noTraceMode: this.noTraceModeStatus + "",
          size: info.file.size,
          onSuccess: (resp) => {
            resp.fileName = resp.fileName + "." + newFileSuffix;
            message.success(`文件'${info.file.name}' 替换成功`);
            this.templateFileList = [];
            self.getTemplateList();
            self.templateCuttentItem = {};
          },
          onGetFilePolicyError(err: any) {
            if (err.errorCode === -3) {
              message.error(`暂不不支持上传${newFileSuffix} 格式文件！`);
            } else {
              log.error({
                p: "t",
                a: "e",
              });
              message.error(`${info.file.name} 文件上传失败！`);
            }
          },
        },
        info,
        this.token,
        obj1
      );
    },
    //模板列表也页
    templatePagionationChange: function (current: number, pageSize: number) {
      this.templatePageNo = current;
      this.templatePageSize = pageSize;
      this.getTemplateList();
    },
    //复选模板列表
    templateRowSelectionChange: function (selectedRowKeys: []) {
      if (selectedRowKeys.length > 1) {
        message.warn("现版本表格数据提取时只允许最多选择一个识别模板！");
      } else {
        this.templateRowSelection = selectedRowKeys;
      }
    },
  },
});
