import { defineComponent } from "vue";
import log from "../../service/log";
import { ProfileService } from "./../../service/ProfileService";
import { ResourceService } from "./../../service/ResourceService";
import { OrderService } from "./../../service/OrderService";
import { Resource } from "./../../entity/Resource";
import { message } from "ant-design-vue";
export default defineComponent({
  data() {
    return {
      startTime: Date.now(),
      items: <any>[],
      point: 0,
      price: 0,
      value: 0,
      amount:0,
      goodId: 0,
      payVisible: false,
      aliPayVisible: false,
      orderId: 0,
      qcodeUrl: "",
      backendPage: "",
    };
  },
  mounted() {
    log.info({
      p: "u",
      a: "m",
    });
    this.getProfile();
    this.getProduct();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "u",
      a: "u",
      d: stayTime,
    });
  },
  name: "UserView",
  methods: {
    handleClick(index: number) {
      // 隐藏所有 div 的阴影效果
      this.items.forEach((item: any) => {
        item.showShadow = false;
      });

      // 显示当前点击的 div 的阴影效果
      this.items[index].showShadow = true;
      this.price = this.items[index].price;
      this.amount = this.items[index].amount;
      this.goodId = this.items[index].id;


      // 这里可以添加其他点击 div 后的逻辑处理
    },
    getProduct: function () {
      const service: ResourceService = new ResourceService(this.$router);
      service.getProduct({
        resourceTypeId: 1,
        onSuccess: (resp) => {
          resp.content.forEach((item: any) => {
            // 使用解构赋值和扩展操作符来添加新属性并设置默认值
            Reflect.set(item, "showShadow", false);
          });

          this.items = resp.content;
          console.log(this.items);
        },
        onError() {
          console.log("列表获取失败");
        },
      });
    },
    //创建订单
    createOrder: function (payType: number) {
      const service: OrderService = new OrderService(this.$router);
      service.addOrder({
        orderName: '购买积分',
        orderAmount: this.price,
        orderType: 0,
        orderNo: this.amount,
        naturalId: this.goodId,
        onSuccess: (resp) => {
          if(payType == 0){
            const baseUrl = process.env.VUE_APP_API_URL;
            this.qcodeUrl = `${baseUrl}wechat/pay?orderId=${resp.id}`;
            this.orderId = resp.orderNumber;
            this.payVisible = true;
          }else if(payType == 1){
            this.getAlipay(resp.id);
          }
           
        },
        onError() {
          console.log("订单创建失败");
        },
      });
    },
    successPay(){
        this.findOrder(this.orderId);
    },
    //微信支付接口
    findOrder: function (id: number) {
      const service: OrderService = new OrderService(this.$router);
      service.findOrder({
        orderNumber: id,
        onSuccess: (resp) => {
          console.log(resp);
          this.getProfile();
          this.payVisible = false;
        },
        onError() {
          message.error("先扫码支付")
        },
      });
    },
    //支付宝接口
    getAlipay: function (id: number) {
      const service: OrderService = new OrderService(this.$router);
      service.aliPay({
        orderId: id,
        onSuccess: (resp) => {
          this.backendPage = resp;
          this.aliPayVisible = true;
        },
        onError() {
          console.log("列表获取失败");
        },
      });
    },
    getProfile() {
      const profileService = new ProfileService(this.$router);
      profileService.getOne((profile) => {
        const user = profile as any;
        this.point = user.point;
      });
    },
    //支付
    pay() {
      if(this.price == 0){
        message.warning("请您先选择要购买的积分商品")
        return;
      }
        this.createOrder(this.value);
    },
  },
});
