import { User } from "../entity/User";
import { BaseService } from "./BaseService";
import { OcrFile } from "../entity/OcrFile";
import { DateformatUtil } from "../util/DateformatUtil";
import { TicketService } from "./TicketService";
import { convertPdfRequest } from "../entity/convertPdfRequest";
import axios from "axios";
import log from "./log";
const baseUrl = process.env.VUE_APP_API_URL;
export class ConvertPdfService extends BaseService<User> {
  protected getUrl(): string {
    return "convert/pdf";
  }
  //获取PDF文档列表
  getPdfFileList(data: any, callback: (data: Array<OcrFile>) => void) {
    this.get(this.getUrl() + "/", { ...data }).then((resp) => {
      try {
        log.info({
          p: "d",
          a: "r",
          o: "getPdfFileList"
        });
        const dateformatUtil = new DateformatUtil();
        const list = resp.data.content;
        log.info({
          p: "d",
          a: "d",
          o: "getPdfFileList"
        });
        for (let i = 0; i < list.length; i++) {
          if (list[i].convertStatus != -1) {
            if (list[i].memo) {
              list[i]["title"] = `${
                list[i].memo.length > 5
                  ? list[i].memo.substring(0, 5) + "..."
                  : list[i].memo
              }`;
            } else {
              list[i]["title"] = `未命名文件`;
            }
          } else {
            if (list[i].memo) {
              list[i]["title"] = `${
                list[i].memo.length > 5
                  ? list[i].memo.substring(0, 5) + "..."
                  : list[i].memo
              }`;
            } else {
              list[i]["title"] = `未命名文件`;
            }
          }
          list[i]["key"] = list[i].id;
          list[i].createdAt = dateformatUtil.formatDate(
            parseInt(list[i].createdAt || "0")
          );
        }
        callback(list);
        log.info({
          p: "d",
          a: "dc",
          o: "getPdfFileList"
        });
      } catch (error) {
        // 异常处理逻辑
        log.error({
          p: "d",
          a: "e",
          o: "getPdfFileList"
        });
      }
    });
  }
  //根据PDF文档id去查询对应子文件列表
  getChildList(id: number, callback: (data: OcrFile) => void) {
    this.get(this.getUrl() + `/${id}`, {}).then((resp) => {
      try {
        log.info({
          p: "d",
          a: "r",
          o: "getChildList"
        });
      const dateformatUtil = new DateformatUtil();
      const data = resp.data;
      log.info({
        p: "d",
        a: "d",
        o: "getChildList"
      });
      for (let i = 0; i < data.length; i++) {
        if(data[i].fileType == "jpg" || data[i].fileType == "png" ||data[i].fileType == "jpeg"){
          data[i].imageUrl =
          baseUrl +
          "convert/common/preview/" +
          data[i].id +
          "?type=pdf&ticket=" +
          new TicketService().get();
        }else if(data[i].fileType == "doc" || data[i].fileType == "docx"){
          data[i].imageUrl ="../../assets/pdf/word.png"
        }else if(data[i].fileType == "tif" || data[i].fileType == "tiff"){
          data[i].imageUrl ="../../assets/pdf/tiff.png"
        }else if(data[i].fileType == "xls" || data[i].fileType == "xlsx"){
          data[i].imageUrl ="../../assets/pdf/xlsx.png"
        }else if(data[i].fileType == "pptx" || data[i].fileType == "ppt"){
          data[i].imageUrl ="../../assets/pdf/ppt.png"
        }else if(data[i].fileType == "html"){
          data[i].imageUrl ="../../assets/pdf/html.png"
        }else if(data[i].fileType == "ofd"){
          data[i].imageUrl ="../../assets/pdf/ofd.png"
        }
        data["key"] = data.id;
        
      }
      
      data.createdAt = dateformatUtil.formatDate(
        parseInt(data.createdAt || "0")
      );
      callback(data);
      log.info({
        p: "d",
        a: "dc",
        o: "getChildList"
      });
    } catch (error) {
      // 异常处理逻辑
      log.error({
        p: "d",
        a: "e",
        o: "getChildList"
      });
    }
    });
    
  }
  //移动子文件顺序
  move(convertPdfReq: convertPdfRequest) {
    return this.putFile(
      `convert/pdf/${convertPdfReq.id}`,
      {
        type: "move",
        list: convertPdfReq.list,
      },
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    )
      .then(function (response) {
        log.info({
          p: "d",
          a: "r",
          o: "move"
        });
        convertPdfReq.onSuccess(response.data);
      })
      .catch(function (err) {
        log.error({
          p: "d",
          a: "e",
          o: "move"
        });
        convertPdfReq.onError(err.response);
      });
  }

  repack(convertPdfReq: convertPdfRequest) {
    return this.getPost(`convert/pdf` ,
      {
        type: "repack",
        ids: convertPdfReq.ids?.toString()
      },
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
     
    )
      .then(function (response) {
        log.info({
          p: "d",
          a: "r",
          o: "repack"
        });
        convertPdfReq.onSuccess(response.data);
      })
      .catch(function (err) {
        log.error({
          p: "d",
          a: "e",
          o: "move"
        });
        convertPdfReq.onError(err.response);
      });
  }

  //下载pdf
  downloadPdf(convertPdfReq: convertPdfRequest){
    return this.get(`convert/common/download`, {
      filepath: convertPdfReq.filepath
    })
    .then(function (response) {
      log.info({
        p: "d",
        a: "r",
        o: "downloadPdf"
      });
      convertPdfReq.onSuccess(response.data);
    })
    .catch(function (err) {
      log.error({
        p: "d",
        a: "e",
        o: "downloadPdf"
      });
      convertPdfReq.onError(err);
    });
  }
  //批量下载pdf
  batchDownloadPdf(convertPdfReq: convertPdfRequest){
    return this.getPost(`convert/pdf`, {
      type: convertPdfReq.type,
      ids: convertPdfReq.ids?.toString()
    } ,{
      "Content-Type": "application/x-www-form-urlencoded",
    })
    .then(function (response) {
      log.info({
        p: "d",
        a: "r",
        o: "batchDownloadPdf"
      });
      convertPdfReq.onSuccess(response.data);
    })
    .catch(function (err) {
      log.error({
        p: "d",
        a: "e",
        o: "batchDownloadPdf"
      });
      convertPdfReq.onError(err);
    });
  }
  //
  DownloadPdf(convertPdfReq: convertPdfRequest){
    return this.getPost(`convert/pdf`, {
      type: convertPdfReq.type,
      id: convertPdfReq.id
    }, {
      "Content-Type": "application/x-www-form-urlencoded",
    })
    .then(function (response) {
      log.info({
        p: "d",
        a: "r",
        o: "batchDownloadPdf"
      });
      convertPdfReq.onSuccess(response.data);
    })
    .catch(function (err) {
      log.error({
        p: "d",
        a: "e",
        o: "batchDownloadPdf"
      });
      convertPdfReq.onError(err);
    });
  }
  //修改当前文件的无痕模式状态
  updateNoTraceMode(data: any, callback: (data: any) => void) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `${baseUrl}ocr/files/${data.ids}`,
      method: "PUT",
      // responseType: "blob",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        noTraceMode: data.noTraceMode,
      },
    })
      .then((response) => {
        callback(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          callback(err);
          log.error({
            p: "d",
            a: "e",
          });
        }
      });
  }
  delPdf(convertPdfReq: convertPdfRequest) {
    return this.delete(`convert/pdf/${convertPdfReq.ids}`, {})
      .then(function (response) {
        log.info({
          p: "d",
          a: "r",
          o: "delPdf"
        });
        convertPdfReq.onSuccess(response.data);
      })
      .catch(function (err) {
        log.error({
          p: "d",
          a: "e",
          o: "delPdf"
        });
        convertPdfReq.onError(err);
      });
  }
}
