/* eslint-disable @typescript-eslint/no-this-alias */
import { defineComponent } from "vue";
import {
  FilePdfOutlined,
  CloseOutlined,
  DownOutlined,
  SmileOutlined,
  FrownOutlined,
  FrownFilled,
  CloudUploadOutlined
} from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import log from "../../service/log";
import { ConvertPdfService } from "@/service/ConvertPdfService";
import { TicketService } from "../../service/TicketService";
import { OcrFileService } from "./../../service/OcrFileService";
import axios from "axios";
import draggable from "vuedraggable";
import pdf from "pdfobject";
export default defineComponent({
  data() {
    return {
      replaceFields: {
        title: "memo",
        key: "id"
      },
      ticket: <any>"",
      isShow: false,
      superiorId: <any>0,
      addFileId: <any>0,
      pollCount: <any>0, //轮询次数
      ids: <any>[],
      timer: null as any,
      startTime: Date.now(),
      list: <any>[], //文档列表
      selectedKeys: <any>[],
      selectedItem: <any>[],
      noTraceModeStatus: false, //是否开启无痕模式
      childList: <any>[], //文件(图片）列表
      drag: false,
      uploadStatus: false,
      moveList: <any>[],
      appendStatus: false, //  追加状态
      moveStatus: false, //  移动状态
      delStatus: false, //删除状态
      mergeStatus: false,
      badgeStatus: false,
      mergeid: 0,
      firstStatus: false, //是否第一次下载
      currentImageBase64: "",


      progress: 0, //进度条数值
      timing: <any>null, //加载定时器

      currentFileItem: <any>[],
      ocrCount: 0, //轮询ocr状态次数
      ocrTimer: <any>null,
      ocrModelShow: false,
      ocrTextResult: <any>"",
      ocrResultShow: false,
      devicePixelRatio: window.devicePixelRatio,
      resizeObserver: <any>null
    };
  },
  components: {
    draggable,
    FilePdfOutlined,
    CloseOutlined,
    DownOutlined,
    SmileOutlined,
    FrownOutlined,
    FrownFilled,
    CloudUploadOutlined
  },
  name: "HomeView",
  mounted() {
    log.info({
      p: "d",
      a: "m",
    });
    //获取PDF文档列表
    this.getPdfFileList();
    this.getTicket();
    this.dragListener();
    this.disableDefaultDragEvents();
    window.addEventListener('resize', this.updateScreenWidth);
    //this.loadPdf();
  },
  computed: {
    scaledSize(): any {
      if (this.devicePixelRatio > 1) {
        return 1 / this.devicePixelRatio;
      } else {
        return 1;
      }
    }
  },
  watch:{
    scaledSize(newId: any) {
      return newId;
    },
  },
  unmounted() {
    // 计算页面停留时间
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    window.removeEventListener('resize', this.updateScreenWidth);
    log.info({
      p: "d",
      a: "u",
      d: stayTime,
    });
  },
  methods: {
     //获取动态div宽高
    updateScreenWidth() {
      this.devicePixelRatio = window.devicePixelRatio;
    },
    // 禁用默认拖拽事件
    disableDefaultDragEvents: function () {
      const doc = document.documentElement;
      doc.addEventListener("dragleave", (e) => e.preventDefault()); //拖离
      doc.addEventListener("drop", (e) => e.preventDefault()); //拖后放
      doc.addEventListener("dragenter", (e) => e.preventDefault()); //拖进
      doc.addEventListener("dragover", (e) => e.preventDefault()); //拖来拖去
     },
    //注册全局拖拽事件
    dragListener: function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      const canvas = document.getElementById('pdf-container') as any;
      // 阻止浏览器默认行为，使其支持拖放操作
      canvas.addEventListener('dragover', function(e: any) {
        e.preventDefault();
      }.bind(this));
      canvas.addEventListener('drop', function(e: any) {
        e.preventDefault();
        const file = e.dataTransfer.files;
        if(file){
          that.beforeRead(file);
        }
      }.bind(this));
      
    },
    startTimer() {
      // 使用setInterval函数每秒钟执行一次更新进度的函数
      this.progress = 0;
      this.timing = setTimeout(() => {
        this.updateProgress();
      }, 2000);
    },
    stopTimer() {
      // 停止计时器
      clearInterval(this.timing);
      this.timing = setTimeout(() => {
        this.ocrModelShow = false;
        if(this.currentFileItem.ocrStatus == 1){
          this.ocrResultShow = true;
        }
      }, 2000);
    },
    updateProgress() {
      // 获取当前时间
      const currentTime = new Date().getSeconds();
      // 根据时间判断要增加的进度
      if (currentTime % 2 === 0) {
        this.progress += 5;
      }
      if (currentTime % 3 === 0) {
        this.progress += 7;
      }

      if (this.progress > 88 && this.currentFileItem.ocrStatus == 0) {
        this.progress = 99;
      }else{
        this.progress = 100;
        this.stopTimer();
      }

    },
    loadPdf(id: number) {
      const baseUrl = process.env.VUE_APP_API_URL;
      const container = document.getElementById("pdf-container") as any;
      let url;
      if (id == 0) {
        url = ``;
        this.currentImageBase64 = "";
        message.warning("正在转换pdf中,请您稍等...");
      } else if (id == -1) {
        url = ``;
        this.currentImageBase64 = "";
      } else {
        url = `${baseUrl}convert/common/preview/${id}?type=pdf&ticket=${this.ticket}`;
        this.currentImageBase64 = url;
      }
      const options = {
        width: "100%",
        height: container.offsetHeight + "px", // 或者通过其他方式获取容器的高度
      };
      pdf.embed(url, container, options);
    },
    getTicket() {
      this.ticket = new TicketService().get();
    },
    checkStatus() {
      let hasLoadingData = false;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].convertStatus == 0) {
          hasLoadingData = true;
        }
      }
      if (hasLoadingData) {
        if (this.pollCount < 50) {
          // 检查轮询次数
          this.pollCount++; // 轮询次数加一
          this.timer = setTimeout(() => {
            this.getPdfFileList();
          }, 2000);
        } else {
          clearTimeout(this.timer); // 停止轮询
          if (this.uploadStatus) {
            message.error("转换pdf失败,已超时...");
          }
        }
      } else {
        clearTimeout(this.timer);
        if (this.uploadStatus) {
          message.success("转换pdf成功,请您查看...");
        }
        if (this.appendStatus) {
          this.loadPdf(this.addFileId);
        }
        if (this.moveStatus) {
          this.loadPdf(this.addFileId);
        }
        if (this.delStatus) {
          this.loadPdf(this.addFileId);
        }
        if (this.mergeStatus) {
          this.loadPdf(this.mergeid);
        }
      }
    },
    treeClick(event: any, node: any) {
      log.info({
        p: "d",
        a: "c",
        o: "treeClick",
      });
      if (node.id == this.mergeid) {
        this.badgeStatus = false;
      }
      if (event.ctrlKey) {
        // 检查是否按下了 Ctrl 键
        const nodeIndex = this.selectedKeys.indexOf(node.key);
        if (nodeIndex === -1) {
          this.selectedKeys.push(node.key); // 添加到已选择的键中
          this.selectedItem.push(node);
        } else {
          this.selectedKeys.splice(nodeIndex, 1); // 从已选择的键中移除
          this.selectedItem.splice(nodeIndex, 1);
        }
      } else {
        this.selectedKeys = [node.key]; // 单选时直接替换已选择的键
        console.log("选择",this.selectedKeys)
        this.selectedItem = [node];
        this.addFileId = [node.key];
        this.getFileById(this.selectedKeys);
        if (node.convertStatus == 0) {
          this.loadPdf(0);
          return;
        } else if (node.convertStatus == -1) {
          this.loadPdf(-1);
          return;
        }
        this.loadPdf(this.selectedKeys);
      }
    },
    //获取PDF文档列表
    getPdfFileList() {
      const service: ConvertPdfService = new ConvertPdfService(this.$router);
      const obj = {
        pageNo: 1,
        pageSize: 500,
        noTraceModeStatus: this.noTraceModeStatus,
      };
      service.getPdfFileList(obj, (data) => {
        this.list = data;
        this.checkStatus();
      });
    },
    delPdf(ids: any) {
      const self = this;
      const service: ConvertPdfService = new ConvertPdfService(this.$router);
      service.delPdf({
        ids: ids,
        onSuccess: (resp: any) => {
          if (resp.state == "true") {
            message.success("删除成功");
            this.delStatus = true;
            this.getFileById(this.addFileId);
            this.getPdfFileList();
          }
        },
        onError() {
          self.delStatus = false;
          message.error("删除失败 ");
        },
      });
    },
    batchDownloadPdf(ids: any, type: string) {
      const self = this;
      const baseUrl = process.env.VUE_APP_API_URL;
      const types = type;
      const service: ConvertPdfService = new ConvertPdfService(this.$router);
      service.batchDownloadPdf({
        type: type,
        ids: ids,
        onSuccess: (resp: any) => {
          if (type == "batchDownload") {
            if (resp.state == "true") {
              const path = resp.filepath;
              const a = document.createElement("a");
              a.href = `${baseUrl}convert/common/download?filepath=${path}`;
              //a.download = `${file[0].memo}.pdf`;
              a.click();
              message.success("下载成功");
            } else {
              message.error("您的积分不足!");
            }
          } else {
            self.uploadStatus = true; //上传成功
            self.mergeStatus = true; //合并成功
            self.mergeid = resp.id;
            this.getPdfFileList();
            self.badgeStatus = true;
            message.success("合并成功");
          }
        },
        onError() {
          if (types == "batchDownload") {
            message.error("下载失败 ");
          } else {
            self.uploadStatus = false; //上传成功
            self.mergeStatus = false; //合并成功
            message.error("合并失败 ");
          }
        },
      });
    },

  

    getFileById(id: number | undefined) {
      if (id) {
        this.superiorId = id;
        const service: ConvertPdfService = new ConvertPdfService(this.$router);
        service.getChildList(id, (data) => {
          this.childList = data;
          console.log("字列表",this.childList)
        });
      } else {
        this.childList = [];
      }
    },
    downloadFile() {
      log.info({
        p: "d",
        a: "c",
        o: "downloadFileBtnClick",
      });
      //const baseUrl = process.env.VUE_APP_API_URL;
      if (this.selectedItem.length == 0) {
        message.warning("请选择下载的pdf");
        return;
      } else if (this.selectedItem.length == 1) {
        const file = this.getPath(this.selectedItem);
        this.repack(file);
      } else {
        const file = this.getPath(this.selectedItem);
        const ids = file.map((item: any) => {
          return item.id;
        });
        this.batchRepack(ids);
        // this.batchDownloadPdf(ids, "batchDownload");
      }
    },
    downloadFilePdf: function (id: any, file: any) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const service: ConvertPdfService = new ConvertPdfService(this.$router);
      service.DownloadPdf({
        type: "download",
        id: id,
        onSuccess: (resp) => {
          if (resp.state == "true") {
            const baseUrl = process.env.VUE_APP_API_URL;
            const files = this.getPath(file);
            const filepath =
              files[0].filePath + files[0].fileName + "." + files[0].fileType;
            const link = document.createElement("a") as any;
            link.href = `${baseUrl}convert/common/download?filepath=${filepath}`;
            link.click();
            message.success("下载成功");
          } else {
            message.error("您的积分不足!");
          }
        },
        onError() {
          message.error("下载失败 ");
        },
      });
    },
    getPath(list: any) {
      const file = list.map((item: any) => ({
        id: item.id,
        fileName: item.fileName,
        memo: item.memo,
        filePath: item.filePath,
        fileType: item.fileType,
      }));
      return file;
    },
    //创建pdf任务
    beforeRead(file: any) {
      // 定义不允许上传的文件类型数组
      const disallowedTypes = [
        "doc",
        "docx",
        "xls",
        "xlsx",
        "wps",
        "ppt",
        "pptx",
        "jpg",
        "tif",
        "png",
        "ofd",
        "html",
      ];
      // 存储不允许上传的文件后缀
      const disallowedExtensions = [];
      if (file.length == undefined) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        // 判断文件扩展名是否在不允许上传的文件类型数组中
        if (disallowedTypes.indexOf(fileExtension) == -1) {
          // 文件类型不允许，阻止上传
          message.error(`暂不支持上传${fileExtension}文件格式`);
          return false;
        }
      } else {
        for (let i = 0; i < file.length; i++) {
          const list = file[i];
          // 获取文件的扩展名
          const fileExtension = list.name.split(".").pop().toLowerCase();
          // 判断文件扩展名是否在不允许上传的文件类型数组中
          if (disallowedTypes.indexOf(fileExtension) == -1) {
            // 文件类型不允许，阻止上传
            disallowedExtensions.push(fileExtension);
          }
        }

        // 判断是否有不允许上传的文件后缀
        if (disallowedExtensions.length > 0) {
          // 将不允许上传的文件后缀显示出来
          const disallowedExtensionsString = disallowedExtensions.join(", ");
          message.error(`暂不支持上传${disallowedExtensionsString}文件格式`);
          return false;
        }
      }

      // 判断文件扩展名是否在不允许上传的文件类型数组中
      const loading = message.loading("文件上传中，请稍等", 0); // 第二个参数0表示不自动关闭

      //文件类型允许，继续上传
      //创建 FormData 对象
      const formData = new FormData();
      if (file.length == undefined) {
        formData.append("files", file);
        console.log(11);
      } else {
        for (let i = 0; i < file.length; i++) {
          formData.append("files", file[i]);
        }
      }
      const xhr = new XMLHttpRequest();
      const baseUrl = process.env.VUE_APP_API_URL;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      xhr.open("POST", `${baseUrl}convert/pdf`);
      xhr.setRequestHeader("apiVersion", "0.1.0");
      xhr.setRequestHeader("ticket", this.ticket);
      xhr.onload = function () {
        if (xhr.status === 200) {
          // 上传成功处理代码
          loading();
          message.success("上传成功");
          self.uploadStatus = true;
          self.pollCount = 0;
          self.getPdfFileList();
        } else {
          // 上传失败处理代码
          message.error("上传失败");
          self.uploadStatus = false;
        }
      };
      xhr.send(formData);
    },
    append() {
      if (this.superiorId == 0) {
        message.warning("请选择要追加的pdf文件");
        return;
      }
    },
    Appendupload(file: any) {
      log.info({
        p: "d",
        a: "c",
        o: "appendFileBtnClick",
      });
      // 定义不允许上传的文件类型数组
      const disallowedTypes = [
        "doc",
        "docx",
        "xls",
        "xlsx",
        "wps",
        "ppt",
        "pptx",
        "jpg",
        "tif",
        "png",
        "ofd",
        "html",
      ];
      // 存储不允许上传的文件后缀
      const disallowedExtensions = [];
      if (file.length == undefined) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        // 判断文件扩展名是否在不允许上传的文件类型数组中
        if (disallowedTypes.indexOf(fileExtension) == -1) {
          // 文件类型不允许，阻止上传
          message.error(`暂不支持上传${fileExtension}文件格式`);
          return false;
        }
      } else {
        for (let i = 0; i < file.length; i++) {
          const list = file[i];
          // 获取文件的扩展名
          const fileExtension = list.name.split(".").pop().toLowerCase();
          // 判断文件扩展名是否在不允许上传的文件类型数组中
          if (disallowedTypes.indexOf(fileExtension) == -1) {
            // 文件类型不允许，阻止上传
            disallowedExtensions.push(fileExtension);
          }
        }

        // 判断是否有不允许上传的文件后缀
        if (disallowedExtensions.length > 0) {
          // 将不允许上传的文件后缀显示出来
          const disallowedExtensionsString = disallowedExtensions.join(", ");
          message.error(`暂不支持上传${disallowedExtensionsString}文件格式`);
          return false;
        }
      }
      const loading = message.loading("追加文件上传中，请稍等", 0);
      // 创建 FormData 对象
      const formData = new FormData();
      if (file.length == undefined) {
        formData.append("files", file);
      } else {
        for (let i = 0; i < file.length; i++) {
          formData.append("files", file[i]);
        }
      }

      formData.append("type", "addFiles");
      formData.append("superiorId", this.addFileId);
      const xhr = new XMLHttpRequest();
      const baseUrl = process.env.VUE_APP_API_URL;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      xhr.open("POST", `${baseUrl}convert/pdf`);
      xhr.setRequestHeader("apiVersion", "0.1.0");
      xhr.setRequestHeader("ticket", this.ticket);
      xhr.onload = function () {
        if (xhr.status === 200) {
          // 上传成功处理代码
          loading();
          message.success("上传成功");
          self.uploadStatus = true; //上传成功
          self.appendStatus = true; //追加成功
          self.getPdfFileList();
          self.pollCount = 0;
          self.getFileById(self.addFileId);
        } else {
          // 上传失败处理代码
          self.uploadStatus = false;
          self.appendStatus = false;
          message.error("上传失败");
        }
      };
      xhr.send(formData);
    },
    //移除图片
    deleteImage(element: any) {
      this.delPdf(element.id);
    },
    Move: function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const service: ConvertPdfService = new ConvertPdfService(this.$router);
      service.move({
        id: this.superiorId,
        list: this.moveList,
        onSuccess: (resp) => {
          if (resp.state == "true") {
            message.success("顺序调整成功");
            self.uploadStatus = true;
            self.moveStatus = true;
            this.getPdfFileList();
          }
        },
        onError() {
          self.uploadStatus = false;
          self.moveStatus = false;
          message.error("顺序调整失败 ");
        },
      });
    },
    repack: function (file: any) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const service: ConvertPdfService = new ConvertPdfService(this.$router);
      service.repack({
        ids: file[0].id,

        onSuccess: (resp) => {
          if (resp[0].state == "true") {
            let content = "";
            if (resp[0].payType == "addFiles") {
              content = `由于您追加上传pdf,需额外消耗${resp[0].TotalNeedPoint}积分,确认下载这个pdf吗`;
            } else {
              content = `需要消耗${resp[0].TotalNeedPoint}积分确认下载这个pdf吗`;
            }
            Modal.confirm({
              title: "提示",
              content: content,
              okText: "确认",
              cancelText: "取消",
              onOk: () => {
                this.downloadFilePdf(file[0].id, this.selectedItem);
              },
            });
          } else {
            this.downloadFilePdf(file[0].id, this.selectedItem);
          }
        },
        onError() {
          message.error("验证失败 ");
        },
      });
    },
    downloadDPdf(){
      message.info("即将提供，敬请期待");
    },
    batchRepack: function (ids: any) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const service: ConvertPdfService = new ConvertPdfService(this.$router);
      service.repack({
        ids: ids,
        onSuccess: (resp) => {
          const status = resp.some(
            (obj: { state: string }) => obj.state === "true"
          );
          const type = resp.some(
            (obj: { payType: string }) => obj.payType === "addFiles"
          );
          if (status) {
            let content = "";
            if (type) {
              content = `由于您追加上传pdf,需消耗${resp
                .filter((obj: { state: string }) => obj.state === "true")
                .reduce(
                  (sum: any, obj: { TotalNeedPoint: any }) =>
                    sum + obj.TotalNeedPoint,
                  0
                )}积分,确认批量下载pdf吗?`;
            } else {
              content = `需要消耗${resp
                .filter((obj: { state: string }) => obj.state === "true")
                .reduce(
                  (sum: any, obj: { TotalNeedPoint: any }) =>
                    sum + obj.TotalNeedPoint,
                  0
                )}积分,确认批量下载pdf吗?`;
            }

            Modal.confirm({
              title: "提示",
              content: content,
              okText: "确认",
              cancelText: "取消",
              onOk: () => {
                this.batchDownloadPdf(ids, "batchDownload");
              },
            });
          } else {
            this.batchDownloadPdf(ids, "batchDownload");
          }
        },
        onError() {
          message.error("验证失败 ");
        },
      });
    },
    endMove() {
      const newArray = this.childList.map(
        (
          item: { id: number; listOrder: number; fieldCName: string },
          index: number
        ) => ({
          id: item.id,
          listOrder: index + 1,
        })
      );
      const jsonString = JSON.stringify(newArray);
      this.moveList = jsonString;
      this.Move();
    },
    mergeFile() {
      log.info({
        p: "d",
        a: "c",
        o: "mergeFileBtnClick",
      });
      if (this.selectedKeys.length < 2) {
        message.warning("请您先选中两个及两个以上pdf文件进行合并");
        return;
      } else {
        const file = this.getPath(this.selectedItem);
        const ids = file.map((item: any) => {
          return item.id;
        });
        this.batchDownloadPdf(ids, "mergePdf");
      }
    },
    countpages() {
      log.info({
        p: "d",
        a: "c",
        o: "countpagesBtnClick",
      });
      let list = [];
      if (this.selectedKeys.length == 0) {
        list = this.list.map((item: any) => ({
          fileName: item.memo,
          pageCount: item.pageCount,
        }));
      } else {
        list = this.list
          .filter((item: any) => this.selectedKeys.includes(item.id))
          .map((item: any) => ({
            fileName: item.memo,
            pageCount: item.pageCount,
          }));
      }

      const text = list
        .map(
          (item: { fileName: any; pageCount: any }) =>
            `名称: '${item.fileName}', 页数: ${item.pageCount}`
        )
        .join("\n");
      const element = document.createElement("a");
      const file = new Blob([text], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = "计算pdf页数.txt";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    downImage(e: any) {
      // 发送 GET 请求获取图片数据
      console.log(e.imageUrl);
      fetch(e.imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // 创建临时下载链接
          const url = window.URL.createObjectURL(blob);

          // 设置下载链接的属性，并模拟点击下载链接
          const link = document.createElement("a");
          link.href = url;
          link.download = e.memo + "." + e.fileType;
          link.click();

          // 清除临时下载链接
          window.URL.revokeObjectURL(url);
        });
    },

    getFileFromUrl(url: string | URL, fileName: string, type: string) {
      return new Promise((resolve, reject) => {
        let blob = null;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Accept", "image/" + type);
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
          // 获取返回结果
          blob = xhr.response;
          const file = new File([blob], fileName+"."+type, { type: "image/" + type });
          // 返回结果
          resolve(file);
        };
        xhr.onerror = (e) => {
          reject(e);
        };
        // 发送
        xhr.send();
      });
    },
    //根据id查询单条目文档信息
    getOcrResultById(id: number | undefined) {
      console.log(id);
      if (id) {
        const service: OcrFileService = new OcrFileService(this.$router);
        service.getResult({
          id: id,
          onSuccess: (resp) => {
            this.currentFileItem = resp;
            if (this.currentFileItem.result) {
              const result = eval(`(${this.currentFileItem.result})`);
              if (result) {
                this.ocrTextResult = result.result.summary
                  .replace(/\n/g, "<br>")
                  .replace(/ /g, "&nbsp;");
              }
            }
            this.checkOcrStatus(id);
          },
          onError() {
            message.error("识别失败");
          },
        });
      } else {
        this.currentFileItem = {};
      }
    },
    checkOcrStatus(id: number) {
      let hasLoadingOcr = false;
      if (this.currentFileItem.ocrStatus == 0) {
        hasLoadingOcr = true;
      }

      if (hasLoadingOcr) {
        if (this.ocrCount < 30) {
          // 检查轮询次数
          this.ocrCount++; // 轮询次数加一
          this.ocrTimer = setTimeout(() => {
            this.getOcrResultById(id);
          }, 2000);
        } else {
          clearTimeout(this.ocrTimer); // 停止轮询
          this.ocrCount = 0;
          message.error("提取文字失败,已超时...");
        }
      } else {
        clearTimeout(this.ocrTimer);
        this.ocrCount = 0;
        if (this.currentFileItem.ocrStatus == 1) {
          message.success("提取文字成功，请查看");
          this.ocrResultShow = true;
        }else if(this.currentFileItem.ocrStatus == -1){
          message.error("提取文字失败");
        }
        
      }
    },

    //提取文字
    extractext(e: any) {
      console.log(e)
      this.ocrModelShow = true;
      this.startTimer();
      this.getFileFromUrl(e.imageUrl, e.fileName, e.fileType)
        .then((response) => {
          const file = response;
          console.log(file)
          this.OcrCreate(file);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    //ocr识别
    OcrCreate(files: any) {
      const formData = new FormData();
      if (files.length == undefined) {
        formData.append("file", files);
      } else {
        for (let i = 0; i < files.length; i++) {
          formData.append("file", files[i]);
        }
      }

      const baseUrl = process.env.VUE_APP_API_URL;
      axios
        .post(`${baseUrl}ocr/file/fileUpload`, formData, {
          headers: {
            apiVersion: "0.1.0",
            ticket: this.ticket,
          },
        })
        .then((response: any) => {
          const id = response.data.join("");

          this.getOcrResultById(id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleOk(){
      this.ocrResultShow = false;
    }
  },
});
