import axios from "axios";

interface LogRecord {
  [key: string]: any;
}

const request = axios.create({
  baseURL: `https://oss-log-1289076259473456-cn-beijing.cn-beijing.log.aliyuncs.com/logstores/fiq/track?APIVersion=0.6.0&e=${process.env.VUE_APP_LOG_ENV}&s=w`,
  timeout: 5000,
});

const log = {
  info(record: LogRecord) {
    this.log(record, "i");
  },
  warn(record: LogRecord) {
    this.log(record, "w");
  },
  error(record: LogRecord) {
    this.log(record, "e");
  },
  log(record: LogRecord, level: string) {
    if (!record) {
      throw new Error("record is required.");
    }
    if (!level) {
      throw new Error("level is required.");
    }
    record.l = level;
    // info level log.
    request({
      url: "",
      method: "get",
      params: record,
    });
  },
};

export default log;
