import { defineComponent } from "vue";
import  log  from "./../../service/log";
export default defineComponent({
  data() {
    return {
      startTime: Date.now(),
    };
  },
  mounted() {
    log.info({
      p: "u",
      a: "m",
    });
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "u",
      a: "u",
      d: stayTime,
    });
  },
  name: "UsbKeyView",
  methods:{
   
  }
});
