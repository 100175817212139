/* eslint-disable @typescript-eslint/no-this-alias */
import axios from "axios";
import { defineComponent } from "vue";
import footerComponent from "../../components/footer/FooterComponent.vue";
import { WelComeModel } from "../../entity/pagemodel/WelComeModel";
import { AccountService } from "../../service/AccountService";
import { ProfileService } from "../../service/ProfileService";
import { TicketService } from "../../service/TicketService";
import { TokenService } from "../../service/TokenService";
import log from "./../../service/log";
export default defineComponent({
  data() {
    return {
      ...new WelComeModel(),
      startTime: Date.now(),
    };
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "o",
      a: "u",
      d: stayTime,
    });
  },
  name: "HomeView",
  methods: {
    createToken: function () {
      log.info({
        p: "o",
        a: "c",
      });
      this.showCreate = false;
      const service = new TokenService(this.$router);
      const self = this;
      service.create((data) => {
        self.token = data;
      });
    },
    formatByte: function (bytes: number) {
      if (bytes == 0) {
        return "0 页";
      }
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
    },
    logout: function () {
      log.info({
        p: "o",
        a: "c",
      });
      new TicketService().remove();
      this.$router.push("/login");
    },
    copyAccessToken: function () {
      if (!this.token) {
        return;
      }
      const tokenString = this.token.token;
      if (!tokenString) {
        return;
      }
      const self = this;
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(tokenString).then(function () {
          self.message = "复制成功！";
        });
      }
    },
    downloadDemo: function () {
      log.info({
        p: "o",
        a: "c",
      });
      const baseUrl = process.env.VUE_APP_API_URL;
      const ticketService = new TicketService();
      const ticket = ticketService.get();

      axios({
        url: `${baseUrl}user-resource/openOcrDemo`,
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": "application/json; application/octet-stream",
          apiVersion: "0.1.0",
          ticket: ticket,
        },
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "ocr-demo.zip");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
  components: { footerComponent },
  mounted() {
    log.info({
      p: "o",
      a: "m",
    });
    this.showCopy = navigator.clipboard && window.isSecureContext;
    const self = this;
    const service = new TokenService(this.$router);
    service.list((data) => {
      if (data.length > 0) {
        self.token = data[0];
      } else {
        self.showCreate = true;
      }
    });
    const ticketService = new TicketService();
    if (!ticketService.get()) {
      this.$router.push("/login");
    }
    // load account
    const accountService = new AccountService(this.$router);
    accountService.getOne((account) => {
      self.account = account;
    });

    // load profile
    const profileService = new ProfileService(this.$router);
    profileService.getOne((profile) => {
      const newUser = "新用户";
      self.username = profile ? profile.name || newUser : newUser;
    });
    // TODO: read from config.
    self.ocrUrl = `${process.env.VUE_APP_API_URL}open-api/ocr`;
  },
});
