export class groupUser {
  id?: number;
  userName?: string;
  userPhoneNumber?: string; //员工手机号
  createdAt?: bigint;
  updatedAt?: bigint;
  joiningMethod?: string; //进入集团方式
  inviteUserName?: string; //邀请人姓名
  approveUserName?: string; //	审批人姓名
  joiningAt?: string; //加入集团时间
  userRole?: string;
  groupName?: string;
}
