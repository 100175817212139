import axios, { AxiosRequestConfig } from "axios";
import { Router } from "vue-router";
import { TicketService } from "./TicketService";

export abstract class BaseService<T> {
  router: Router;
  request(url: string, method: string, data: any, headers?: any) {
    const baseUrl = process.env.VUE_APP_API_URL;
    //const baseUrl = "http://localhost:80/";
    if (!headers) {
      headers = {};
    }
    headers.apiVersion = "0.1.0";
    const ts = new TicketService();
    const ticket = ts.get();
    if (ticket) {
      headers.ticket = ticket;
    }
    //const dataStr = JSON.stringify(data);
    url = baseUrl + url;

    const config: AxiosRequestConfig<any> = {
      method: method,
      url: url,
      headers: headers,
    };
    if ("post" === method || "put" === method ) {
      config.data = data;
    } else {
      config.params = data;
    }
    return axios.request(config);
  }

  post(url: string, data: object) {
    return this.request(url, "post", data);
  }
  getPost(url: string, data: object, headers?: object) {
    return this.request(url, "post", data, headers);
  }

  get(url: string, data: object, headers?: object) {
    return this.request(url, "get", data, headers);
  }
  delete(url: string, data: object) {
    return this.request(url, "delete", data);
  }
  putFile(url: string, data: object, headers?: object) {
    return this.request(url, "put", data, headers);
  }

  getPut(url: string, data: object, headers?: object) {
    return this.request(url, "put", data, headers);
  }

  put(data: T, callback: (rest: T) => any) {
    this.request(this.getUrl(), "put", data, {})
      .then((resp) => {
        callback(resp.data);
      })
      .catch(this.errorHandler);
  }

  errorHandler(err: any) {
    if (err.response.status === 401) {
      // ticket expired
      new TicketService().remove();
      // this.router.push("/");
    }
  }

  getWithLogin(url: string, callback: (data: any) => any, _router: any) {
    this.get(url, {})
      .then((resp) => {
        callback(resp.data);
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  getOne(callback: (data: T) => any) {
    this.getWithLogin(this.getUrl(), callback, this.router);
  }

  protected abstract getUrl(): string;

  constructor(router: Router) {
    this.router = router;
  }
}
