import { User } from "../entity/User";
import { BaseService } from "./BaseService";
import { OcrFile } from "../entity/OcrFile";
import { OcrFileRequest } from "../entity/OcrFileRequest";
import { DateformatUtil } from "../util/DateformatUtil";
import { AliyunOSSUtil } from "../util/AliyunOSSUtil";
import { AliyunOSS } from "../entity/AliyunOSS";
import { TicketService } from "./TicketService";
import axios from "axios";
import log from "./log";
export class OcrFileService extends BaseService<User> {
  protected getUrl(): string {
    return "ocr";
  }
  //查询最近两条文档
  getRecentFileList(callback: (data: Array<OcrFile>) => void) {
    this.get("ocr/files/", { pageNo: 1, pageSize: 2 }).then((resp) => {
      const dateformatUtil = new DateformatUtil();
      const list = resp.data.content;
      for (let i = 0; i < list.length; i++) {
        list[i].createdAt = dateformatUtil.formatDate(
          parseInt(list[i].createdAt || "0")
        );
      }
      callback(list);
    });
  }
  //查询所有文档列表
  getFileList(data: any, callback: (data: Array<OcrFile>) => void) {
    this.get("ocr/files/", { ...data }).then((resp) => {
      const dateformatUtil = new DateformatUtil();
      const list = resp.data.content;
      for (let i = 0; i < list.length; i++) {
        if (list[i].ocrStatus == -1) {
          if (list[i].memo) {
            list[i].memo = `${
              list[i].comment.length > 10
                ? list[i].comment.substring(0, 10) + "..."
                : list[i].comment
            }`;
          } else {
            list[i].memo = `未命名文件`;
          }
        } else if (list[i].ocrStatus == 0 || list[i].ocrStatus == "") {
          if (list[i].memo) {
            list[i].memo = `${
              list[i].comment.length > 10
                ? list[i].comment.substring(0, 10) + "..."
                : list[i].comment
            }`;
          } else {
            list[i].memo = `未命名文件`;
          }
        } else {
          if (list[i].memo) {
            list[i].memo = `${
              list[i].memo.length > 10
                ? list[i].memo.substring(0, 10) + "..."
                : list[i].memo
            }`;
          } else {
            list[i].memo = `未命名文件`;
          }
        }

        list[i].createdAt = dateformatUtil.formatDate(
          parseInt(list[i].createdAt || "0")
        );
      }
      callback(list);
    });
  }

  //根据id查询单条目文档信息
  getFileById(id: number, callback: (data: OcrFile) => void) {
    this.get(`ocr/file/${id}`, {}).then((resp) => {
      const dateformatUtil = new DateformatUtil();
      const data = resp.data;
      if (data.ocrStatus != -1) {
        if (data.comment) {
          data["memo"] = `${
            data.comment.length > 5
              ? data.comment.substring(0, 5) + "..."
              : data.comment
          }`;
        } else {
          data["memo"] = `未命名文件`;
        }
      } else {
        if (data.comment) {
          data["memo"] = `${
            data.comment.length > 5
              ? data.comment.substring(0, 5) + "..."
              : data.comment
          }`;
        } else {
          data["memo"] = `未命名文件`;
        }
      }

      data.createdAt = dateformatUtil.formatDate(
        parseInt(data.createdAt || "0")
      );
      callback(data);
    });
  }

  getResult(OcrFileRequest: OcrFileRequest) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `${baseUrl}ocr/file/${OcrFileRequest.id}`,

      method: "get",
      // responseType: "blob",
      headers: {
        apiVersion: "0.1.0",
        ticket: ticket,
      },
    })
      .then((response) => {
        OcrFileRequest.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "f",
            a: "e",
          });
          OcrFileRequest.onError();
        }
      });
  }

  ocrPay(OcrFileReq: OcrFileRequest) {
    return this.post(`ocr/file/pay/${OcrFileReq.id}/${OcrFileReq.type}`, {})
      .then(function (response) {
        OcrFileReq.onSuccess(response.data);
      })
      .catch(function () {
        OcrFileReq.onError();
      });
  }

  getFileListByInterval(OcrFileReq: OcrFileRequest) {
    return this.get(`ocr/file/interval`, {
      id: OcrFileReq.id,
      startPage: OcrFileReq.startPage,
      endPage: OcrFileReq.endPage,
    })
      .then(function (response) {
        OcrFileReq.onSuccess(response.data);
      })
      .catch(function () {
        OcrFileReq.onError();
      });
  }

  //根据id查询单条目图片信息
  getImageById(data: any, callback: (data: string) => void) {
    this.get(`ocr/file/image/${data.id}`, {})
      .then((resp) => {
        callback(resp.data);
      })
      .catch(() => {
        log.error({
          p: "f",
          a: "e",
        });
      });
    /*// const aliyunOSSUtil = new AliyunOSSUtil();
    // const aliyunOSS = new AliyunOSS();
    // const client = aliyunOSSUtil.getClient(aliyunOSS);
    // const imageUrl = aliyunOSSUtil.getImageFile(
    //   client,
    //   `userfiles/${data.filePath}${data.fileName}.${data.fileType}`
    // );
    // callback(imageUrl);*/
  }
  //根据id查询单条目图片信息
  downloadFileById(data: any, callback: (data: string) => void) {
    console.log("filedata", data);
    const aliyunOSSUtil = new AliyunOSSUtil();
    const client = aliyunOSSUtil.getClient(data.aliyunOSS);
    if (
      (data.data.fileType == "pdf" && data.type == "d-pdf") ||
      (data.data.fileType == "tif" && data.type == "d-pdf")
    ) {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}_m_d.pdf`
      );
      callback(imageUrl);
    } else if (
      (data.data.fileType == "pdf" && data.type == "pdf") ||
      (data.data.fileType == "tif" && data.type == "pdf")
    ) {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}_m.pdf`
      );
      console.log("图片", imageUrl);
      callback(imageUrl);
    } else if (
      (data.data.fileType == "pdf" && data.type == "txt") ||
      (data.data.fileType == "tif" && data.type == "txt")
    ) {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}_m.txt`
      );
      callback(imageUrl);
    } else if (
      (data.data.fileType == "pdf" && data.type == "docx") ||
      (data.data.fileType == "tif" && data.type == "docx")
    ) {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}_m.docx`
      );
      callback(imageUrl);
    } else if (
      (data.data.fileType == "pdf" && data.type == "xlsx") ||
      (data.data.fileType == "tif" && data.type == "xlsx")
    ) {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}_m.xlsx`
      );
      callback(imageUrl);
    } else if (data.type == "d-pdf") {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}_d.pdf`
      );
      callback(imageUrl);
    } else if (data.type == "pdf") {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}.pdf`
      );
      callback(imageUrl);
    } else if (data.type == "txt") {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}.txt`
      );
      callback(imageUrl);
    } else if (data.type == "docx") {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}.docx`
      );
      callback(imageUrl);
    } else if (data.type == "excel") {
      const imageUrl = aliyunOSSUtil.getImageFile(
        client,
        `userfiles/${data.data.filePath}${data.data.fileName}.xlsx`
      );
      callback(imageUrl);
    }
  }
  //修改当前文件的无痕模式状态
  updateNoTraceMode(data: any, callback: (data: any) => void) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `${baseUrl}ocr/files/${data.ids}`,
      method: "PUT",
      // responseType: "blob",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        noTraceMode: data.noTraceMode,
      },
    })
      .then((response) => {
        callback(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          callback(err);
          log.error({
            p: "f",
            a: "e",
          });
        }
      });
  }
  //获取阿里云OSS链接参数
  getAliyunOSSPolicy(callback: (data: AliyunOSS) => void) {
    this.get("aliyun/oss", {})
      .then((resp) => {
        callback(resp.data);
      })
      .catch(() => {
        log.error({
          p: "f",
          a: "e",
        });
      });
  }

  //修改当前文件的备注
  updateComment(data: any, callback: (data: any) => void) {
    console.log("updata", data);
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `${baseUrl}user-resource/file/${data.id}/detail`,
      method: "PUT",
      // responseType: "blob",
      headers: {
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        memo: data.memo,
      },
    })
      .then((response) => {
        callback(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          callback(err);
          log.error({
            p: "f",
            a: "e",
          });
        }
      });
  }

  downloadDeduct(OcrFileReq: OcrFileRequest) {
    return this.post(`ocr/file/deduct/${OcrFileReq.id}/${OcrFileReq.type}`, {})
      .then(function (response) {
        OcrFileReq.onSuccess(response.data);
      })
      .catch(function () {
        OcrFileReq.onError();
      });
  }
  repeatCheck(OcrFileReq: OcrFileRequest) {
    return this.get(`ocr/file/repack/${OcrFileReq.type}`, {
      repeatCheck: OcrFileReq.repeatCheck,
    })
      .then(function (response) {
        OcrFileReq.onSuccess(response.data);
      })
      .catch(function () {
        OcrFileReq.onError();
      });
  }

  // upload(OcrFileReq: OcrFileRequest) {
  //   return this.get(`ocr/file/upload`, {
  //     file: OcrFileReq.file
  //   })
  //     .then(function (response) {
  //       OcrFileReq.onSuccess(response.data);
  //     })
  //     .catch(function (error) {
  //       OcrFileReq.onError();
  //     });
  // }
}
