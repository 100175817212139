import { TokenResponse } from "./../entity/TokenResponse";
import { User } from "./../entity/User";
import { BaseService } from "./BaseService";
import log from "./log";

const url = "user-resource/accessToken";
export class TokenService extends BaseService<User> {
  protected getUrl(): string {
    return url;
  }
  list(callback: (data: Array<TokenResponse>) => void) {
    this.getWithLogin(url + "s", callback, this.router);
  }
  create(callback: (data: TokenResponse) => void) {
    this.post(url, {})
      .then((resp) => {
        callback(resp.data);
      })
      .catch((error) => {
        console.log(error);
        log.error({
          p: "o",
          a: "e",
        });
      });
  }
}
