/* eslint-disable @typescript-eslint/no-this-alias */
import { defineComponent } from "vue";
import  log  from "../../service/log";
import { SearchOutlined, RightOutlined } from "@ant-design/icons-vue";
import { OrderService } from "./../../service/OrderService";
import { ProfileService } from "./../../service/ProfileService";
export default defineComponent({
  components:{
        SearchOutlined,RightOutlined
  },
  data() {
    return {
      startTime: Date.now(),
      selectValue: <any>"全部",
      point: 0,
      time: "",
      inputorTime: "",
      start: 0,
      end: 0,
      options: [
        { label: "全部", value: "全部" },
        { label: "支付成功", value: "1" },
        { label: "支付失败", value: "2" },
        { label: "待支付", value: "0" },
      ],
      data: [],
      pagination:{
        current: 1, // 当前页码
        pageSize: 3, // 每页显示数量
        total: 0,
        showSizeChanger: true, // 是否显示每页显示数量切换器
        pageSizeOptions: ["3", "5"], // 每页显示数量选项
        onChange: this.handlePageChange,
        onShowSizeChange: this.handlePageSizeChange,
        showTotal: (total: any) => `共 ${total} 条`, // 显示总数的文本
      }
    };
  },
  mounted() {
    log.info({
      p: "u",
      a: "m",
    });
    this.getOrders();
    this.getProfile();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "u",
      a: "u",
      d: stayTime,
    });
  },
  name: "UserView",
  methods: {
    getProfile() {
      const profileService = new ProfileService(this.$router);
      profileService.getOne((profile) => {
        const user = profile as any
        this.point = user.point;
      });
    },
    handlePageChange(page: number) {
      // 处理页码改变事件
      this.pagination.current = page;
      this.getOrders();
    },
    handlePageSizeChange(_: any, pageSize: any) {
      // 处理每页显示数量改变事件
      this.pagination.pageSize = pageSize;
      this.getOrders();
    },
    handleDateChange(value: any) {
      console.log(value)
      if(value == null){
        this.start = 0;
        this.end = 0;
      }else{
        this.start = new Date(value[0]).setHours(0, 0, 0, 0); // 获取开始时间戳
        this.end= new Date(value[1]).setHours(23, 59, 59, 999); // 获取结束时间戳
      }
    
   },
    formatTime(timestamp: string | number | Date) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hour = ("0" + date.getHours()).slice(-2);
      const minute = ("0" + date.getMinutes()).slice(-2);
      const second = ("0" + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },
    getOrders: function () {
      const service: OrderService = new OrderService(this.$router);
      if(this.start == 0 || this.end == 0){
        this.inputorTime = "";
        console.log(this.inputorTime)
      }else{
        this.inputorTime = this.start+"-"+ this.end
      }
      let item = {};
      if(this.selectValue == 0 || this.selectValue == 1 || this.selectValue == 2){
        item = { 
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          searchType: "createdAt",
          searchContext: this.inputorTime,
          orderStatus: this.selectValue
        }
      }else{
        item = { 
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          searchType: 'createdAt',
          searchContext: this.inputorTime,
          orderStatus: ""
        }
      }
      
      service.getOrder({
        ...item,
        onSuccess: (resp) => {
          const self = this;
          this.data = resp.content;
          this.data.forEach(function (obj: { createdAt: string }) {
            const timestamp = obj.createdAt;
            obj.createdAt = self.formatTime(timestamp);
          });
          this.pagination.total = resp.totalCount
        },
        onError() {
          console.log("列表获取失败");
        },
      });
    },
    onSearch(){
      this.getOrders();
    }
  },
});
