const messages = {
  // home
  welcomeMessage: "汇智入圈，AI时代的文档处理专家",
  recognition: "全文识别",
  tableRecognition: "表格识别",
  cardRecognition: "卡证票据识别",
  tableDataExtraction: "表格数据提取",
  toPdf: "转换至PDF",
  toOfd: "转换至OFD",
  toImage: "转换至图片",
  nlpExtraction: "NLP信息抽取",
  recognitionDescription: "以文字形式全文提取，支持不超过10M的文件",
  tableDescription: "根据表格内容识别，并可保存表格文档",
  cardDescription: "根据证件格式识别",
  tableDataDescription: "批量表格数据识别及下载",
  toPdfDescription: "将图片或者文件转换为PDF格式",
  toOfdDescription: "将图片或者文件转换为OFD格式",
  toImageDescription: "将文件转换为图片格式",
  nlpDescription: "更加智能化的文档案录入系统，支持文字识别、框选识别",
  //head
  homePage: "首页",
  encryption: "加密使用",
  intelligentInput: "智能录入系统",
  hello: '您好',
  newUser: "新用户",
  login: "登录",
  exit: "退出",
  userCenter: '个人中心',
  message: '消息',
  checkIn: '签到',
  inviteFriends: '邀请朋友',
  upload: "上传文件",
  downloadClient: "批量识别请下载客户端",
  showOriginalImage: "显示原图",
  showAnnotation: "显示标注",
  showResult: "显示结果",
  extractionResult: "抽取结果",
  recognitionComparison: "识别对比",
  supportHandwriting: "支持手写体",
  dropFilesHere: "拖拽文件到此区域",
  //nlp
  chooseInfoExtractionCategory: "选择信息抽取类别",
  custom: "自定义",
  officeDocumentRecognition: "办公文档",
  IDCardRecognition: "身份证",
  bankCardRecognition: "银行卡",
  businessLicenseRecognition: "营业执照",
  passportRecognition: "护照",
  householdRegisterRecognition: "户口本",
  birthCertificateRecognition: "出生医学证明",
  bankStatementRecognition: "银行回单",
  mixedInvoiceRecognition: "混贴票据",
  VATInvoiceRecognition: "增值税发票",
  VATRollInvoiceRecognition: "增值税卷票",
  trainTicketRecognition: "火车票",
  taxiReceiptRecognition: "出租车票",
  flightItineraryRecognition: "飞机行程单",
  licensePlateRecognition: "车牌号",
  driverLicenseRecognition: "驾驶证",
  vehicleLicenseRecognition: "行驶证",
  motorVehicleSalesInvoiceRecognition: "机动车销售发票",
  medicalInvoiceRecognition: "医疗发票",
  medicalExpenseSettlementRecognition: "医疗费用结算单",
  medicalRecordRecognition: "病案首页",
  addField: '添加抽取字段',
  startExtraction: '开始抽取',
  //下载按钮
  downloadTxt: "下载TXT",
  downloadDoublePdf: "双层PDF",
  downloadTextPdf: "文字PDF",
  downloadWord: "WORD",
  downloadExcel: "EXCEL",
  mergePDF: "合并PDF",
  countPages: "计算PDF页数",
  downloadOFD: "下载OFD",
  mergeOFD: "合并OFD",
  countOFDPages: "计算OFD页数",
  downloadSourceText: "下载原文本",
  downloadRecognizedText: "下载识别文本",
  countTextPages: "计算文本页数",
  TemplateManagement: "模板管理",
  TemplateLists: "模板管理列表",
  uploadTemplate: "上传识别模板",
  dragImageOrder: "拖拽即可调整图片顺序",
  appendUpload: "追加上传图片",
  tableColumns: {
    num: "序号",
    memo: "模板名称",
    operation: "操作",

    fieldName: '设置抽取字段',
    content: '抽取结果'
  },
  removeImage: "移除图片",
  extractText: "提取文字",
  extractTable: "提取表格",
  extractAIKeyElements: "AI关键要素提取",
  downloadImage: "下载图片",
  moreAIOptions: "更多AI智慧设置 ->",
  phoneNumber: "手机号",
  resend: "重发",
  getCode: "获取",
  verifyCode: "验证码",
  enterElevenDigits: "请输入11位手机号",
  enterSixDigits: "请输入6位验证码",
  errorMessage: "错误消息",
  sendSuccess: '发送成功',
  loginSuccess: '登录成功',
  invalidCode: '验证码错误',

  //个人中心
  setting: "账户设置",
  purchase: "积分购买",
  bill: "积分账单",
  orders: "订单管理",
  mission: "任务中心",
  sign: "签到",
  invite: "邀请朋友",
  //账户设置
  account: '账户',
  fillInvitationCode: "填写邀请码",
  myPoints: "我的积分",
  remainingPoints: "积分可用于OCR文字识别，格式转换，1积分=1页",
  pointUsage: "积分使用情况",
  used: "已使用",
  unused: "未使用",
  point: '分'
};

export default messages;
