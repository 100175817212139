import { defineComponent } from "vue";
import footerComponent from "../../components/footer/FooterComponent.vue";
import { WelComeModel } from "../../entity/pagemodel/WelComeModel";
import log from "../../service/log";

export default defineComponent({
  data() {
    return {
      ...new WelComeModel(),
      startTime: Date.now(),
      imageUrl:require("../../assets/intelligent-input-system/download.png")
    };
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "o",
      a: "u",
      d: stayTime,
    });
  },
  name: "HomeView",
  methods: {
    downloadFile() {
      const link = document.createElement('a')
      link.href = "https://static.filequan.com/filequan.com_iis_1.0.0.zip"
      link.click()
    },
  },
  components: { footerComponent },
  mounted() {
    log.info({
      p: "o",
      a: "m",
    });
    this.showCopy = navigator.clipboard && window.isSecureContext;
  },
});
