import { defineComponent } from "vue";
import { AccountService } from "./../../service/AccountService";
import log from "../../service/log";
export default defineComponent({
  data() {
    return {
      startTime: Date.now(),
      bg: require("@/assets/sign/积分背景.png"),
      list: <any>{
        point: "10",
        csd: "0",
        tsd: "0",
      },
      todaySigned: false,
      singInDays: [
        {
          index: 1,
          point: 1.0,
          isToday: false,
          signed: true,
        },
        {
          index: 2,
          point: 2.0,
          isToday: true,
          signed: true,
        },
        {
          index: 3,
          point: 3.0,
          isToday: false,
          signed: false,
        },
        {
          index: 4,
          point: 4.0,
          isToday: false,
          signed: false,
        },
        {
          index: 5,
          point: 5.0,
          isToday: false,
          signed: false,
        },
        {
          index: 6,
          point: 6.0,
          isToday: false,
          signed: false,
        },
        {
          index: 7,
          point: 7.0,
          isToday: false,
          signed: false,
        },
      ],
    };
  },
  mounted() {
    log.info({
      p: "u",
      a: "m",
    });
    this.getAccount();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "u",
      a: "u",
      d: stayTime,
    });
  },
  name: "UsbKeyView",
  methods: {
    getAccount: function () {
      const service: AccountService = new AccountService(this.$router);
      service.getAccount({
        onSuccess: (resp: any) => {
          this.singInDays = resp.singInDays;
          this.list = resp;
          this.todaySigned = resp.todaySigned;
        },
        onError(err) {
          console.log(err);
        },
      });
    },
    signIn: function () {
      const service: AccountService = new AccountService(this.$router);
      service.signIn({
        onSuccess: () => {
          this.getAccount();
        },
        onError(err) {
          console.log(err);
        },
      });
    },
    signInClick() {
      this.signIn();
    },
  },
});
