/* eslint-disable @typescript-eslint/no-this-alias */
import { defineComponent } from "vue";
import {
  ArrowUpOutlined,
  InboxOutlined,
  PlusOutlined,
  MinusOutlined,
  DownloadOutlined,
  CompressOutlined,
  UploadOutlined,
  EditOutlined,
  CloudUploadOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons-vue";
import axios from 'axios';
import { OcrFileService } from "../../service/OcrFileService";
import { TicketService } from "../../service/TicketService";
import { OcrFile } from "../../entity/OcrFile";
import { AliyunOSS } from "@/entity/AliyunOSS";
import { message, Modal } from "ant-design-vue";
import { FilePolicyService } from "./../../service/FilePolicyService";
import log from "./../../service/log";
import { difference } from 'lodash-es';
export default defineComponent({
  data() {
    return {
      checked: true, //支持手写体
      status: <any>0,  //登录状态  0：未登录
      replaceFields: {
        title: "memo",
        key: "id",
      },
      isLoading: false,
      ocrCount: 0,
      superiorId: 0, 
      current: 0,
      stepShow: false,
      stepStatus: "process",
      steps:[
        { title: '文件上传', description: "正在上传中..." },
        {
          title: '文件拆分', description: "等待拆分中..." ,
        },
        {
          title: '文字识别' ,description: "等待识别中...",
        },
      ],
      expandedKeys: <any>[],
      //当前焦点节点的key
      currentNodeKey: 0 as number | undefined,
      currentselectItem: <any>[], //当前选中的文档条目信息
      childrenIndex: -1 as number, //当前子节点索引
      currentIndex: 0 as number, //当前结果的list索引
      showInputGroup: false, // 标志位，用于控制是否显示输入框组件
      memo: "",
      loading: false as boolean,
      fileid: 0,
      ids: <any>[],
      timer: null as any,
      startTime: 0,
      list: Array<OcrFile>(), //文档列表
      currentFileItem: <any>{}, //当前选中的文档条目信息
      currentImageBase64: "", //当前选中图片的base64
      tracelessChecked: true, //无痕模式开关
      selectedKeys: ["0-0-0"],
      activeKey: "xsyt",
      canvasWidth: 1800, // 画布大小
      canvasHeight: 1210,
      myCanvas: <any>null,
      ctx: <any>null,
      imgObject: <any>[],
      local: <any>null,
      imgX: 0, // 图片在画布中渲染的起点x坐标
      imgY: 0,
      imgScale: 1, // 图片的缩放大小
      oldImgScale: 0, //记录原始图片缩放大小
      oldImgX: 0, // 图片在画布中渲染的起点x坐标
      oldImgY: 0,
      extraImgList: <any>[], //图片列表
      ocrTextResult: <any>"",
      fileList: [],
      noTraceModeStatus: false, //是否开启无痕模式
      nodeId: 0,
      currentId: 0,
      defaultExpandedKeys: <any>[],
      payStatus: -1,
      superStatus: -1,
      pointStatus: false,
      superId: 0,
      noPayPage: 0,
      ticket: "",
      pollCount: 0,
    };
  },
  watch: {
    current(value) {
      if (value === 0 && this.stepStatus!= "error") {
        this.steps[0].description = "正在上传中...";
        this.steps[1].description = "等待拆分中...";
        this.steps[2].description = "等待识别中...";
      } else if (value === 1) {
        this.steps[0].description = "上传完成";
        this.steps[1].description = "正在拆分中...";
        this.steps[2].description = "等待识别中...";
      } else if (value === 2) {
        this.steps[0].description = "上传完成";
        this.steps[1].description = "拆分完成";
        this.steps[2].description = "正在识别中...";
      }  else if (value === 3) {
        this.steps[0].description = "上传完成";
        this.steps[1].description = "拆分完成";
        this.steps[2].description = "识别完成";
      } else if (value === -1) {
        this.current = 0;
        this.stepStatus = "error"
        this.steps[0].description = "上传失败";
        this.steps[1].description = "";
        this.steps[2].description = "";
      } else if (value === 0 && this.stepStatus == "error") {

        this.steps[0].description = "上传失败";
        this.steps[1].description = "";
        this.steps[2].description = "";
      }
    },
  },

  components: {
    ArrowUpOutlined,
    InboxOutlined,
    PlusOutlined,
    MinusOutlined,
    DownloadOutlined,
    CompressOutlined,
    UploadOutlined,
    EditOutlined,
    CloudUploadOutlined,
    LeftOutlined,
    RightOutlined,
  },
  name: "HomeView",
  mounted() {
    log.info({
      p: "f",
      a: "m",
    });
    this.startTime = Date.now();
    this.status = localStorage.getItem("status");
    //获取文档列表
    this.getFileList();
    //禁用默认拖拽事件
    this.disableDefaultDragEvents();
    //注册全局拖拽事件
    this.dragListener();
    //this.getCanvas();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "f",
      a: "u",
      d: stayTime,
    });
  },

  methods: {
    getNodeById(nodeId: any) {
      const node = this.findNodeById(nodeId, this.list);
      if (node) {
        const id = node.id;
        // 根据 nodeId 获取对应的 id
        console.log("Get id by nodeId:", id);
        return id;
      }
    },
    findNodeById(nodeId: any, nodes: any) {
      for (const node of nodes) {
        if (node.nodeId === nodeId) {
          console.log(node);
          return node;
        }
        if (node.children && node.children.length > 0) {
          const foundNode = this.findNodeById(nodeId, node.children) as any;
          console.log(foundNode);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    },
    nextNode() {
      if (this.currentNodeKey === null) return;
      if (this.currentId == this.nodeId - 1) {
        return;
      } else {
        this.currentId++;
        const id = this.getNodeById(this.currentId);
        this.currentNodeKey = id;
        this.getImageById(this.currentNodeKey);
        this.getFileById(this.currentNodeKey);
      }
    },
    prevNode() {
      if (this.currentNodeKey === null) return;
      if (this.currentId == 0) {
        return;
      } else {
        this.currentId--;
        const id = this.getNodeById(this.currentId);
        this.currentNodeKey = id;
        this.getImageById(this.currentNodeKey);
        this.getFileById(this.currentNodeKey);
      }
    },
    addNodeId(list: any) {
      for (const item of list) {
        item.nodeId = this.nodeId++;
        if (item.children && item.children.length > 0) {
          this.addNodeId(item.children);
        }
      }
    },
    //获取当前用户的所有文件列表
    getFileList() {
      const service: OcrFileService = new OcrFileService(this.$router);
      const obj = {
        pageNo: 1,
        pageSize: 50,
        noTraceModeStatus: this.noTraceModeStatus,
      };
      service.getFileList(obj, (data) => {
        this.list = data;
        this.addNodeId(this.list);
        //this.checkOcrStatus();
      });
    },
    //获取当前用户的所有文件列表
    getFileListOcr() {
      const service: OcrFileService = new OcrFileService(this.$router);
      const obj = {
        pageNo: 1,
        pageSize: 500,
        noTraceModeStatus: this.noTraceModeStatus,
      };
      service.getFileList(obj, (data) => {
        this.list = data;   
        this.addNodeId(this.list);
        this.checkOcrStatus();
      });
    },
    checkOcrStatus() {
      // 检查是否存在 ocrStatus 为 0 的项
      const hasLoadingData = this.list.some((item) => item.ocrStatus === 0);
      const matchData = this.list.find((item) => item.id === this.superiorId) as any;
        if(matchData.splitStatus == 1){
            this.current = 2;
        }
        if(matchData.ocrStatus == 1){
            this.current = 3;
            this.isLoading = false;
        }
      if (hasLoadingData) {
        if (this.pollCount < 50) {
          this.pollCount++; // 轮询次数加一
          this.timer = setTimeout(() => {
            this.getFileListOcr();
          }, 3000);
        } else {
          this.pollCount = 0;
          this.timer = null;
          clearTimeout(this.timer); // 停止轮询
        }
      } else {
        this.isLoading = false;
        this.current = 3;
        this.pollCount = 0;
        this.timer = null;
        clearTimeout(this.timer);
      }
    },
    getCanvas() {
      const width = (this.$refs.imageDiv as HTMLElement)?.offsetWidth; //画布宽
      const height = (this.$refs.imageDiv as HTMLElement)?.offsetHeight; //画布高
      if (width && height) {
        (document.getElementById("bargraphCanvas") as any).width =
          width * window.devicePixelRatio;
        (document.getElementById("bargraphCanvas") as any).height =
          height * window.devicePixelRatio;
        this.canvasWidth = width * window.devicePixelRatio;
        this.canvasHeight = height * window.devicePixelRatio;
      }
    },
    //根据id查询单条目文档信息
    getFileById(id: number | undefined) {
      if (id) {
        const service: OcrFileService = new OcrFileService(this.$router);
        service.getFileById(id, (data) => {
          this.currentFileItem = data;
          this.memo = this.currentFileItem.comment;
          this.superId = this.currentFileItem.superiorId;
          if (this.superId) {
            this.pointStatus = false;
            this.payStatus = this.currentFileItem.payStatus;
          } else {
            this.superStatus = this.currentFileItem.payStatus;
          }
          if (this.currentFileItem.jsonResult) {
            const jsonResult = eval(`(${this.currentFileItem.jsonResult})`);
            if (jsonResult) {
              this.tableRestoration(jsonResult);
            }
          }
          //根据id查询单条目图片信息
          
        });
      } else {
        this.currentFileItem = {};
      }
    },
    //支付单页费用
    payOcr(type: string) {
      if(this.status == 0){
        Modal.confirm({
          title: "提示",
          content: `检测到您未登录，是否去登录！`,
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            this.$router.push("/login");
          },
        });
        return;
      }
      if (type == "all" && this.superId != null) {
        this.pay(this.superId, type);
      } else {
        this.pay(this.currentNodeKey, type);
      }
    },
    toProduct() {
      this.$router.push("/account/purchase");
    },
    //支付单页
    pay(id: any, type: string) {
      const service: OcrFileService = new OcrFileService(this.$router);
      service.ocrPay({
        id: id,
        type: type,
        onSuccess: (resp: any) => {
          if (resp == true) {
            if (this.superId) {
              this.getFileById(this.currentNodeKey);
            } else {
              this.getFileById(id);
            }
          } else {
            this.pointStatus = true;
            this.noPayPage = resp.noPay;
            message.error("积分不足");
          }
        },
        onError() {
          message.error("支付失败 ");
        },
      });
    },
    //根据id查询单条目图片信息
    // getImageById(id: number | undefined) {
    //   if (id) {
    //     const service: OcrFileService = new OcrFileService(this.$router);
    //     service.getImageById(this.currentFileItem, (data) => {
    //       this.currentImageBase64 = data;
    //       this.createCanvas();
    //     });
    //   } else {
    //     this.currentImageBase64 = "";
    //   }
    // },
    getImageById(id: any){
        this.ticket = new TicketService().get() as any;
        const baseUrl = process.env.VUE_APP_API_URL;
        this.currentImageBase64 = `${baseUrl}ocr-convert/common/preview/${id}?ticket=${this.ticket}`;
        this.createCanvas();
    },
    //创建画布
    createCanvas() {
      //1.创建image
      const imgObj = new Image();
      // imgObj.setAttribute("crossOrigin", "anonymous");
      //imgObj.src = "data:image/png;base64," + this.currentImageBase64;
      imgObj.src = this.currentImageBase64;
      //2.预加载图片
      imgObj.onload = () => {
        //3.创建图片对象
        const obj = {
          //url: "data:image/png;base64," + this.currentImageBase64,
          url: this.currentImageBase64,
          x: 0,
          y: 0,
          width: imgObj.width,
          height: imgObj.height,
        };
        // 重置画布的缩放属性
     
        //4.记录原始缩放大小，x轴、y轴坐标
        this.oldImgScale = this.imgScale;
        this.oldImgX = this.imgX;
        this.oldImgY = this.imgY;
        //5.设置图片列表
        this.extraImgList[0] = obj;
        //6.创建画布
        this.myCanvas = this.$refs.bargraphCanvas;
        this.ctx = this.myCanvas.getContext("2d");
        //7.加载图片信息
        this.loadingImage();
        //8.在画布上注册鼠标的拖动和缩放事件
        this.canvasEventsInit();
      };
    },
    //加载图片信息
    loadingImage() {
      const self = this;
      const extraImgList = self.extraImgList;
      const length = extraImgList.length;
      const imageList = <any>[];
      let count = 0;
      //1.加载图片
      const img = new Image();
      const bgImg = extraImgList[0];
      img.src = bgImg.url;
      img.onload = () => {
        imageList.push({
          img: img,
          x: bgImg.x,
          y: bgImg.y,
          width: bgImg.width,
          height: bgImg.height,
        });
        ++count;
        if (length > 1) {
          //加载剩余图片
          for (let key = 1; key < length; key++) {
            const item = extraImgList[key];
            const extarImg = new Image();
            extarImg.src = item.url;
            extarImg.onload = () => {
              imageList.push({
                img: extarImg,
                x: item.x,
                y: item.y,
                width: item.width,
                height: item.height,
              });
              if (++count >= length) {
                self.imgObject = imageList;
                self.drawImage(imageList, 0, 0, 0, 0);
              }
            };
          }
        } else {
          const iw = imageList[0].width;
          const ih = imageList[0].height;
          self.ctx.fillStyle = "white";
          self.ctx.fill();
          self.imgObject = imageList;
          //2.计算最佳尺寸四个角坐标
          const local = self.calculate(iw, ih);
          self.local = local;
          //3.将图片加载到画布上
          self.drawImage(imageList, local.px, local.py, local.pw, local.ph);
        }
      };
    },
    //计算图片最佳缩放大小，返回四个角坐标
    calculate(pw: number, ph: number) {
      const self = this;
      const canvas = self.myCanvas;
      const w = canvas.width;
      const h = canvas.height;

      let px = 0;
      let py = 0;
      if (pw < w && ph < h) {
        px = 0.5 * w - 0.5 * pw;
        py = 0.5 * h - 0.5 * ph;
      } else if (ph / pw > h / w) {
        const uu = ph;
        ph = h;
        pw = (pw * h) / uu;
        px = 0.5 * w - 0.5 * pw;
      } else {
        const uu = pw;
        pw = w;
        ph = (ph * pw) / uu;
        py = 0.5 * h - 0.5 * ph;
      }
      self.imgObject[0].x = px;
      self.imgObject[0].y = py;
      self.imgObject[0].width = pw;
      self.imgObject[0].height = ph;
      self.imgX = px;
      self.imgY = py;
      return {
        px,
        py,
        pw,
        ph,
      };
    },
    //将图片加载到画布上
    drawImage(imgList: any, x: number, y: number, w: number, h: number) {
      const self = this;
      self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      for (let i = 0; i < imgList.length; i++) {
        self.ctx.drawImage(
          imgList[i].img, //规定要使用的图片
          x * self.imgScale,
          y * self.imgScale,
          w * self.imgScale,
          h * self.imgScale
        );
      }
    },
    //在画布上注册鼠标的拖动和缩放事件
    canvasEventsInit() {
      const self = this;
      const canvas = self.myCanvas;
      //拖动鼠标
      canvas.onmousedown = function (event: any) {
        const imgx = self.imgX;
        const imgy = self.imgY;
        const pos = {
          x: event.clientX,
          y: event.clientY,
        }; //坐标转换，将窗口坐标转换成canvas的坐标
        canvas.onmousemove = function (evt: any) {
          //移动
          canvas.style.cursor = "move";
          const x = (evt.clientX - pos.x) * 2 + imgx;
          const y = (evt.clientY - pos.y) * 2 + imgy;
          self.imgX = x;
          self.imgY = y;
          self.drawImage(
            self.imgObject,
            x,
            y,
            self.imgObject[0].width,
            self.imgObject[0].height
          ); //重新绘制图片

          //如果当前左上tab为显示标注，则拖动时应刷新标注位置
          if (self.activeKey === "xsbz") {
            self.xsbzClick();
          } else if (self.activeKey === "xsjg") {
            self.xsjgClick();
          } else if (self.activeKey === "sbdb") {
            self.sbdbClick();
          }
        };
        canvas.onmouseup = function () {
        
          canvas.onmousemove = null;
          canvas.onmouseup = null;
          canvas.style.cursor = "default";
        };
      };
      //滚轮放大缩小
      canvas.onmousewheel = canvas.onwheel = function (event: any) {
        //滚轮放大缩小
        const pos = self.windowToCanvas(event.clientX, event.clientY);
        const wheelDelta = event.wheelDelta
          ? event.wheelDelta
          : event.deltalY * -40; //获取当前鼠标的滚动情况
        const newPos = <any>{
          x: ((pos.x - self.imgX) / self.imgScale).toFixed(2),
          y: ((pos.y - self.imgY) / self.imgScale).toFixed(2),
        };
        if (wheelDelta > 0) {
          // 放大
          self.imgScale += 0.1;
          self.imgX = (1 - self.imgScale) * newPos.x + (pos.x - newPos.x);
          self.imgY = (1 - self.imgScale) * newPos.y + (pos.y - newPos.y);
        } else {
          //  缩小
          self.imgScale -= 0.1;
          if (self.imgScale < 0.3) {
            //最小缩放1
            self.imgScale = 0.3;
          } else {
            self.imgX = (1 - self.imgScale) * newPos.x + (pos.x - newPos.x);
            self.imgY = (1 - self.imgScale) * newPos.y + (pos.y - newPos.y);
          }
        }
        self.drawImage(
          self.imgObject,
          self.imgX,
          self.imgY,
          self.imgObject[0].width,
          self.imgObject[0].height
        ); //重新绘制图片
        event.preventDefault && event.preventDefault();
        //如果当前左上tab为显示标注，则放大缩小时应刷新标注位置
        if (self.activeKey === "xsbz") {
          self.xsbzClick();
        } else if (self.activeKey === "xsjg") {
          self.xsjgClick();
        } else if (self.activeKey === "sbdb") {
          self.sbdbClick();
        }
      };
    },
    //还原缩放按钮
    returnScale() {
      const self = this;
      if (self.currentImageBase64 == "") {
        return;
      }
      self.imgScale = self.oldImgScale;
      //清空画布
      self.ctx.clearRect(0, 0, self.canvasWidth, self.canvasHeight);
      const imgList = self.imgObject;
      const imageList = self.extraImgList;
      const iw = imageList[0].width;
      const ih = imageList[0].height;
      const local = self.calculate(iw, ih);

      for (let i = 0; i < imgList.length; i++) {
        self.ctx.drawImage(
          imgList[i].img, //规定要使用的图片
          local.px * self.imgScale,
          local.py * self.imgScale,
          local.pw * self.imgScale,
          local.ph * self.imgScale
        );
      }

      if (self.activeKey === "xsbz") {
        self.xsbzClick();
      } else if (self.activeKey === "xsjg") {
        self.xsjgClick();
      } else if (self.activeKey === "sbdb") {
        self.sbdbClick();
      }
    },
    //下载图片
    downLoadPngImage() {
      log.info({
        p: "f",
        a: "c",
        o: "downLoadPngImageBtn",
      });
      const self = this;
      if (self.currentImageBase64 == "" && this.currentFileItem.fileType != 'jpg') {
        return;
      }
      const baseUrl = process.env.VUE_APP_API_URL;
      const filepath =this.currentFileItem.filePath + this.currentFileItem.fileName + "." + this.currentFileItem.fileType;
      const path = `${baseUrl}ocr-convert/common/download?filepath=${filepath}`
  
      const link = document.createElement("a");
      link.href = path;
      link.download = "image.jpg";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
    },
    //放大图片
    amplificationImage() {
      const self = this;
      if (self.currentImageBase64 == "") {
        return;
      }
      this.imgScale = this.imgScale + 0.1;
      //清空画布
      self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      const imgList = self.imgObject;
      const imageList = self.extraImgList;
      const iw = imageList[0].width;
      const ih = imageList[0].height;
      const local = self.calculate(iw, ih);
      for (let i = 0; i < imgList.length; i++) {
        self.ctx.drawImage(
          imgList[i].img, //规定要使用的图片
          local.px * self.imgScale,
          local.py * self.imgScale,
          local.pw * self.imgScale,
          local.ph * self.imgScale
        );
      }
      if (self.activeKey === "xsbz") {
        self.xsbzClick();
      } else if (self.activeKey === "xsjg") {
        self.xsjgClick();
      } else if (self.activeKey === "sbdb") {
        self.sbdbClick();
      }
    },
    //缩小图片
    narrowImage() {
      const self = this;
      if (self.currentImageBase64 == "") {
        return;
      }
      if (this.imgScale > 0.2) {
        this.imgScale = this.imgScale - 0.1;
        //清空画布
        self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        const imgList = self.imgObject;
        const imageList = self.extraImgList;
        const iw = imageList[0].width;
        const ih = imageList[0].height;
        const local = self.calculate(iw, ih);
        for (let i = 0; i < imgList.length; i++) {
          self.ctx.drawImage(
            imgList[i].img, //规定要使用的图片
            local.px * self.imgScale,
            local.py * self.imgScale,
            local.pw * self.imgScale,
            local.ph * self.imgScale
          );
        }
        if (self.activeKey === "xsbz") {
          self.xsbzClick();
        } else if (self.activeKey === "xsjg") {
          self.xsjgClick();
        } else if (self.activeKey === "sbdb") {
          self.sbdbClick();
        }
      }
    },
    //切换标签触发事件
    tabHandleClick(e: string) {
      log.info({
        p: "f",
        a: "c",
        o: "tabHandleBtn",
      });
      const self = this;
      self.imgScale = 1;
      if (self.currentFileItem.id) {
        if (e === "xsbz") {
          this.initImage();
          this.xsbzClick();
        } else if (e === "xsyt") {
          this.xsytClick();
        } else if (e === "xsjg") {
          this.initImage();
          this.xsjgClick();
        } else if (e === "sbdb") {
          this.initImage();
          this.sbdbClick();
        }
      }
    },
    //显示标注事件
    xsbzClick() {
      const self = this;
      //获得图片在画布上自适应缩放的比例
      const scale = self.imgObject[0].width / self.extraImgList[0].width;
      /**------------------------- */
      /** 开始解析OCR JSON  */
      /**------------------------- */
      if (!self.currentFileItem.jsonResult) {
        alert("暂无识别结果，请刷新后重试！");
        return;
      }
      const jsonResult = eval("(" + self.currentFileItem.jsonResult + ")");
      //1.获取到识别状态码
      const code = jsonResult.code;
      if (code !== 0) {
        alert("识别错误！");
        return;
      }
      let runningNumber = 0;
      //2.获取到result;
      const result = jsonResult.result;
      //3.获取到result下的elems;
      const elemsList = result.elems;
      //4.遍历elems
      for (let i = 0; i < elemsList.length; i++) {
        let lines = [];
        //5.获取elems文本类型
        const elemsType = elemsList[i].type;
        //6.如果等于1表示为文本类型，等于2为单元格类型
        if (elemsType === 1) {
          lines = elemsList[i].lines;
          for (let j = 0; j < lines.length; j++) {
            runningNumber += 1;
            //.获取每一行的坐标数组
            const coords = lines[j].coords;
            //6.开始制作标注
            for (let p = 0; p < 4; p++) {
              //起始点X，Y
              let qsX = 0;
              let qsY = 0;
              //终止点X，Y
              let zzX = 0;
              let zzY = 0;
              if (p === 0) {
                //起始点X，Y
                qsX = coords[0];
                qsY = coords[1];
                //终止点X，Y
                zzX = coords[2];
                zzY = coords[3];
              } else if (p === 1) {
                //起始点X，Y
                qsX = coords[2];
                qsY = coords[3];
                //终止点X，Y
                zzX = coords[4];
                zzY = coords[5];
              } else if (p === 2) {
                //起始点X，Y
                qsX = coords[4];
                qsY = coords[5];
                //终止点X，Y
                zzX = coords[6];
                zzY = coords[7];
              } else if (p === 3) {
                //起始点X，Y
                qsX = coords[6];
                qsY = coords[7];
                //终止点X，Y
                zzX = coords[0];
                zzY = coords[1];
              }

              /**
               * 首先应该算出，当前这张图片，在本画布中，被缩放的比例，如
               * 图片宽度为：2473，高度为3502
               * 自适应后宽度为：790,高度为：1120
               * 790/2473=0.319
               * 1120/3502=0.319
               * 这时候应该将标注线下标也缩放至0.319即可。
               */
              qsX = qsX * scale;
              qsY = qsY * scale;
              //终止点X，Y
              zzX = zzX * scale;
              zzY = zzY * scale;
              //开始画标注线
              self.ctx.beginPath();

              const a = (self.imgX + qsX) * self.imgScale;

              const b = (self.imgY + qsY) * self.imgScale;

              const c = (self.imgX + zzX) * self.imgScale;

              const d = (self.imgY + zzY) * self.imgScale;

              self.ctx.moveTo(a, b);
              self.ctx.lineTo(c, d);
              self.ctx.strokeStyle = "rgba(236,49, 49)";
              self.ctx.lineWidth = 2;
              self.ctx.stroke();

              //添加上数字标记
              self.ctx.font = "20px Georgia";
              self.ctx.fillStyle = "blue";
              self.ctx.fillText(
                runningNumber,
                (self.imgX + coords[0] * scale) * self.imgScale,
                (self.imgY + coords[1] * scale) * self.imgScale
              );
            }
          }
        } else if (elemsType === 2) {
          const cells = elemsList[i].cells;
          for (let j = 0; j < cells.length; j++) {
            lines = cells[j].lines;
            for (let p = 0; p < lines.length; p++) {
              runningNumber += 1;
              //.获取每一行的坐标数组
              const coords = lines[p].coords;
              //6.开始制作标注
              for (let o = 0; o < 4; o++) {
                //起始点X，Y
                let qsX = 0;
                let qsY = 0;
                //终止点X，Y
                let zzX = 0;
                let zzY = 0;
                if (o === 0) {
                  //起始点X，Y
                  qsX = coords[0];
                  qsY = coords[1];
                  //终止点X，Y
                  zzX = coords[2];
                  zzY = coords[3];
                } else if (o === 1) {
                  //起始点X，Y
                  qsX = coords[2];
                  qsY = coords[3];
                  //终止点X，Y
                  zzX = coords[4];
                  zzY = coords[5];
                } else if (o === 2) {
                  //起始点X，Y
                  qsX = coords[4];
                  qsY = coords[5];
                  //终止点X，Y
                  zzX = coords[6];
                  zzY = coords[7];
                } else if (o === 3) {
                  //起始点X，Y
                  qsX = coords[6];
                  qsY = coords[7];
                  //终止点X，Y
                  zzX = coords[0];
                  zzY = coords[1];
                }

                /**
                 * 首先应该算出，当前这张图片，在本画布中，被缩放的比例，如
                 * 图片宽度为：2473，高度为3502
                 * 自适应后宽度为：790,高度为：1120
                 * 790/2473=0.319
                 * 1120/3502=0.319
                 * 这时候应该将标注线下标也缩放至0.319即可。
                 */
                qsX = qsX * scale;
                qsY = qsY * scale;
                //终止点X，Y
                zzX = zzX * scale;
                zzY = zzY * scale;
                //开始画标注线
                self.ctx.beginPath();

                const a = (self.imgX + qsX) * self.imgScale;

                const b = (self.imgY + qsY) * self.imgScale;

                const c = (self.imgX + zzX) * self.imgScale;

                const d = (self.imgY + zzY) * self.imgScale;

                self.ctx.moveTo(a, b);
                self.ctx.lineTo(c, d);
                self.ctx.strokeStyle = "rgba(236,49, 49)";
                self.ctx.lineWidth = 2;
                self.ctx.stroke();

                //添加上数字标记
                self.ctx.font = "20px Georgia";
                self.ctx.fillStyle = "blue";
                self.ctx.fillText(
                  runningNumber,
                  (self.imgX + coords[0] * scale) * self.imgScale,
                  (self.imgY + coords[1] * scale) * self.imgScale
                );
              }
            }
          }
        }
      }
    },
    //显示原图
    xsytClick() {
      log.info({
        p: "f",
        a: "c",
        o: "xsytBtn",
      });
      this.initImage();
    },
    //显示结果
    xsjgClick() {
      log.info({
        p: "f",
        a: "c",
        o: "xsjgBtn",
      });
      const self = this;
      self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      //获得图片在画布上自适应缩放的比例
      const scale = self.imgObject[0].width / self.extraImgList[0].width;
      /**------------------------- */
      /** 开始解析OCR JSON  */
      /**------------------------- */

      if (!self.currentFileItem.jsonResult) {
        alert("暂无识别结果，请刷新后重试！");
        return;
      }
      const jsonResult = eval("(" + self.currentFileItem.jsonResult + ")");
      //1.获取到识别状态码
      const code = jsonResult.code;
      if (code !== 0) {
        alert("识别错误！");
        return;
      }
      //2.获取到result;
      const result = jsonResult.result;
      //3.获取到result下的elems;
      const elemsList = result.elems;
      //4.遍历elems
      for (let i = 0; i < elemsList.length; i++) {
        let lines = [];
        //5.获取elems文本类型
        const elemsType = elemsList[i].type;
        //6.如果等于1表示为文本类型，等于2为单元格类型
        if (elemsType === 1) {
          lines = elemsList[i].lines;
          for (let j = 0; j < lines.length; j++) {
            //5.获取每一行的坐标数组
            const coords = lines[j].coords;
            //6.获取文本最大宽度
            let textWidth = 0;
            let text = "";
            if (lines[j]) {
              if (lines.length > 0 && lines[0].text) {
                text = lines[0].text;
                //9.获取文本最大宽度
                textWidth = coords[2] - coords[0];
              }
            }
            self.ctx.font = "30px Georgia";
            self.ctx.fillStyle = "blue";
            self.ctx.fillText(
              text,
              (self.imgX + coords[6] * scale) * self.imgScale,
              (self.imgY + coords[7] * scale) * self.imgScale,
              textWidth * scale * self.imgScale
            );
          }
        } else if (elemsType === 2) {
          const cells = elemsList[i].cells;
          for (let j = 0; j < cells.length; j++) {
            lines = cells[j].lines;
            for (let p = 0; p < lines.length; p++) {
              //5.获取每一行的坐标数组
              const coords = lines[p].coords;
              //6.获取文本最大宽度
              let textWidth = 0;
              let text = "";
              if (lines[p]) {
                if (lines.length > 0 && lines[0].text) {
                  text = lines[p].text;
                  //9.获取文本最大宽度
                  textWidth = coords[2] - coords[0];
                }
              }
              self.ctx.font = "30px Georgia";
              self.ctx.fillStyle = "blue";
              self.ctx.fillText(
                text,
                (self.imgX + coords[6] * scale) * self.imgScale,
                (self.imgY + coords[7] * scale) * self.imgScale,
                textWidth * scale * self.imgScale
              );
            }
          }
        }
      }
    },
    //识别对比
    sbdbClick() {
      log.info({
        p: "f",
        a: "c",
        o: "sbdbBtn",
      });
      const self = this;
      //获得图片在画布上自适应缩放的比例
      const scale = self.imgObject[0].width / self.extraImgList[0].width;
      /**------------------------- */
      /** 开始解析OCR JSON  */
      /**------------------------- */

      if (!self.currentFileItem.jsonResult) {
        alert("暂无识别结果，请刷新后重试！");
        return;
      }
      const jsonResult = eval("(" + self.currentFileItem.jsonResult + ")");
      //1.获取到识别状态码
      const code = jsonResult.code;
      if (code !== 0) {
        alert("识别错误！");
        return;
      }
      //2.获取到result;
      const result = jsonResult.result;
      //3.获取到result下的lines;
      const elemsList = result.elems;
      //4.遍历elems
      for (let i = 0; i < elemsList.length; i++) {
        let lines = [];
        //5.获取elems文本类型
        const elemsType = elemsList[i].type;
        //6.如果等于1表示为文本类型，等于2为单元格类型
        if (elemsType === 1) {
          lines = elemsList[i].lines;
          for (let j = 0; j < lines.length; j++) {
            //5.获取每一行的坐标数组
            const coords = lines[j].coords;
            //6.计算文本最大的宽度
            let textWidth = 0;
            //7.获取文本内容
            let text = "";
            if (lines[j]) {
              if (lines.length > 0 && lines[0].text) {
                text = lines[0].text;
                //9.获取文本最大宽度
                textWidth = coords[2] - coords[0];
              }
            }
            self.ctx.font = "50px Georgia";
            self.ctx.fillStyle = "red";
            self.ctx.fillText(
              text,
              (self.imgX + coords[6] * scale) * self.imgScale,
              (self.imgY + coords[7] * scale) * self.imgScale,
              textWidth * scale * self.imgScale
            );
          }
        } else if (elemsType === 2) {
          const cells = elemsList[i].cells;
          for (let j = 0; j < cells.length; j++) {
            lines = cells[j].lines;
            for (let p = 0; p < lines.length; p++) {
              //5.获取每一行的坐标数组
              const coords = lines[p].coords;
              //6.获取文本最大宽度
              let textWidth = 0;
              let text = "";
              if (lines[p]) {
                if (lines.length > 0 && lines[0].text) {
                  text = lines[p].text;
                  //9.获取文本最大宽度
                  textWidth = coords[2] - coords[0];
                }
              }
              self.ctx.font = "50px Georgia";
              self.ctx.fillStyle = "red";
              self.ctx.fillText(
                text,
                (self.imgX + coords[6] * scale) * self.imgScale,
                (self.imgY + coords[7] * scale) * self.imgScale,
                textWidth * scale * self.imgScale
              );
            }
          }
        }
      }
    },
    /*坐标转换*/
    windowToCanvas(x: any, y: any) {
      const box = this.myCanvas.getBoundingClientRect(); //这个方法返回一个矩形对象，包含四个属性：left、top、right和bottom。分别表示元素各边与页面上边和左边的距离
      return {
        x: x - box.left - (box.width - this.myCanvas.width) / 2,
        y: y - box.top - (box.height - this.myCanvas.height) / 2,
      };
    },
    //初始化画布上的图片
    initImage() {
      const self = this;
      //清空画布
      self.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      const imgList = self.imgObject;
      const imageList = self.extraImgList;
      const iw = imageList[0].width;
      const ih = imageList[0].height;
      const local = self.calculate(iw, ih);
      for (let i = 0; i < imgList.length; i++) {
        self.ctx.drawImage(
          imgList[i].img, //规定要使用的图片
          local.px * self.imgScale,
          local.py * self.imgScale,
          local.pw * self.imgScale,
          local.ph * self.imgScale
        );
      }
    },
    //初始化基础数据
    initData() {
      this.imgObject = <any>[];
      this.local = <any>null;
      this.imgX = 0; // 图片在画布中渲染的起点x坐标
      this.imgY = 0;
      this.imgScale = 1; // 图片的缩放大小
      this.oldImgScale = 0; //记录原始图片缩放大小
      this.oldImgX = 0; // 图片在画布中渲染的起点x坐标
      this.oldImgY = 0;
      this.extraImgList = <any>[]; //图片列表
      this.ocrTextResult = "";
      this.currentImageBase64 = "";
      this.activeKey = "xsyt";
    },
     //根据JSON生成表格
     tableRestoration: function (jsonResult: any) {
      let newHtml = ``;
      let newText = ``;
      let newTable = ``;
      /**------------------------- */
      /** 开始解析OCR JSON  */
      /**------------------------- */
      if (!jsonResult) {
        alert("暂无识别结果，请刷新后重试！");
        return;
      }
      //1.获取到识别状态码
      const code = jsonResult.code;
      if (code !== 0) {
        alert("识别错误！");
        return;
      }
      //2.获取到result;
      const result = jsonResult.result;
      //3.获取到result下的lines;
      const elemsList = result.elems;
      //4.遍历elems
      for (let i = 0; i < elemsList.length; i++) {
        let lines = [];
        //5.获取elems文本类型
        const elemsType = elemsList[i].type;
        //6.如果等于1表示为文本类型，等于2为单元格类型
        if (elemsType === 1) {
          lines = elemsList[i].lines;
          for (let j = 0; j < lines.length; j++) {
            //5.获取每一行的坐标数组
            //const coords = lines[j].coords;
            //console.log(coords);
            //7.获取文本内容
            let text = "";
            if (lines[j]) {
              if (lines.length > 0 && lines[0].text) {
                text = lines[0].text.replace(/ /g, "&nbsp;");
             
              }
            }
            newText += `${text}<br>`;

          }
        } else if (elemsType === 2) {
          const cells = elemsList[i].cells;
          for (let j = 0; j < cells.length; j++) {
            lines = cells[j].lines;
            let text = "";
            let textWidth = 0;
            let textheight = 0;
            for (let p = 0; p < lines.length; p++) {
              //5.获取每一行的坐标数组
              const coords = lines[p].coords;
              //6.获取文本最大宽度
              if (lines[p]) {
                if (lines.length > 0 && lines[0].text) {
                  text += lines[p].text;
                  //9.获取文本最大宽度
                  textWidth = coords[2] - coords[0];
                  textheight = coords[5] - coords[3];
                }
              }
            }
            if (textheight < 30) {
              newTable += ` <td 
              colspan="${cells[j].colend - cells[j].colstart}"
              width="${textWidth}" height="${30}"> 
              ${text}
              </td>`;
            } else {
              newTable += ` <td 
              colspan="${cells[j].colend - cells[j].colstart}"
              width="${textWidth}" height="${textheight}">
              ${text}
              </td>`;
            }
            const currentRowNumber = cells[j].rowstart;
            if (cells.length === 1) {
              if (currentRowNumber != undefined) {
                newTable = ` <tr>` + newTable + `</tr>`;
              }
            } else if (j === cells.length - 1) {
              const previousRowNumber = cells[j - 1].rowstart;
              if (
                currentRowNumber != undefined &&
                previousRowNumber != undefined
              ) {
                if (currentRowNumber != previousRowNumber) {
                  newTable = ` <tr>` + newTable + `</tr>`;
                }
              }
            } else {
              const nextRowNumber = cells[j + 1].rowstart;
              if (currentRowNumber != undefined && nextRowNumber != undefined) {
                if (currentRowNumber != nextRowNumber) {
                  newTable = ` <tr>` + newTable + `</tr>`;
                }
              }
            }
          }
        }
      }
      //1.获取html节点
      const root = document.getElementById("table-ocr-result");
      if (root) {
        newTable = `<table border="1"> ` + newTable + ` </table>`;
        if (elemsList.length > 0) {
          if (elemsList[0].type === 1) {
            newHtml = `${newText} ${newTable}`;
          } else if (elemsList[0].type === 2) {
            newHtml = `${newTable} ${newText} `;
          }
        }
        root.innerHTML = newHtml;
      }
    },
    //下载oss操作
    downByOss(type: any){
      if (type && this.currentFileItem.id) {
        let aliyunOSS = new AliyunOSS();
        const service: OcrFileService = new OcrFileService(
          this.$router
        );
        //获取阿里云OSS链接参数。
        service.getAliyunOSSPolicy((data) => {
          aliyunOSS = data;
        });
        const obj = {
          data: this.currentFileItem,
          type,
          aliyunOSS,
        };
        service.downloadFileById(obj, (data) => {
          const a = document.createElement("a");
          a.href = data;
          a.download = this.currentFileItem.id;
          a.click();
        });
      } else {
        this.currentImageBase64 = "";
      }
    },
    //下载文件
    downOcrFile(type: any){
      const baseUrl = process.env.VUE_APP_API_URL;
      let fileName = "";
      if(this.currentFileItem.fileType=="pdf" || this.currentFileItem.fileType == "tif" || this.currentFileItem.fileType == "tiff"){
        fileName = this.currentFileItem.fileName + "_m"
        if(type == "d-pdf"){
          fileName = this.currentFileItem.fileName + "_m_d"
          type = "pdf"
        }
      }else{
        fileName = this.currentFileItem.fileName
        if(type == "d-pdf"){
          fileName = this.currentFileItem.fileName + "_d"
          type = "pdf"
        }
      }
      const filepath =
      this.currentFileItem.filePath + fileName + "." + type;
      const link = document.createElement("a") as any;
      console.log("下载路径",filepath)
      link.href = `${baseUrl}ocr-convert/common/download?filepath=${filepath}`;
      link.click();
      message.success("下载成功");
    },
    repeatCheck: function (id: any, type: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const service: OcrFileService = new OcrFileService(this.$router);
      service.repeatCheck({
        type: type,
        repeatCheck: id,
        onSuccess: (resp) => {
          if (resp.state == true) {
            Modal.confirm({
              title: "提示",
              content: `需要消耗${resp.deductedPoint}积分确认下载这个${type}吗`,
              okText: "确认",
              cancelText: "取消",
              onOk: () => {
                this.deduct(id,type);
              },
            });
          }else{
            this.deduct(id,type);
          }
        },
        onError() {
          message.error("顺序调整失败 ");
        },
      });
    },
    deduct: function (id: any, type: string) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const service: OcrFileService = new OcrFileService(this.$router);
      service.downloadDeduct({
        id: id,
        type: type,
        onSuccess: (resp) => {
          if(resp.state == true){
            this.downOcrFile(type);
          }else{
            message.error("积分不足");
          }
        },
        onError() {
          message.error("顺序调整失败 ");
        },
      });
    },
    //下载文件
    downloadFile(type: string) {
      log.info({
        p: "f",
        a: "c",
        o: "downloadFileBtn",
      });
      if(this.status == 0){
        Modal.confirm({
          title: "提示",
          content: `检测到您未登录，是否去登录！`,
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            this.$router.push("/login");
          },
        });
        return;
      }
      if (this.currentNodeKey == 0) {
        message.error("请选择要下载得文件");
        return;
      }
      this.repeatCheck(this.currentNodeKey, type);
      
    },
    //无痕模式切换
    updateNoTraceMode(e: any) {
      log.info({
        p: "f",
        a: "c",
        o: "updateNoTraceModeBtn",
      });
      this.noTraceModeStatus = e;
      if (e) {
        sessionStorage.setItem("isTracelessMode", "true");
        message.success("已切换至无痕模式，可安心查阅!");
      } else {
        sessionStorage.setItem("isTracelessMode", "false");
        message.success("已切换至普通模式!");
      }
    },
    // updateMemo() {
    //   if (this.memo.length == 0) {
    //     message.success("备注不能为空!");
    //     return
    //   }
    //   const params = {
    //     id: this.currentFileItem.id,
    //     memo: this.memo,
    //   }
    //   const service: OcrFileService = new OcrFileService(this.$router);
    //   service.updateComment(params, (data) => {
    //     console.log(data);
    //     this.getFileList();
    //     message.success("修改成功!");
    //   });
    // },
    handleDrop: (e: DragEvent) => {
      console.log(e);
    },
    //上传
    handleChange(file: any){
       console.log(file);
    },
    //自定义上传文件
    beforeRead: function (file: any) {
      const self = this;
      log.info({
        p: "f",
        a: "c",
        o: "customUploadBtn ",
      });
      const disallowedTypes = ["jpeg", "jpg", "tif", "png", "pdf", "gif"];
      // 存储不允许上传的文件后缀
      const disallowedExtensions = [];
      if (file.length == undefined) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        // 判断文件扩展名是否在不允许上传的文件类型数组中
        if (disallowedTypes.indexOf(fileExtension) == -1) {
          // 文件类型不允许，阻止上传
          message.error(`暂不支持上传${fileExtension}文件格式`);
          return false;
        }
      } else {
        for (let i = 0; i < file.length; i++) {
          const list = file[i];
          // 获取文件的扩展名
          const fileExtension = list.name.split(".").pop().toLowerCase();
          // 判断文件扩展名是否在不允许上传的文件类型数组中
          if (disallowedTypes.indexOf(fileExtension) == -1) {
            // 文件类型不允许，阻止上传
            disallowedExtensions.push(fileExtension);
          }
        }

        // 判断是否有不允许上传的文件后缀
        if (disallowedExtensions.length > 0) {
          // 将不允许上传的文件后缀显示出来
          const disallowedExtensionsString = disallowedExtensions.join(", ");
          message.error(`暂不支持上传${disallowedExtensionsString}文件格式`);
          return false;
        }
      }
      const formData = new FormData();
      if (file.length == undefined) {
        formData.append("file", file);
        const ocrFile = {
          ocrStatus: 0,
          memo:
            file.name.length > 10
              ? file.name.substring(0, 8) + "..."
              : file.name,
          uploadStatus: 0,
          progress: 0,
          key: 1,
        };
        this.list.unshift(ocrFile);
      } else {
        for (let i = 0; i < file.length; i++) {
          formData.append("file", file[i]);
          const ocrFile = {
            ocrStatus: 0,
            memo: file[i].name
              ? file[i].name.substring(0, 8) + "..."
              : file[i].name,
            uploadStatus: 0,
            progress: 0,
          };
          this.list.unshift(ocrFile);
        }
      }
      // 模拟上传进度递增
      let progress = 0;
      const timer = setInterval(() => {
        if (progress < 99) {
          // 只有进度小于99时才自增
          progress++;
        }
        this.list.forEach((item: any) => {
          if (item.progress !== 100) {
            item.progress = progress;
          }
        });
      }, 50);

      const baseUrl = process.env.VUE_APP_API_URL;
      this.ticket = new TicketService().get() as any;
      axios
        .post(`${baseUrl}ocr/file/fileUpload`, formData, {
          headers: {
            apiVersion: "0.1.0",
            ticket: this.ticket,
          },
        })
        .then((response) => {
         
          this.ids = response.data;
          this.superiorId = this.ids[0].id
          clearInterval(timer); // 清除计时器
          message.success("上传成功");
          this.current = 1;
          this.getFileListOcr();
         })
        .catch((error) => {
          console.error(error);
          // 处理上传失败的代码
          setTimeout(() => {
            clearInterval(timer);
            // 清除完成后的操作
          }, 1000);
          this.current = -1;
          message.error("上传失败");
          setTimeout(() => {
            clearInterval(timer);
            // 清除完成后的操作
          }, 1000);
        });
    },
    //启动轮询
    checkStatus() {
      let hasLoadingData = false;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].ocrStatus === 0) {
          hasLoadingData = true;
        }
      }
      if (hasLoadingData) {
        if (this.ocrCount < 200) {
          // 检查轮询次数
          this.ocrCount++; // 轮询次数加一
          this.timer = setTimeout(() => {
            this.init();
          }, 2000);
        } else {
          clearTimeout(this.timer);
          this.timer = 0;
        }
      }
    },
    init() {
      const service: OcrFileService = new OcrFileService(this.$router);
      for (let i = 0; i < this.ids.length; i++) {
        service.getFileById(this.ids[i], (data) => {
          for (let j = 0; i < this.list.length; j++) {
            if (this.list[j].id == this.ids[i]) {
              // 找到了匹配的对象
              this.list[j] = data;
            }
          }
        });
      }
      if(this.list){
        this.checkStatus();
      }
      
    },
    uploadFile: function (fileInfo: any, filePolicy: any) {
      const self = this;
      self.fileList = [];
      const service: FilePolicyService = new FilePolicyService(this.$router);
      service.uploadFile(
        {
          ...filePolicy,
          onSuccess: (resp) => {
            this.fileid = resp.id;
            this.ids.unshift(filePolicy.id);
            message.success(`文件'${fileInfo.file.name}' 上传成功`);
            const service: OcrFileService = new OcrFileService(this.$router);
            service.getFileById(filePolicy.id, (data) => {
              this.currentFileItem = data;
              this.list.unshift(data);
              this.ocrCount = 0;
              this.init();
            });
          },
          onGetFilePolicyError(resp: any) {
            self.fileList = [];
            message.error(`文件'${fileInfo.file.name}' 上传失败！`);
          },
        },
        fileInfo
      );
    },
    // 禁用默认拖拽事件
    disableDefaultDragEvents: function () {
      const doc = document.documentElement;
      doc.addEventListener("dragleave", (e) => e.preventDefault()); //拖离
      doc.addEventListener("drop", (e) => e.preventDefault()); //拖后放
      doc.addEventListener("dragenter", (e) => e.preventDefault()); //拖进
      doc.addEventListener("dragover", (e) => e.preventDefault()); //拖来拖去
    },
    //注册全局拖拽事件
    dragListener: function () {
      const that = this;
      const canvas = document.getElementById('canvas') as any;
      // 阻止浏览器默认行为，使其支持拖放操作
      canvas.addEventListener('dragover', function(e: any) {
        e.preventDefault();
      }.bind(this)); 
      canvas.addEventListener('drop', function(e: any) {
        e.preventDefault();
        console.log(e.dataTransfer.files)
        const file = e.dataTransfer.files;
        if(file){
          that.stepShow = true,
          that.current = 0,
          that.beforeRead(file);
        }
      }.bind(this));
      
    },
    //检查文件大小
    checkSize: function (file: any) {
      const maxSize = 10 * 1024 * 1024;
      const sizeInMB = file.size / (1024 * 1024);
      if (sizeInMB > maxSize) {
        message.error(`文件大小不得超过10MB`);
        return;
      }
    },
    // 检查文件类型
    checkType: function (file: any) {
      const { type, name } = file;
      const accept = ".png, .jpg, .tif,.pdf,.gif";
      if (accept.length === 0) return true;
      const extension =
        name.indexOf(".") > -1 ? `.${name.split(".").pop()}` : "";
      const baseType = type.replace(/\/.*$/, "");
      return accept
        .split(",")
        .map((type) => type.trim())
        .filter((type) => type)
        .some((acceptedType) => {
          if (/\..+$/.test(acceptedType)) {
            return extension === acceptedType;
          }
          if (/\/\*$/.test(acceptedType)) {
            return baseType === acceptedType.replace(/\/\*$/, "");
          }
          if (/^[^/]+\/[^/]+$/.test(acceptedType)) {
            return type === acceptedType;
          }
        });
    },
    //点击左侧图片树，切换图片信息
    //点击左侧图片树，切换图片信息
    treeHandleClick(
      e: number,
      info: {node: any;}
    ) {
      if(info.node.fileType == 'pdf' || info.node.fileType == 'tif' || info.node.fileType == 'tiff' || info.node.type == 1){
        //this.superiorId = info.node.superiorId;
        this.currentImageBase64 = ""
      }else{
        this.initData();
        this.getImageById(info.node.key);
      }
      if (this.isLoading) {
        return;
      }
     if(info.node.type == 1 && info.node.ocrStatus == 0 && info.node.splitStatus == null){
         //this.getFileListByInterval();
         const memo = info.node.memo;
         this.superiorId = info.node.superiorId;
         const range = memo.split('-');
         const start = parseInt(range[0]);
         const end = parseInt(range[1]);
         if(start==1 && end == 10){
            return;
         }
         // 标记为正在加载中
         this.isLoading = true;
         this.getFileListByInterval(info.node.superiorId,start,end);
      }else{
        this.currentNodeKey = info.node.key;
        this.currentId = info.node.nodeId;
        this.currentNodeKey = info.node.key;
        
        if(info.node.ocrStatus == 1){
          this.getFileById(info.node.key);
        }
      }
    },

    //根据区间
    getFileListByInterval(id: number,start: number,end: number) {
      const self = this;
      const service: OcrFileService = new OcrFileService(this.$router);
      service.getFileListByInterval({
        id: id,
        startPage: start,
        endPage: end,
        onSuccess: (resp: any) => {
          this.stepShow = true;
          this.current = 1;
          this.superStatus = 0;
          this.getFileListOcr();
        },
        onError() {
          self.isLoading = false;
        },
      });
    },

    handleExpand(keys: any, { expanded, node }: any) {
      const tempKeys = ((node.parent ? node.parent.children : this.list) || []).map(({ key }: {key: any}) => key);
      if (expanded) {
        this.expandedKeys = difference(keys, tempKeys).concat(node.key);
      } else {
        this.expandedKeys = keys;
      }
    },
  },
});
