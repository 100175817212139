import { createStore } from 'vuex';

export default createStore({
  state: {
    language: null, // 语言
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    updateRole({ commit }, newLanguage) {
      commit('setLanguage', newLanguage);
    },
  },
  getters: {
    getLanguage: (state) => {
      return state.language;
    },
  }
});
