import axios from "axios";
import { User } from "../entity/User";
import { BaseService } from "./BaseService";
import { FilePolicyRequest } from "../entity/FilePolicyRequest";
import { TicketService } from "../service/TicketService";
import { FileUploadRequest } from "../entity/FileUploadRequest";
import  log  from "./log";
export class FilePolicyService extends BaseService<User> {
  protected getUrl(): string {
    return "aliyun/oss/uploadPolicy";
  }
  getAliyunOSSParameter(filePolicyRequest: FilePolicyRequest) {
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `https://web-api.filequan.com/aliyun/oss/uploadPolicy`,
      method: "POST",
      // responseType: "blob",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        fileType: filePolicyRequest.fileType,
        noTraceMode: filePolicyRequest.noTraceMode,
        md5: filePolicyRequest.md5,
        size: filePolicyRequest.size,
      },
    })
      .then((response) => {
        filePolicyRequest.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "f",
            a: "e",
          });
          filePolicyRequest.onGetFilePolicyError(err.response.data);
        }
      });
  }
  getPolicy(filePolicyRequest: FilePolicyRequest) {
    axios({
      //url: `${baseUrl}aliyun/oss/uploadPolicy`,
      url: `https://web-api.filequan.com/aliyun/oss/uploadPolicy`,
     
      method: "POST",
      // responseType: "blob",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        apiVersion: "0.1.0",
        ticket: "2bee3512ff9a411abe8ed58fc4062914",
      },
      data: {
        memo: filePolicyRequest.memo,
        fileType: filePolicyRequest.fileType,
        noTraceMode: filePolicyRequest.noTraceMode,
        md5: filePolicyRequest.md5,
        size: filePolicyRequest.size,
      },
    })
      .then((response) => {
        filePolicyRequest.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "f",
            a: "e",
          });
          filePolicyRequest.onGetFilePolicyError(err.response.data);
        }
      });
  }
  uploadFile(fileUploadRequest: FileUploadRequest, fileInfo: any) {
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `${fileUploadRequest.host}`,
      method: "POST",
      // responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        name: fileUploadRequest.fileName,
        key: fileUploadRequest.dir + "" + fileUploadRequest.fileName,
        OSSAccessKeyId: fileUploadRequest.accessid,
        callback: fileUploadRequest.callback,
        dir: fileUploadRequest.dir,
        expire: fileUploadRequest.expire,
        host: fileUploadRequest.host,
        policy: fileUploadRequest.policy,
        signature: fileUploadRequest.signature,
        file: fileInfo.file,
      },
    })
      .then((response) => {
        fileUploadRequest.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "f",
            a: "e",
          });
          fileUploadRequest.onUploadFileError(err.response.data);
        }
      });
  }
}
