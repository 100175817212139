import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "usbkey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_result, {
      status: "success",
      title: "付款完成",
      "sub-title": "实付 0.01元"
    }, {
      extra: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "console",
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("查看订单")
          ]),
          _: 1
        }),
        _createVNode(_component_a_button, { key: "buy" }, {
          default: _withCtx(() => [
            _createTextVNode("去文档圈首页")
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}