import { defineComponent } from "vue";
import log from "./../../service/log";
import { LeftOutlined } from "@ant-design/icons-vue";
import type { FormInstance } from "ant-design-vue";
import { message } from "ant-design-vue";
import { ResourceService } from "./../../service/ResourceService";
import { SmsCodeService } from "@/service/SmsCodeService";
import { UserService } from "@/service/UserService";
import { TicketService } from "@/service/TicketService";
import { OrderService } from "./../../service/OrderService";
import Clipboard from "clipboard";
export default defineComponent({
  components: {
    LeftOutlined,
  },
  data() {
    return {
      membershipCode: "",
      startTime: Date.now(),
      modalVisible: true,
      modalRecharge: false,
      modalCode: true,

      form: {
        phoneNumber: "",
        verifyCode: "",
      },
      countDown: 0,
      isFirstIntercept: true,
      isFirstInterceptTwo: true,
      visible: false,
      labelCol: { style: { width: "60px" } },

      items: <any>[],
      price: 0, //商品价格
      amount: 0, //商品数量 -- 会员天数
      goodId: 0, //商品id
      qcodeUrl: "", //微信支付二维码
      payVisible: false, // 支付页面
      backendPage: "", // 支付宝页面
      aliPayVisible: false, // 支付宝

      orderId: 0,

      checked: 0,
      way: true,

      vipCode: <any>"",

      clipboard: <any>null,

      inviteCode: <any>"",
      meachCodeInput: false,

      disabled: false
    };
  },
  mounted() {
    log.info({
      p: "a",
      a: "m",
    });

    this.showModal(); // 在组件挂载后显示模态框
    this.getCode();
    // 初始化Clipboard
    this.clipboard = new Clipboard(".copy");
    // 监听复制成功的事件
    this.clipboard.on("success", this.handleCopySuccess);
    // 监听复制失败的事件
    this.clipboard.on("error", this.handleCopyError);
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "a",
      a: "u",
      d: stayTime,
    });
  },
  name: "ApplyFormView",

  methods: {
    getCode() {
      const code = this.$route.params.code;
      if (code != "pay") {
        this.inviteCode = code;
        this.meachCodeInput = true;
      } else {
        this.meachCodeInput = false;
      }
    },
    handleCopySuccess() {
      message.success("复制成功");
      // 复制成功的逻辑处理
    },
    handleCopyError() {
      message.error("复制失败");
      // 复制失败的逻辑处理
    },
    //立即续费
    renewal() {
      if (this.inviteCode == "") {
        message.warning("请填写机器码");
        return;
      }
      const ticketService = new TicketService();
      const ticket = ticketService.get();
      if (ticket) {
        this.modalCode = true;
        this.modalVisible = false;
        this.modalRecharge = true;
        this.way = true;
        this.getProduct();
      } else {
        this.modalCode = false;
        message.warning("请先登录");
      }
    },
    //获取兑换码
    getexchangeCode() {
      if (this.inviteCode == "") {
        message.warning("请填写机器码");
        return;
      }
      const ticketService = new TicketService();
      const ticket = ticketService.get();
      if (ticket) {
        this.modalCode = true;
        this.modalVisible = false;
        this.modalRecharge = true;
        this.way = false;
        this.GetmembershipCode();
      } else {
        this.modalCode = false;
        message.warning("请先登录");
      }
    },

    sendcode: function (phone: any) {
      const service: SmsCodeService = new SmsCodeService(this.$router);
      service.send(phone).then(() => {
        message.success("验证码已发送，请注意查收");
        this.countDown = 60; // 设置倒计时初始值为60秒

        // 启动计时器，每秒减少1秒
        const timer = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--;
          } else {
            clearInterval(timer); // 计时结束时清除计时器
          }
        }, 1000);
      }).catch(()=>{
        message.error("验证码发送频繁，请稍后再试");
      });
    },
    login: function () {
      const service: UserService = new UserService(this.$router);
      service.login({
        phoneNumber: this.form.phoneNumber,
        verifyCode: this.form.verifyCode,
        onSuccess: (resp) => {
          new TicketService().set(resp.ticket);
          new UserService(this.$router).setUserStatus("1");
          message.success("登录成功");
          this.modalRecharge = true;
          this.getProduct()
        },
        onVerifyCodeError() {
          message.error("验证码错误");
        },
      });
    },
    //捕获邀请链接参数
    showModal() {
      log.info({
        p: "a",
        a: "c",
        o: "showModalbtnClick",
      });
      this.modalVisible = true; // 打开模态框
    },
    handleCancel() {
      log.info({
        p: "a",
        a: "c",
        o: "handleCancelbtnClick",
      });
      // 返回上一页面的逻辑
      this.$router.push("/"); // 使用Vue Router返回上一页面
    },
    handleCloseModal() {
      this.modalRecharge = false;
      this.modalVisible = true;
    },
    handleOk() {
      log.info({
        p: "a",
        a: "c",
        o: "handleOkbtnClick",
      });
      (this.$refs.form as FormInstance)
        .validate()
        .then(() => {
          // 表单验证通过
          // 执行确认按钮的逻辑
          // ...
          this.modalVisible = false;
        })
        .catch(() => {
          // 表单验证不通过
          // 处理错误消息或其他逻辑
          // ...
        });
    },

    checkPhone(value: string) {
      if (value && this.form.phoneNumber.length > 0) {
        // 修改校验规则，要求手机号必须是11位数字，且以1开头
        const pattern = /^1[3456789]\d{9}$/;
        if (value && pattern.test(this.form.phoneNumber)) {
          this.disabled = false;
          return Promise.resolve("成功");
        } else {
          this.disabled = true;
          return Promise.reject("手机号格式不正确");
        }
      } else {
        this.disabled = true;
        return Promise.reject("请输入手机号码");
      }
    },
    handleInput(event: { target: { value: any } }) {
      const phone = event.target.value;
      this.checkPhone(phone);
    },
    sendCode() {
      log.info({
        p: "a",
        a: "c",
        o: "sendCodebtnClick",
      });
      // 发送验证码逻辑
      const phone = this.form.phoneNumber;
      this.sendcode(phone);
    },
    onFinish() {
      log.info({
        p: "a",
        a: "c",
        o: "handleSubmitbtnClick",
      });
      this.login();
    },
    handleExit() {
      log.info({
        p: "a",
        a: "c",
        o: "handleExitbtnClick",
      });
      if (this.isFirstIntercept) {
        this.isFirstIntercept = false;
        this.modalVisible = true;
        this.visible = true;
      } else {
        this.modalVisible = false;
      }
    },
    handleExitTwo() {
      log.info({
        p: "a",
        a: "c",
        o: "handleExitbtnClick",
      });
      if (this.isFirstInterceptTwo) {
        this.isFirstInterceptTwo = false;
        this.modalRecharge = true;
        this.visible = true;
      } else {
        this.modalRecharge = false;
      }
    },
    crjj() {
      log.info({
        p: "a",
        a: "c",
        o: "crjjbtnClick",
      });
      this.visible = false;
    },
    xzjq() {
      this.$router.push("/");
    },
    handleClick(index: number) {
      // 隐藏所有 div 的阴影效果
      this.items.forEach((item: { showShadow: boolean }) => {
        item.showShadow = false;
      });

      // 显示当前点击的 div 的阴影效果
      this.items[index].showShadow = true;
      this.price = this.items[index].price;
      this.amount = this.items[index].amount;
      this.goodId = this.items[index].id;

      // 这里可以添加其他点击 div 后的逻辑处理
    },

    getProduct: function () {
      const service: ResourceService = new ResourceService(this.$router);
      service.getProduct({
        resourceTypeId: 2,
        onSuccess: (resp) => {
          resp.content.forEach((item: any) => {
            // 使用解构赋值和扩展操作符来添加新属性并设置默认值
            Reflect.set(item, "showShadow", false);
          });

          this.items = resp.content;
          console.log(this.items);
          for (let i = 0; i < this.items.length; i++) {
            const element = this.items[i];
            if(element.amount == 30){
              element.origin = 90
            }else if(element.amount == 90){
              element.origin = 240
            }else if(element.amount == 365){
              element.origin = 840
            }
              
          }
        },
        onError() {
          console.log("列表获取失败");
        },
      });
    },
    //30天90   90天240   365天840
    //创建订单
    createOrder: function (payType: number) {
      const service: OrderService = new OrderService(this.$router);
      service.addOrder({
        orderName: "购买会员",
        orderAmount: this.price,
        orderType: 1,
        orderNo: this.amount,
        naturalId: this.goodId,
        onSuccess: (resp) => {
          if (payType == 0) {
            this.orderId = resp.orderNumber;
            const baseUrl = process.env.VUE_APP_API_URL;
            this.qcodeUrl = `${baseUrl}wechat/pay?orderId=${resp.id}`;
            this.payVisible = true;
            this.getDesc();
          } else if (payType == 1) {
            this.getAlipay(resp.id);
            this.getDesc();
          }
        },
        onError() {
          console.log("订单创建失败");
        },
      });
    },
    //微信支付接口
    findOrder: function (id: number) {
      console.log(id);
      const service: OrderService = new OrderService(this.$router);
      service.findOrder({
        orderNumber: id,
        onSuccess: (resp) => {
          //this.vipCode = resp;
          this.payVisible = false;
          this.modalRecharge = false;
          this.modalVisible = true;
        },
        onError() {
          message.error("先扫码支付")
        },
      });
    },
    paySuccess() {
      this.findOrder(this.orderId);
    },
    //会员验证
    member: function () {
      const service: OrderService = new OrderService(this.$router);
      service.member({
        day: this.amount,
        machineCode: this.inviteCode,
        onSuccess: (resp) => {
          console.log(resp);
        },
        onError() {
          console.log("会员验证失败");
        },
      });
    },
    getFirstPay: function () {
      const service: OrderService = new OrderService(this.$router);
      service.getFirstPay({
        onSuccess: (resp) => {
          if (resp == 0) {
            console.log("过滤首充数据");
          }
        },
        onError() {
          console.log("判断失败");
        },
      });
    },
    //获取会员兑换码
    getDesc: function () {
      const service: OrderService = new OrderService(this.$router);
      service.getDesc({
        onSuccess: (resp) => {
          this.vipCode = resp.callbackMap;
        },
        onError() {
          console.log("会员码获取失败");
        },
      });
    },

    //直接点击获取按钮
    GetmembershipCode: function () {
      const service: OrderService = new OrderService(this.$router);
      service.GetmembershipCode({
        onSuccess: (resp) => {
          if (resp == 0) {
            this.membershipCode = "已过期";
          } else if (resp == 1) {
            this.membershipCode = "暂未购买兑换码";
          } else {
            this.membershipCode = resp.formatSerialNumber;
          }
        },
        onError() {
          console.log("会员码获取失败");
        },
      });
    },

    //支付宝接口
    getAlipay: function (id: number) {
      const service: OrderService = new OrderService(this.$router);
      service.aliPay({
        orderId: id,
        onSuccess: (resp) => {
          this.backendPage = resp;
          this.aliPayVisible = true;
        },
        onError() {
          console.log("列表获取失败");
        },
      });
    },

    //支付
    pay() {
      if (this.price == 0) {
        message.warning("请您先选择要购买的会员商品");
        return;
      }
      this.member();
      this.createOrder(this.checked);
    },
  },
});
