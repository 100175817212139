/* eslint-disable @typescript-eslint/no-this-alias */
import footerComponent from "@/components/footer/FooterComponent.vue";
import { SmsCodeService } from "@/service/SmsCodeService";
import { InfoCircleOutlined, MobileOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
import { LoginModel } from "./../../entity/pagemodel/LoginModel";
import { TicketService } from "./../../service/TicketService";
import { UserService } from "./../../service/UserService";
import log from "./../../service/log";

export default defineComponent({
  data() {
    return {
      ...new LoginModel(),
      startTime: Date.now(),
      messageType: '',
    };
  },
  mounted() {
    log.info({
      p: "l",
      a: "m",
    });
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "l",
      a: "u",
      d: stayTime,
    });
  },
  components: {
    MobileOutlined,
    InfoCircleOutlined,
    footerComponent,
  },
  methods: {
    sendVerifyCode: function () {
      log.info({
        p: "l",
        a: "c",
      });
      this.resend = true;
      const service: SmsCodeService = new SmsCodeService(this.$router);
      service.send(this.phoneNumber).then(() => {
        this.messageType = 'success';
        //this.message = "发送成功";
      });
      this.wait = 60;
      const self = this;
      const readSeconds = function () {
        if (self.wait && self.wait > 0) {
          self.wait--;
          setTimeout(readSeconds, 1000);
        }
        if (self.wait && self.wait <= 0) {
          self.wait = undefined;
        }
      };
      readSeconds();
      const verifyCodeInput = document.querySelector(
        "#verifyCode"
      ) as HTMLInputElement;
      verifyCodeInput.focus();
    },
    login: function () {
      log.info({
        p: "l",
        a: "c",
      });
      const self = this;
      const service: UserService = new UserService(this.$router);
      service.login({
        phoneNumber: this.phoneNumber,
        verifyCode: this.verifyCode,
        onSuccess: (resp) => {
          //self.message = "登录成功";
          this.messageType = 'loginSuccess';
          new TicketService().set(resp.ticket);
          new UserService(this.$router).setUserStatus("1");
          this.$router.push("/");
        },
        onVerifyCodeError() {
          log.error({
            p: "l",
            a: "e",
          });
          self.messageType = 'invalidCode';
          //self.message = "验证码错误";
          self.verifyCode = "";
        },
      });
    },
  },
});
