import { groupUser } from "./../entity/groupUser";
import { groupUserRequest } from "./../entity/groupUserRequest";
import { BaseService } from "./BaseService";
import log from "./log";
export class groupUserSrevice extends BaseService<groupUser> {
  protected getUrl(): string {
    return "groupUser";
  }
  getGroupUser(groupUserReq: groupUserRequest) {
    return this.get(`groupUser/${groupUserReq.groupAccountId}`, {
      pageNo: groupUserReq.pageNo,
      pageSize: groupUserReq.pageSize,
      searchType: groupUserReq.searchType,
      searchContext: groupUserReq.searchContext,
      type: groupUserReq.type,
      phoneNumber: groupUserReq.userPhoneNumber,
    })
      .then(function (response) {
        log.info({
          p: "g",
          a: "r",
          o: "getGroupUser"
        });
        groupUserReq.onSuccess(response.data);
      })
      .catch(function () {
        log.error({
          p: "g",
          a: "e",
          o: "getGroupUser"
        });
        groupUserReq.onError();
      });
  }
  AddGroupUser(groupUserReq: groupUserRequest) {
    return this.post("groupUser", {
      groupAccountId: groupUserReq.groupAccountId,
      ...groupUserReq,
    })
      .then(function (response) {
        log.info({
          p: "g",
          a: "r",
          o: "AddGroupUser"
        });
        groupUserReq.onSuccess(response.data);
      })
      .catch(function () {
        log.error({
          p: "g",
          a: "e",
          o: "AddGroupUser"
        });
        groupUserReq.onError();
      });
  }
  DelGroupUser(groupUserReq: groupUserRequest) {
    return this.delete(`groupUser/${groupUserReq.id}`, {})
      .then(function (response) {
        log.info({
          p: "g",
          a: "r",
          o: "DelGroupUser"
        });
        groupUserReq.onSuccess(response.data);
      })
      .catch(function () {
        log.error({
          p: "g",
          a: "e",
          o: "DelGroupUser"
        });
        groupUserReq.onError();
      });
  }
  groupInviteLink(groupUserReq: groupUserRequest) {
    return this.get(`groupInviteLink/${groupUserReq.id}`, {})
      .then(function (response) {
        log.info({
          p: "g",
          a: "r",
          o: "groupInviteLink"
        });
        groupUserReq.onSuccess(response.data);
      })
      .catch(function () {
        log.error({
          p: "g",
          a: "e",
          o: "groupInviteLink"
        });
        groupUserReq.onError();
      });
  }
}
