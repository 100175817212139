import ProfileView from "@/views/profile/ProfileView.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import WelcomeComponent from "../components/welcome/WelcomeComponent.vue";
import homeView from "../views/home/HomeView.vue";
import LoginView from "../views/login/LoginView.vue";
import OpenApiView from "../views/open-api/OpenApiView.vue";
import FullTextOcrView from "../views/full-text-ocr/FullTextOcrView.vue";
import TableOcrView from "../views/table-ocr/TableOcrView.vue";
import TablePumpView from "../views/table-pump/TablePumpView.vue";
import UsbKeyView from "../views/usbKey/UsbKeyView.vue";
import ApplyFormView from "../views/applyform/ApplyFormView.vue";
import groupUserView from "../views/groupUser/groupUserView.vue";
import ConvertPdfView from "../views/convert-pdf/ConvertPdfView.vue";
import ConvertOfdView from "../views/convert-ofd/ConvertOfdView.vue";
import AccountView from "../views/account/AccountView.vue";
import UserView from "../views/user/UserView.vue";
import MissionView from "../views/mission/MissionView.vue";
import SignView from "../views/sign/SignView.vue";
import InviteView from "../views/invite/InviteView.vue";
import PurchaseView from "../views/purchase/PurchaseView.vue";
import medalView from "../views/medal/medalView.vue";
import MessageView from "../views/message/MessageView.vue";
import ConvertImageView from "@/views/convert-image/ConvertImageView.vue";
import RechargeView from "../views/recharge/RechargeView.vue";
import BillView from "../views/bill/BillView.vue";
import OrdersView from "../views/orders/OrdersView.vue";
import PaysuccessView from "../views/paysuccess/PaysuccessView.vue";
import IntelligentInputSystemView from "../views/intelligent-input-system/IntelligentInputSystemView.vue";
import FullTextNlpView from "../views/full-text-nlp/FullTextNlpView.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "welcome",
    component: WelcomeComponent,
    children: [
      {
        path: "/",
        name: "home",
        component: homeView,
      },
      {
        path: "/",
        name: "home",
        component: homeView,
      },
      {
        path: "/open-api",
        name: "open-api",
        component: OpenApiView,
      },
      {
        path: "/full-text-ocr",
        name: "full-text-ocr",
        component: FullTextOcrView,
      },
      {
        path: "/table-ocr",
        name: "table-ocr",
        component: TableOcrView,
      },
      {
        path: "/car-ocr",
        name: "car-ocr",
        component: FullTextOcrView,
      },
      {
        path: "/table-pump",
        name: "table-pump",
        component: TablePumpView,
      },
      {
        path: "/convert-pdf",
        name: "convert-pdf",
        component: ConvertPdfView,
      },

      {
        path: "/convert-image",
        name: "convert-image",
        component: ConvertImageView,
      },
      {
        path: "/convert-ofd",
        name: "convert-ofd",
        component: ConvertOfdView,
      },
      {
        path: "/intelligent-input-system",
        name: "intelligent-input-system",
        component: IntelligentInputSystemView,
      },
      {
        path: "/full-text-nlp",
        name: "full-text-nlp",
        component: FullTextNlpView,
      },
      {
        path: "/usb-key",
        name: "usbKey",
        component: UsbKeyView,
      },
      {
        path: "/message",
        name: "message",
        component: MessageView,
      },
      {
        path: "/account",
        name: "account",
        component: AccountView,
        children: [
          {
            path: "/account/user",
            name: "user",
            component: UserView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/account/medal",
            name: "medal",
            component: medalView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/account/mission",
            name: "mission",
            component: MissionView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/account/sign",
            name: "sign",
            component: SignView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/account/invite",
            name: "invite",
            component: InviteView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/account/purchase",
            name: "purchase",
            component: PurchaseView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/account/bill",
            name: "bill",
            component: BillView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/account/orders",
            name: "orders",
            component: OrdersView,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "/account/paysuccess",
            name: "paysuccess",
            component: PaysuccessView,
            meta: {
              requiresAuth: true,
            },
          }
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/:inviteCode",
    name: "applyform",
    component: ApplyFormView,
  },
  {
    path: "/groupUser/:groupAccountId",
    name: "groupUser",
    component: groupUserView,
  },
  {
    path: "/recharge/:code",
    name: "recharge",
    component: RechargeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  const ticket = localStorage.getItem('ticket');
  if (to.meta.requiresAuth && !ticket) {
    // 如果需要登录且 ticket 不存在，则重定向到登录页面
    next('/login');
  } else {
    // 用户已登录或不需要登录的页面，则正常跳转
    next();
  }
});

export default router;
