import { Resource } from "../entity/Resource";
import { ResourceRequest } from "../entity/ResourceRequest";

import { BaseService } from "./BaseService";

export class ResourceService extends BaseService<Resource> {
  protected getUrl(): string {
    return "y";
  }
  //积分明细
  accountItem(ResourceReq: ResourceRequest) {
    return this.get(`user-resource/pointItem`, {
        pageNo: ResourceReq.pageNo,
        pageSize: ResourceReq.pageSize,
        searchType: ResourceReq.searchType,
        searchContext: ResourceReq.searchContext,
    })
      .then(function (response) {
        ResourceReq.onSuccess(response.data);
      })
      .catch(function () {
        ResourceReq.onError();
      });
  }
  //商品信息
  getProduct(ResourceReq: ResourceRequest) {
    return this.get(`user-resource/product`, {
      searchType: "resourceTypeId",
      searchContext: ResourceReq.resourceTypeId,
    })
      .then(function (response) {
        ResourceReq.onSuccess(response.data);
      })
      .catch(function () {
        ResourceReq.onError();
      });
  }
}
