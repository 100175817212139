import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28363871"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sign" }
const _hoisted_2 = { class: "tab-div" }
const _hoisted_3 = { href: "#" }
const _hoisted_4 = { class: "line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileProtectOutlined = _resolveComponent("FileProtectOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "1",
            tab: "消息通知"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, { class: "FileProtectBtn" }, {
                default: _withCtx(() => [
                  _createVNode(_component_FileProtectOutlined),
                  _createTextVNode(" 全部已读")
                ]),
                _: 1
              }),
              _createVNode(_component_a_list, {
                bordered: "",
                "data-source": _ctx.data
              }, {
                renderItem: _withCtx(({ item }) => [
                  _createVNode(_component_a_list_item, null, {
                    actions: _withCtx(() => [
                      _createVNode(_component_a_button, null, {
                        default: _withCtx(() => [
                          _createTextVNode("已读")
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_list_item_meta, null, {
                        title: _withCtx(() => [
                          _createElementVNode("a", _hoisted_3, _toDisplayString(item.message), 1)
                        ]),
                        avatar: _withCtx(() => [
                          _createVNode(_component_a_badge, { count: "1" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_avatar, {
                                src: item.img
                              }, null, 8, ["src"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        description: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, _toDisplayString(item.time), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["data-source"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeKey"])
    ])
  ]))
}