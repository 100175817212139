import { User } from "./../entity/User";
import { BaseService } from "./BaseService";
export class SmsCodeService extends BaseService<User> {
  protected getUrl(): string {
    return "smsCode";
  }
  send(phoneNumber: string) {
    return this.post("smsCode", { phoneNumber: phoneNumber });
  }
}
