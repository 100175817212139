import { ApplyForm } from "../entity/ApplyForm";
import { ApplyFormRequest } from "../entity/ApplyFormRequest";

import { BaseService } from "./BaseService";

export class ApplyFormService extends BaseService<ApplyForm> {
  protected getUrl(): string {
    return "y";
  }
  getinvite(ApplyFormReq: ApplyFormRequest) {
    return this.get(`y/${ApplyFormReq.inviteCode}`, {})
      .then(function (response) {
        ApplyFormReq.onSuccess(response.data);
      })
      .catch(function (error) {
        ApplyFormReq.onError(error);
      });
  }

  sendinvite(ApplyFormReq: ApplyFormRequest) {
    return this.post(`groupUserApprove`, {
      ...ApplyFormReq,
    })
      .then(function (response) {
        ApplyFormReq.onSuccess(response.data);
      })
      .catch(function (error) {
        ApplyFormReq.onError(error);
      });
  }
}
