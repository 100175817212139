import { defineComponent } from "vue";
import  log  from "../../service/log";
import { ProfileService } from "./../../service/ProfileService";
export default defineComponent({
  data() {
    return {
      startTime: Date.now(),
      modalVisible: false,
      inviteCode: "",
      invite: "",
      phoneNumber: <any>"",
      point: 0,  //我的积分
      totalPoint: 0, //总积分
    };
  },
  mounted() {
    log.info({
      p: "u",
      a: "m",
    });
    this.getProfile();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "u",
      a: "u",
      d: stayTime,
    });
  },
  name: "UserView",
  methods: {
  
    openModal() {
      this.modalVisible = true;
    },
    handleOk() {
      this.inviteCode = this.invite;
      this.modalVisible = false;
    },
    handleCancel() {
      this.modalVisible = false;
    },
    getProfile() {
      const profileService = new ProfileService(this.$router);
      profileService.getOne((profile) => {
        const user = profile as any
        this.phoneNumber = user.phoneNumber;
        this.point = user.point;
        this.totalPoint = user.totalPoint;
      });
    },

  },
});
