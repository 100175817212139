import { LoginRequest } from "./../entity/LoginRequest";
import { User } from "./../entity/User";
import { BaseService } from "./BaseService";
import log from "./log";

export class UserService extends BaseService<User> {
  key = "status";
  protected getUrl(): string {
    return "user";
  }
  login(loginReq: LoginRequest) {
    return this.get(
      "user",
      {},
      {
        phoneNumber: loginReq.phoneNumber,
        verifyCode: loginReq.verifyCode,
      }
    )
      .then(function (response) {
        loginReq.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "l",
            a: "e",
          });
          loginReq.onVerifyCodeError();
        }
      });
  }
  createTempUser(loginReq: LoginRequest) {
    return this.post("user", {
      deviceId: "",
    })
      .then(function (response) {
        loginReq.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "l",
            a: "e",
          });
          loginReq.onVerifyCodeError();
        }
      });
  }
  getUserStatus(): string | null {
    return localStorage.getItem(this.key);
  }
  setUserStatus(status: string) {
    localStorage.setItem(this.key, status);
  }
  /**
   * remove ticket in local storage.(logout)
   */
  remove() {
    localStorage.removeItem(this.key);
  }
}
