import { Order } from "../entity/Order";
import { OrderRequest } from "../entity/OrderRequest";

import { BaseService } from "./BaseService";

export class OrderService extends BaseService<Order> {
  protected getUrl(): string {
    return "y";
  }
  //获取订单
  getOrder(OrderReq: OrderRequest) {
    return this.get("order/from", {
        pageNo: OrderReq.pageNo,
        pageSize: OrderReq.pageSize,
        searchType: OrderReq.searchType,
        searchContext: OrderReq.searchContext,
        orderStatus: OrderReq.orderStatus
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }
  //添加订单
  addOrder(OrderReq: OrderRequest) {
    return this.post(`order/from`, {
      ...OrderReq
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }
  //会员注册验证api
  member(OrderReq: OrderRequest) {
    return this.post(`member`, {
       day: OrderReq.day,
       machineCode: OrderReq.machineCode
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }

  //支付宝支付
  aliPay(OrderReq: OrderRequest) {
    return this.get(`alipay`, {
      orderId: OrderReq.orderId
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }

  //微信支付
  wechatPay(OrderReq: OrderRequest) {
    return this.get(`wechat/pay`, {
      orderId: OrderReq.orderId
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }

  //微信完成支付回调
  findOrder(OrderReq: OrderRequest) {
    return this.get(`findOrder`, {
      orderNumber: OrderReq.orderNumber
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }
  //localhost:80/order/from/desc
  getDesc(OrderReq: OrderRequest){
    return this.get(`order/from/desc`, {
    
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }

  //判断是否首次充值
  getFirstPay(OrderReq: OrderRequest){
    return this.get(`order/from/total`, {
    
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }


  //获取会员兑换码
  GetmembershipCode(OrderReq: OrderRequest){
    return this.get(`member/probeCode`, {
    
    })
      .then(function (response) {
        OrderReq.onSuccess(response.data);
      })
      .catch(function () {
        OrderReq.onError();
      });
  }
  

}
