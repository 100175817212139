/* eslint-disable @typescript-eslint/no-this-alias */
import { defineComponent } from "vue";
import { ArrowUpOutlined, InboxOutlined } from "@ant-design/icons-vue";
import { OcrFileService } from "./../../service/OcrFileService";
import { OcrFile } from "../../entity/OcrFile";
import log from "./../../service/log";

export default defineComponent({
  data() {
    return {
      justify:[ 'space-around'],
      list: Array<OcrFile>(),
      tabType: "qwsb",
      startTime: Date.now(),
      showQwsb: false,
      showBgsb: false,
      showkzpjsb: false,
      showBgsjtq: false,
      showPdf: false,
      showtoOfd: false,
      showtoImage: false,
      tileType: '',
      titleText: '',
    };
  },
  components: {
    ArrowUpOutlined,
    InboxOutlined,
  },
  mounted() {
    log.info({
      p: "h",
      a: "m",
    });
    this.getRecentFileList();
  },
  unmounted() {
    // 计算页面停留时间并记录到后端
    const endTime = Date.now();
    const stayTime = Math.floor((endTime - this.startTime) / 1000); // 单位：秒
    log.info({
      p: "h",
      a: "u",
      d: stayTime,
    });
  },
  name: "HomeView",
  methods: {
    switchLang() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'zh';
      } else {
        this.$i18n.locale = 'en';
      }
    },
    //获取两条最新文档的数据信息
    getRecentFileList() {
      const service: OcrFileService = new OcrFileService(this.$router);
      service.getRecentFileList((data) => {
        this.list = data;
      });
    },
    //切换功能标签
    swichFunctionTab: function (type: string) {
      log.info({
        p: "h",
        a: "c",
      });
      const self = this;
      switch (type) {
        case "qwsb":
          self.tabType = "qwsb";
          this.$router.push({
            path: "/full-text-ocr",
          });
          break;
        case "bgsb":
          self.tabType = "bgsb";
          this.$router.push({
            path: "/table-ocr",
          });
          break;
        case "kzpjsb":
          self.tabType = "kzpjsb";
          this.$router.push({
            path: "/car-ocr",
          });
          break;
        case "bgsjtq":
          self.tabType = "bgsjtq";
          this.$router.push({
            path: "/table-pump",
          });
          break;
        case "toPdf":
          self.tabType = "toPdf";
          this.$router.push({
            path: "/convert-pdf",
          });
          break;
        case "toOfd":
          self.tabType = "toOfd";
          this.$router.push({
            path: "/convert-ofd",
          });
          break;
        case "toImage":
          self.tabType = "toImage";
          this.$router.push({
            path: "/convert-image",
          });
          break;
          case "qwnlp":
          self.tabType = "qwnlp";
          this.$router.push({
            path: "/full-text-nlp",
          });
          break;
      }
    },
  },
});
