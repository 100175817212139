import OSS from "ali-oss";
import { AliyunOSS } from "../entity/AliyunOSS";
export class AliyunOSSUtil {
  //create aliyun oss client
  getClient = (aliyunOSS: AliyunOSS) => {
    const client = new OSS({
      region: aliyunOSS.region,
      secure: true,
      accessKeyId: aliyunOSS.accessKeyId,
      accessKeySecret: aliyunOSS.accessKeySecret,
      bucket: aliyunOSS.bucket,
    });
    return client;
  };
  //get image file for aliyun oss
  getImageFile = (client: OSS, imagePath: string) => {
    const signUrl = client.signatureUrl(imagePath, {
      expires: 3600,
    });
    return signUrl;
  };
  //get  file for aliyun oss
  getFile = (client: OSS, filePath: string) => {
    const signUrl = client.signatureUrl(filePath, {
      expires: 3600,
    });
    return signUrl;
  };
}
