import { Account } from "../Account";
import { TokenResponse } from "../TokenResponse";
export class WelComeModel {
  token?: TokenResponse;
  account?: Account;
  showCreate = false;
  username?: string;
  ocrUrl?: string;
  message?: string;
  showCopy = false;
  showDownload = true;
  loginStatus?: string;
}
