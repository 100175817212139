import { MenuInfo } from "ant-design-vue/lib/menu/src/interface";
import { defineComponent } from "vue";
import { WelComeModel } from "../../entity/pagemodel/WelComeModel";
import { AccountService } from "../../service/AccountService";
import { groupAccountSrevice } from "../../service/groupAccountSrevice";
import { groupUserSrevice } from "../../service/groupUserSrevice";
import { groupUserApproveSrevice } from "../../service/groupUserApproveSrevice";
import { ProfileService } from "../../service/ProfileService";
import { TicketService } from "../../service/TicketService";
import { TokenService } from "../../service/TokenService";
import { UserService } from "../../service/UserService";
import { message, Modal } from "ant-design-vue";
import { copy } from "clipboard";
import {
  MinusCircleFilled,
  RightOutlined,
  PlusCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  DownOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    MinusCircleFilled,
    RightOutlined,
    PlusCircleFilled,
    CheckCircleOutlined,
    CloseCircleOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    DownOutlined,
  },
  data() {
    return {
      ...new WelComeModel(),
      headerStyle: <any>{},
      textStyle: <any>{},
      logoSrc: "",
      data: <any>[],
      groupInviteLink: false,
      url: "",
      qrCode: "",
      showApprove: false,
      dataList: [],
      isuserRole: false,
      issetting: false,
      isapprove: false,
      psize: 20,
    };
  },
  name: "HeaderComponent",
  methods: {

    profileClick() {
      this.$router.push("/profile");
    },
    getGroupUser: function () {
      const service: groupUserSrevice = new groupUserSrevice(this.$router);
      service.getGroupUser({
        groupAccountId: this.data.groupAccountId,
        onSuccess: (resp) => {
          resp.content.forEach(function (obj: { joiningAt: string }) {
            const timestamp = obj.joiningAt; // 获取时间戳
            const date = new Date(timestamp); // 创建新的Date对象

            // 获取年月日的值
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            // 将年月日重新组合成所需的日期格式，例如：2023-07-03
            const formattedDate =
              year + "-" + month + "-" + day + " " + hours + ":" + minutes;

            // 更新对象的时间属性值
            obj.joiningAt = formattedDate;
          });
        },
        onError() {
          console.log("列表获取失败");
        },
      });
    },
    GroupInviteLink: function () {
      const service: groupUserSrevice = new groupUserSrevice(this.$router);
      service.groupInviteLink({
        id: this.data.groupAccountId,
        onSuccess: (resp) => {
          this.url = resp.url;
          this.qrCode = resp.qrCode;
        },
        onError() {
          message.error("获取失败 ");
        },
      });
    },
    getGroupUserApprove: function () {
      const service: groupUserApproveSrevice = new groupUserApproveSrevice(
        this.$router
      );
      service.getGroupUserApprove({
        id: this.data.groupAccountId,
        onSuccess: (resp) => {
          resp.content.forEach(function (obj: { approvalAt: string }) {
            const timestamp = obj.approvalAt; // 获取时间戳
            const date = new Date(timestamp); // 创建新的Date对象

            // 获取年月日的值
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            // 将年月日重新组合成所需的日期格式，例如：2023-07-03
            const formattedDate =
              year + "-" + month + "-" + day + " " + hours + ":" + minutes;

            // 更新对象的时间属性值
            obj.approvalAt = formattedDate;
          });
          this.dataList = resp.content;
        },
        onError() {
          message.error("获取失败 ");
        },
      });
    },
    getApproveStatus: function (id: any, status: any) {
      const service: groupUserApproveSrevice = new groupUserApproveSrevice(
        this.$router
      );
      service.getApproveStatus({
        id: id,
        status: status,
        onSuccess: (resp) => {
          if (resp.status == "审批通过") {
            this.getGroupUserApprove();
            this.getGroupUser();
            message.success("审批通过");
          } else {
            this.getGroupUserApprove();
            this.getGroupUser();
            message.error("已拒绝");
          }
        },
        onError() {
          message.error("获取失败 ");
        },
      });
    },
    handleCancel() {
      this.$emit("modalClosed");
    },
    switchUser: function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const userStatus = new UserService(this.$router).getUserStatus();
      if (userStatus === "1") {
        Modal.confirm({
          title: "提示",
          content: `确认退出登录吗?`,
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            new TicketService().remove();
            new UserService(this.$router).remove();
            self.loginStatus = "登录";
            window.location.href = "/";
          },
        });
       
      } else {
        this.$router.push("/login");
      }
    },
    loginInit() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.showCopy = navigator.clipboard && window.isSecureContext;
      const service = new TokenService(this.$router);
      service.list((data) => {
        if (data.length > 0) {
          self.token = data[0];
        } else {
          self.showCreate = true;
        }
      });
      // load account
      const accountService = new AccountService(this.$router);
      accountService.getOne((account) => {
        self.account = account;
      });
      // load profile
      const profileService = new ProfileService(this.$router);
      profileService.getOne((profile) => {
        if (profile.name == null) {
          if (profile.phoneNumber == null) {
            this.username = "新用户";
          } else {
            this.username = profile.phoneNumber;
          }
        } else {
          this.username = profile.name;
        }
      });
      // TODO: read from config.
      self.ocrUrl = `${process.env.VUE_APP_API_URL}open-api/ocr`;
      new UserService(this.$router).getUserStatus() === "1"
        ? (self.loginStatus = "退出")
        : (self.loginStatus = "登录");

      const groupAccount: groupAccountSrevice = new groupAccountSrevice(
        this.$router
      );
      groupAccount.getGroupAccount({
        type: "login",
        onSuccess: (resp) => {
          if (resp == false) {
            this.isuserRole = false;
          } else if (resp.userRole == "普通用户") {
            this.isuserRole = true;
            this.issetting = false;
            this.isapprove = false;
            this.data = resp;
          } else if (resp.userRole == "管理员") {
            this.isuserRole = true;
            this.issetting = true;
            this.isapprove = true;
            this.data = resp;
          }
        },
        onError: () => {
          console.log("不是企业用户");
        },
      });
    },
    switchMenu(e: MenuInfo) {
      this.$router.push({
        path: e.key.toString(),
      });
    },
    handleMenuSelect(path: string) {
      this.$router.push(path);
    },
    jtgl() {
      this.$router.push(`/groupUser/${this.data.groupAccountId}`);
    },
    handleOk() {
      const link = document.createElement("a");
      link.href = this.qrCode;

      // 设置下载文件名
      link.download = "邀请二维码.png";

      // 触发点击事件以下载图片
      link.click();
    },
    copyText() {
      const textElement = this.$refs.text as any;
      // 获取文字内容
      const text = textElement.innerText;
      // 复制文字到剪贴板
      copy(text);
      // 可选：给出复制成功的提示
      message.success("复制成功");
    },
    showInviteLink() {
      this.GroupInviteLink();
      this.groupInviteLink = true;
    },
    isModelOpen() {
      this.getGroupUserApprove();
      this.showApprove = true;
    },
    ok(item: any) {
      this.getApproveStatus(item.id, "审批通过");
    },
    minus(item: any) {
      this.getApproveStatus(item.id, "已拒绝");
    },
  },
  mounted() {
    //1. get ticket
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const ticketService = new TicketService();
    if (!ticketService.get()) {
      //2. create temp user.
      const userService = new UserService(this.$router);
      userService.createTempUser({
        phoneNumber: "",
        verifyCode: "",
        onSuccess: (resp) => {
          new TicketService().set(resp.ticket);
          this.loginInit();
          userService.setUserStatus("0");
          this.$router.push("/");
          this.loginStatus = "登录";
        },
        onVerifyCodeError() {
          message.error("登录已失效，请重新登录！");
          self.$router.push("/login");
        },
      });
    } else {
      this.loginInit();
    }
  },
  beforeUpdate() {
    //2.是否无痕模式
    const isTracelessMode = sessionStorage.getItem("isTracelessMode");
    console.log(isTracelessMode);
    if (isTracelessMode === "true") {
      console.log("修改颜色为黑色");
      this.headerStyle = {
        backgroundColor: "#000",
      };
      this.textStyle = {
        color: "#fff",
      };
      this.logoSrc = "tracelessModeLogo.png";
    } else {
      this.headerStyle = {
        color: "#000",
      };
      this.logoSrc = "logo.png";
    }
  },
});
