import { User } from "../entity/User";
import { BaseService } from "./BaseService";
import { OcrFile } from "../entity/OcrFile";
import { DateformatUtil } from "../util/DateformatUtil";
import { TicketService } from "./TicketService";
import { convertImageRequest } from "../entity/convertImageRequest";

import log from "./log";
import axios from 'axios';
const baseUrl = process.env.VUE_APP_API_URL;
export class ConvertImageService extends BaseService<User> {
  [x: string]: any;

  protected getUrl(): string {
    return "convert/image";
  }
  // 获取image文档列表getImageFileList
  getImageFileList(data: any, callback: (data: Array<OcrFile>) => void) {
    this.get(this.getUrl() + "/", { ...data }).then((resp) => {
      const dateformatUtil = new DateformatUtil();
      const list = resp.data.content;
      for (let i = 0; i < list.length; i++) {
        if (list[i].convertStatus != -1) {
          if (list[i].memo) {
            list[i]["title"] = `${
              list[i].memo.length > 5
                ? list[i].memo.substring(0, 5) + "..."
                : list[i].memo
            }`;
          } else {
            list[i]["title"] = `未命名文件`;
          }
        } else {
          if (list[i].memo) {
            list[i]["title"] = `${
              list[i].memo.length > 5
                ? list[i].memo.substring(0, 5) + "..."
                : list[i].memo
            }`;
          } else {
            list[i]["title"] = `未命名文件`;
          }
        }
        list[i]["key"] = list[i].id;
        list[i].createdAt = dateformatUtil.formatDate(
          parseInt(list[i].createdAt || "0")
        );
      }
      callback(list);
    });
  }
  //移动子文件顺序
  move(convertImageReq: convertImageRequest) {
    return this.putFile(
      `convert/image/${convertImageReq.id}`,
      {
        type: "move",
        list: convertImageReq.list,
      },
      {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    )
      .then(function (response:any) {
        log.info({
          p: "d",
          a: "r",
          o: "move"
        });
        convertImageReq.onSuccess(response.data);
      })
      .catch(function (err:any) {
        log.error({
          p: "d",
          a: "e",
          o: "move"
        });
        convertImageReq.onError(err.response);
      });
  }
  
  //根据image文档id去查询对应子文件列表
  getChildList(id: number, callback: (data: OcrFile) => void) {
    this.get(this.getUrl() + `/${id}`, {}).then((resp) => {
      const dateformatUtil = new DateformatUtil();
      const data = resp.data;
      for (let i = 0; i < data.length; i++) {
        data[i].imageUrl =
          baseUrl +
          "convert/common/preview/" +
          data[i].id +
          "?type=image&ticket=" +
          new TicketService().get();
      }
      data["key"] = data.id;
      data.createdAt = dateformatUtil.formatDate(
        parseInt(data.createdAt || "0")
      );
      callback(data);
    });
  }

//下载image
downloadImage(convertImageReq: convertImageRequest){
  return this.get(`convert/common/download`, {
    filepath: convertImageReq.filepath
  })
  .then(function (response) {
    
    convertImageReq.onSuccess(response.data);
  })
  .catch(function (err) {
 
    convertImageReq.onError(err);
  });
}
//批量下载pdf
batchDownloadImage(convertImageReq: convertImageRequest){
  return this.getPost(`convert/image`, {
    type: convertImageReq.type,
    ids: convertImageReq.ids?.toString()
  } ,{
    "Content-Type": "application/x-www-form-urlencoded",
  })
  .then(function (response) {
    log.info({
      p: "d",
      a: "r",
      o: "batchDownloadPdf"
    });
    convertImageReq.onSuccess(response.data);
  })
  .catch(function (err) {
    log.error({
      p: "d",
      a: "e",
      o: "batchDownloadPdf"
    });
    convertImageReq.onError(err);
  });
}
  
  
 
  //修改当前文件的无痕模式状态
  updateNoTraceMode(data: any, callback: (data: any) => void) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `${baseUrl}ocr/files/${data.ids}`,
      method: "PUT",
      // responseType: "blob",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        noTraceMode: data.noTraceMode,
      },
    })
      .then((response) => {
        callback(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          callback(err);
          log.error({
            p: "f",
            a: "e",
          });
        }
      });
  }
 
  delImage(convertImageReq: convertImageRequest) {
    return this.delete(`convert/image/${convertImageReq.ids}`, {})
      .then(function (response) {
      
        convertImageReq.onSuccess(response.data);
      })
      .catch(function (err) {
      
        convertImageReq.onError(err);
      });
  }

  getCheckImage(convertImageReq: convertImageRequest) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url: `${baseUrl}convert/image`,
      method: "POST",
      // responseType: "blob",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        type: convertImageReq.type,
        ids: convertImageReq.ids,
      },
    })
      .then((response) => {
        convertImageReq.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "f",
            a: "e",
          });
          convertImageReq.onError(err.response.data);
        }
      });
  }

}
