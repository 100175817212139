const messages = {
  welcomeMessage: 'Join Huizhi Circle, Document Processing Expert in the AI Era',
  recognition: "Document Recognition",
  tableRecognition: "Table Recognition",
  cardRecognition: "Card Ticket Recognition",
  tableDataExtraction: "Table Data Extraction",
  toPdf: "Convert to PDF",
  toOfd: "Convert to OFD",
  toImage: "Convert to Image",
  nlpExtraction: "NLP Information Extraction",
  recognitionDescription: "Extract full text in text form, supporting files up to 10MB",
  tableDescription: "Recognition based on the content of the form, and the table document can be saved",
  cardDescription: "Identification according to the format of the document",
  tableDataDescription: "Batch table data identification and download",
  toPdfDescription: "Convert images or files to PDF format",
  toOfdDescription: 'Convert images or files to OFD format',
  toImageDescription: 'Convert the file to a picture format',
  nlpDescription:'A more intelligent document entry system supports text recognition and box selection recognition',
  //head
  homePage: 'HOME',
  encryption: 'Encryption Usage',
  intelligentInput: 'Intelligent Input System',
  hello: 'Hello',
  newUser: 'New User',
  login: 'Login',
  exit: 'Exit',
  userCenter: 'User Center',
  message: 'Message',
  checkIn: 'Check In',
  inviteFriends: 'Invite Friends',

  upload: 'Upload the file',
  downloadClient: 'Download the client',
  showOriginalImage: 'Show Original Image',
  showAnnotation: 'Show Annotation',
  showResult: 'Show Result',
  extractionResult: 'Extraction Result',
  recognitionComparison: 'Recognition Comparison',
  supportHandwriting: 'Support Handwriting',
  dropFilesHere: 'Drop files here',

  //nlp
  chooseInfoExtractionCategory: 'Extraction Category',
  custom: 'Custom',
  officeDocumentRecognition: 'Office Document Recognition',
  IDCardRecognition: 'ID Card Recognition',
  bankCardRecognition: 'Bank Card Recognition',
  businessLicenseRecognition: 'Business License Recognition',
  passportRecognition: 'Passport Recognition',
  householdRegisterRecognition: 'Household Register Recognition',
  birthCertificateRecognition: 'Birth Certificate Recognition',
  bankStatementRecognition: 'Bank Statement Recognition',
  mixedInvoiceRecognition: 'Mixed Invoice Recognition',
  VATInvoiceRecognition: 'VAT Invoice Recognition',
  VATRollInvoiceRecognition: 'VAT Roll Invoice Recognition',
  trainTicketRecognition: 'Train Ticket Recognition',
  taxiReceiptRecognition: 'Taxi Receipt Recognition',
  flightItineraryRecognition: 'Flight Itinerary Recognition',
  licensePlateRecognition: 'License Plate Recognition',
  driverLicenseRecognition: 'Driver License Recognition',
  vehicleLicenseRecognition: 'Vehicle License Recognition',
  motorVehicleSalesInvoiceRecognition: 'Motor Vehicle Sales Invoice Recognition',
  medicalInvoiceRecognition: 'Medical Invoice Recognition',
  medicalExpenseSettlementRecognition: 'Medical Expense Settlement Recognition',
  medicalRecordRecognition: 'Medical Record Recognition',

  addField: 'Add Field',
  startExtraction: 'Start Extraction',

  //下载按钮
  downloadTxt: 'TXT',
  downloadDoublePdf: 'Double PDF',
  downloadTextPdf: 'Text PDF',
  downloadWord: 'WORD',
  downloadExcel: 'EXCEL',
  mergePDF: 'Merge PDF',
  countPages: 'Count PDF Pages',

  downloadOFD: 'Download OFD',
  mergeOFD: 'Merge OFD',
  countOFDPages: 'Count OFD Pages',

  downloadSourceText: 'Source Text',
  downloadRecognizedText: 'Recognized Text',
  countTextPages: 'Count Text Pages',

  TemplateManagement: 'Template Management',
  TemplateLists: 'Template lists',
  uploadTemplate: 'Upload template',
  dragImageOrder: 'Drag to adjust image order',
  appendUpload: 'Append Upload Image',

  tableColumns: {
    num: 'Id',
    memo: 'Template Name',
    operation: 'Operation',

    fieldName: 'Field Name',
    content: 'Extraction Result'
  },

  removeImage: 'Remove Image',
  extractText: 'Extract Text',
  extractTable: 'Extract Table',
  extractAIKeyElements: 'Extract AI Key Elements',
  downloadImage: 'Download Image',
  moreAIOptions: 'More AI Smart Settings ->',
  phoneNumber: 'Phone Number',
  resend: 'Resend',
  getCode: 'Get Code',
  verifyCode: 'Verify Code',
  enterElevenDigits: 'Please enter 11 digits',
  enterSixDigits: 'Please enter 6 digits',
  sendSuccess: 'Message sent successfully',
  loginSuccess: 'Login successful',
  invalidCode: 'Invalid verification code',

  //个人中心
  setting: "Account Settings",
  purchase: "Points Purchase",
  bill: "Points Bill",
  orders: "Order Management",
  mission: "Mission Center",
  sign: "Sign In",
  invite: "Invite Friends",
  //账户设置
  account: 'Account',
  fillInvitationCode: "Fill Invitation Code",
  myPoints: 'My Points',
  remainingPoints: "points can be used for OCR text recognition, format conversion, 1 point = 1 page",
  pointUsage: "Point Usage",
  used: "Used",
  unused: "Unused",
  point: 'points'
};

export default messages;
