import { User } from "../entity/User";
import { BaseService } from "./BaseService";
import { FilePolicyRequest } from "../entity/FilePolicyRequest";
import { TicketService } from "./TicketService";
import { TokenResponse } from "../entity/TokenResponse";

import axios from "axios";
import log from "./log";
export class TablePumpTemplateService extends BaseService<User> {
  protected getUrl(): string {
    return "open-api/table-pump-template";
  }

  //查询所有模板列表
  getTemplateList(data: any, callback: (data: any) => void) {
    this.get("open-api/table-pump-template", { ...data }).then((resp) => {
      try{
        log.error({
          p: "t",
          a: "r",
          o: "getTemplateList"
        });
      const list = resp.data.content;
      for (let i = 0; i < list.length; i++) {
        if (list[i].memo) {
          list[i]["memo"] = `${
            list[i].memo.length > 10
              ? list[i].memo.substring(0, 10) + "..."
              : list[i].memo
          }`;
        } else {
          list[i]["memo"] = `未命名文件`;
        }
        list[i]["key"] = list[i].id;
      }
      const obj = {
        templateData: list,
        templateTotalPages: resp.data.totalPages,
        templatePageSize: resp.data.pageSize,
        templatePageNo: resp.data.pageNo,
        templateTotalCount: resp.data.totalCount,
      };
      callback(obj);
    }catch(error){
      log.error({
        p: "t",
        a: "e",
        o: "getTemplateList"
      });
    }
    });
  }
  //修改模板文件备注
  updateMemo(data: any, callback: (data: any) => void) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url:
        `${baseUrl}open-api/table-pump-template?memo=` +
        data.memo +
        "&id=" +
        data.id +
        "&token=" +
        data.token,
      method: "PUT",
      // responseType: "blob",
      headers: {
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {},
    })
      .then((response) => {
        log.info({
          p: "t",
          a: "r",
          o: "updateMemo"
        });
        callback(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          callback(err);
          log.error({
            p: "t",
            a: "e",
            o: "updateMemo"
          });
        }
      });
  }
  //上传文件
  uploadFile(
    filePolicyRequest: FilePolicyRequest,
    fileInfo: any,
    token: TokenResponse,
    template: any
  ) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();

    axios({
      url: `${baseUrl}open-api/table-pump-template?token=${token.token}`,
      method: "POST",
      // responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        file: fileInfo.file,
      },
    })
      .then((response) => {
        log.info({
          p: "t",
          a: "r",
          o: "uploadFile"
        });
        filePolicyRequest.onSuccess(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "t",
            a: "e",
            o: "uploadFile"
          });
          filePolicyRequest.onGetFilePolicyError(err.response.data);
        }
      });
  }
  //删除模板文件
  deleteItem(data: any, callback: (data: any) => void) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url:
        `${baseUrl}open-api/table-pump-template/` +
        data.ids +
        "?token=" +
        data.token,
      method: "DELETE",
      // responseType: "blob",
      headers: {
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {},
    })
      .then((response) => {
        log.info({
          p: "t",
          a: "r",
          o: "deleteItem"
        });
        callback(response.data);
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          callback(err);
          log.error({
            p: "t",
            a: "e",
            o: "deleteItem"
          });
        }
      });
  }
  //替换模板文件
  templateReUploadFile(
    filePolicyRequest: FilePolicyRequest,
    fileInfo: any,
    token: TokenResponse,
    templateRecord: any
  ) {
    const baseUrl = process.env.VUE_APP_API_URL;
    const ticketService = new TicketService();
    const ticket = ticketService.get();
    axios({
      url:
        `${baseUrl}open-api/table-pump-template?token=${token.token}&id=` +
        templateRecord.id,
      method: "POST",
      // responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
        apiVersion: "0.1.0",
        ticket: ticket,
      },
      data: {
        file: fileInfo.file,
      },
    })
      .then((response) => {
        log.info({
          p: "t",
          a: "r",
          o: "templateReUploadFile"
        });
        filePolicyRequest.onSuccess(response.data);
        const result = response.data.result;
      })
      .catch(function (err) {
        const status = err.response.status;
        if (status === 400) {
          log.error({
            p: "t",
            a: "e",
            o: "templateReUploadFile"
          });
          filePolicyRequest.onGetFilePolicyError(err.response.data);
        }
      });
  }
}
